export const ArchiveTranslations = {
  'eng': {
    archive: 'Show Archive',
    users: 'Show Users',
    clientcompanies: 'Show client companies'
  },
  'hrv': {
    archive: 'Prikaži Arhivu',
    users: 'Prikaži Korisnike',
    clientcompanies: 'Prikaži klijent tvrtke'
  }
};
