import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {ClickOutsideModule} from '../../../directives/clickOutside/clickOutside.module';
import {CategoryModalComponent} from './category-modal.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ClickOutsideModule
  ],
  providers: [
  ],
  declarations: [
    CategoryModalComponent
  ],
  exports: [
    CategoryModalComponent
  ]
})
export class CategoryModalModule {
}
