<div id="spinner" [class.electron]="_electronService.isElectronApp">
  <div class="spinner-overlay">
    <div class="spinner-wrapper-flex">
      <div class='spinner'>
        <div class='spinner__dot'></div>
        <div class='spinner__dot'></div>
        <div class='spinner__dot'></div>
        <div class='spinner__dot'></div>
        <div class='spinner__dot'></div>
        <div class='spinner__dot'></div>
        <div class='spinner__dot'></div>
        <div class='spinner__dot'></div>
      </div>
    </div>
  </div>
</div>
