import {UploadedFile} from '../../../../interfaces/uploaded-file';

export class UserListItem {
  id: number = null;
  firstName?: string = '';
  lastName?: string = '';
  email: string = '';
  profileImage: UploadedFile = null;
  iconColor: string;
  selected?: boolean;

  constructor(init?: any) {
    if (init) {
      this.id = init.id;
      this.firstName = init.firstName;
      this.lastName = init.lastName;
      this.email = init.email;
      this.profileImage = init.profileImage;
      this.iconColor = init.iconColor;
    }
  }

  getFullName() {
    return `${this.firstName} ${this.lastName}`;
  }

  getInitials() {
    return `${this.firstName[0]}${this.lastName[0]}`;
  }
}
