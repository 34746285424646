import {Injectable} from '@angular/core';

@Injectable()
export class StorageService {
  constructor() {
  }

  set(name: string, value?: any): void {
    if (!value) localStorage.removeItem(name);
    else {
      if (typeof value !== 'string') value = JSON.stringify(value);
      localStorage.setItem(name, value);
    }
  }

  get(name: string): any {
    let item = localStorage.getItem(name);
    try {
      item = JSON.parse(item);
    } catch (err) {
    }
    return item;
  }

  update(name: string, added: any): void {
    let current: any = localStorage.getItem(name);
    try {
      current = JSON.parse(current);
    } catch (err) {
    }

    if (!current) current = {};

    for (let key in added) current[key] = added[key];

    localStorage.setItem(name, JSON.stringify(current));
  }

  delete(name: string): void {
    localStorage.removeItem(name);
  }

  clear(): void {
    localStorage.removeItem('user');
    localStorage.removeItem('access-token');
    localStorage.removeItem('refresh-token');
  }
}
