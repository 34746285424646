import {Component, HostListener, OnInit} from '@angular/core';
import {GlobalService} from './services/global.service';
import {ClickedElementService} from './services/clicked-element.service';
import {LocalizationService} from './services/localization.service';
import {StorageService} from './services/storage.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit{

  constructor(public _global: GlobalService,
              private _clickedElement: ClickedElementService,
              public _locale: LocalizationService,
              public _storage: StorageService) {}

  @HostListener('click', ['$event.target'])
  appClick(target: HTMLElement): void {
    this._clickedElement.element.next(target);
  }
  ngOnInit() {
    this.getLanguage()
  }
  getLanguage() {
    if (this._storage.get('language-token'))
      this._locale.set(this._storage.get('language-token'))

  }
}
