export const ErrorTranslations = {
  'eng': {
    required: 'This field is required',
    max: 'Value can not be higher then',
    email: 'Email is not valid',
    characters: 'characters',
    minLength: 'Field can have minimum of',
    maxLength: 'Field can have maximum of',
    validateOib: 'This OIB is not valid',
    validPhone: 'This field can only use numbers and a + character',
    validPassword: 'Must have uppercase letter, symbol and a number',
    mailFormat: 'Email is not valid',
    salutationFormat: 'Salutation can be Empty, Mr, Mrs, Sir...',
    shippingAddressMax: 'Cant have 2 shipping addresses',
    billingAddressMax: 'Cant have 2 billing addresses',
    mainAddressMax: 'Cant have 2 main addresses',
    isOfAge: 'User should be at least 18 years old',
    maxCheckedItems: 'You can only select 5 items',
    spaceNotAllowed: 'Space is not allowed',
    passwordNotMatch: 'Password does not match',
    notAvailableUsername: '`Username is taken, suggested:',
    notAvailableEmail: 'Email is taken',
    startDateGreaterThenEndDate: 'Start date can not be greater then end date',
    endDateLesserThenStartDate: 'End date can not be lesser then start date',
    timeRegExp: 'Only numbers, point and colon are allowed',
    numberFirst: 'Number must be a first character',
    maxTagLength: 'Max tag length is',
    passingPointsNegative: 'Passing points cannot be nagative',
    passingPointsMoreThanTotal: 'Passing points cannot be more than point total',
    atLeastOneQuestion: 'Exam must have at least one question',
    atLeastOneCorrectAnswer: 'Question must have at least one correct answer',
    atLeastTwoAnswers: 'Question must have at least two answer options',
    sameAnswerText: 'Two answers cannot have the same text',
    atLeastOneAnswer: 'Question must have at least one answer',
    numAnswerGaps: 'Number of answers must match the number of gaps in question text',
    atLeastTwoPairs: 'Question must have at least two answer pairs',
    minNotBiggerThenMaxStock: 'Minimum stock can not be lesser then maximum stock',
    usernameSpecialChars: 'Username can only have letters numbers - and _ ',
    startEvent: 'Event start time',
    endEvent: 'Event end time',
    endRecurring: 'Recurrence end time',
    cantBeGreaterThen: 'can not be greater then',
    start: 'start',
    end: 'end'
  },
  'hrv': {
    required: 'Ovo polje je obavezno',
    max: 'Vrijednost ne može biti veća od',
    email: 'Email nije validan',
    characters: 'znakova',
    minLength: 'Polje mora imati min',
    maxLength: 'Polje može imate max',
    validateOib: 'OIB nije ispravan',
    validPhone: 'Ovo polje može sadržavati samo brojeve i + znak',
    validPassword: 'Mora imati veliko slovo, znak i broj.',
    mailFormat: 'Email nije ispravan',
    salutationFormat: 'Titula može biti prazna, g, gđa...',
    shippingAddressMax: 'Ne može imati 2 adrese za isporuku roba',
    billingAddressMax: 'Ne može imati 2 adresa za dostavu računa',
    mainAddressMax: 'Ne može imati 2 glavne adrese',
    isOfAge: 'Korisnik treba biti najmanje 18 godina',
    maxCheckedItems: 'Možete odabrati max 5 stavci',
    spaceNotAllowed: 'Razmak nije dozvoljen',
    passwordNotMatch: 'Password does not match',
    notAvailableUsername: 'Korisničko ime je zazueto, prijedlog:',
    notAvailableEmail: 'Email je zazuzet',
    startDateGreaterThenEndDate: 'Start date can not be greater then end date',
    endDateLesserThenStartDate: 'End date can not be lesser then start date',
    timeRegExp: 'Dozvoljeni su samo brojevi, točka i dvotočka',
    numberFirst: 'Prvi znak mora biti broj',
    maxTagLength: 'Tag ne može biti duži od',
    passingPointsNegative: 'Bodovi za prolaz ne mogu biti negativni',
    passingPointsMoreThanTotal: 'Bodovi za prolaz ne mogu biti preko ukupnog broja bodova',
    atLeastOneQuestion: 'Ispit mora imati barem jedno pitanje',
    atLeastOneCorrectAnswer: 'Pitanje mora imati barem jedan točan odgovor',
    atLeastTwoAnswers: 'Pitanje mora imati barem dva ponuđena odgovora',
    sameAnswerText: 'Dva odgovora ne mogu imati jednaki tekst',
    atLeastOneAnswer: 'Pitanje mora imati barem jednu odgovor',
    numAnswerGaps: 'Broj odgovora mora odgovarati broju praznina u tekstu pitanja',
    atLeastTwoPairs: 'Pitanje mora imati barem dva para odgovora',
    minNotBiggerThenMaxStock: 'Minimalna zaliha ne može biti manja od maksimanle',
    usernameSpecialChars: 'Korisničko ime može sadržavati samo slova, brojeve - i _',
    startEvent: 'Početak događaja',
    endEvent: 'Kraj događaja',
    endRecurring: 'Kraj ponavljanja događaja',
    cantBeGreaterThen: 'ne može biti veći od',
    start: 'početak',
    end: 'završetak'
  }
};
