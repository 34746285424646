import { Pipe, PipeTransform } from '@angular/core';
import {DecimalPipe} from '@angular/common';
import {LocalizationService} from '../services/localization.service';

@Pipe({
  name: 'FourDigitPipe'
})
export class FourDigitDecimalPipe implements PipeTransform {

  constructor(private decimalPipe: DecimalPipe, private _locale: LocalizationService) {}

  transform(value: any, hasDecimalPart: boolean = true, digitsInfo: string = '1.4-4', locale?: string): string {
      return this.decimalPipe.transform(value, digitsInfo, locale ? locale : this._locale.getCurrentLocaleShort());;
  }


}
