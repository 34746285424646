import {ProfitAndLoss} from './documents/profit-and-loss-2018';
import {Balance} from './documents/balance-2018';
import {Params} from '@angular/router';
import {SavedRawImportDocument, SavedRawImportYear} from './import-data';
import {CashFlow} from './documents/cash-flow-2018';
import {ProfitAndLoss2021} from "./documents/profit-and-loss-2021";
import {Balance2021} from "./documents/balance-2021";

export class ImportYear {
  businessYear: number;
  profitAndLoss?: ProfitAndLoss;
  balance?: Balance;
  cashFlow?: CashFlow;
  endDate?: any;

    constructor(rawYear: SavedRawImportYear) {
    let rawProfitAndLoss: SavedRawImportDocument = rawYear.importedDocuments.find(rawDocument => rawDocument.name === 'profitAndLoss');
    let rawBalance: SavedRawImportDocument = rawYear.importedDocuments.find(rawDocument => rawDocument.name === 'balance');
    let rawCashFlow: SavedRawImportDocument = rawYear.importedDocuments.find(rawDocument => rawDocument.name === 'cashFlow');
    this.businessYear = rawYear.businessYear;
    let endDateTwo = null;
    let endDateThree = null;
    let endDateFour = null;
    if (rawProfitAndLoss?.endDate) {
      endDateTwo = rawProfitAndLoss.endDate;
      endDateThree = endDateTwo.split('.')[2];
      endDateFour = parseInt(endDateThree);
      this.endDate = rawProfitAndLoss.endDate
      this.profitAndLoss = rawProfitAndLoss && endDateFour >= 2021 ? new ProfitAndLoss2021(rawProfitAndLoss?.values) : new ProfitAndLoss(rawProfitAndLoss?.values);
    }

    if (this.profitAndLoss) {
      this.profitAndLoss.endDate = rawProfitAndLoss?.endDate;
    }
    let endDateFive = null;
    let endDateSix = null;
    let endDateSeven = null;
    if (rawBalance?.endDate) {
      endDateFive = rawBalance.endDate;
      endDateSix = endDateFive.split('.')[2]
      endDateSeven = parseInt(endDateSix);
      this.endDate = rawBalance.endDate
      this.balance = rawBalance && endDateSeven < 2021 ? new Balance(rawBalance?.values) : new Balance2021(rawBalance?.values);
    }

    if (this.balance) {
      this.balance.endDate = rawBalance?.endDate;
    }
    this.cashFlow = rawCashFlow && new CashFlow(rawCashFlow.values);
    if (this.cashFlow) {
      this.cashFlow.endDate = rawCashFlow?.endDate ? rawCashFlow.endDate : null;
    }
  }

  getSaveData(): Params {
    let documents = [];
    if (this.profitAndLoss) {
      documents.push({
        name: 'profitAndLoss',
        values: this.profitAndLoss.values,
        endDate: this.endDate
      });
    }
    if (this.balance) {
      documents.push({
        name: 'balance',
        values: this.balance.values,
        endDate: this.endDate
      });
    }
    if (this.cashFlow) {
      documents.push({
        name: 'cashFlow',
        values: this.cashFlow.values,
        endDate: this.endDate
      });
    }
    return {
      businessYear: this.businessYear,
      documents: documents
    };
  }
}
