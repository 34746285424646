import {environment} from '../../../../environments/environment';

export const AdminRoutes = {
  attributes: {
    filter: {
      method: 'Post',
      url: `${environment.baseUrl}/api/${environment.version}/settings/attribute/filter`
    },
    get: {
      method: 'Get',
      url: `${environment.baseUrl}/api/${environment.version}/settings/attribute/get` // /:id
    },
    create: {
      method: 'Post',
      url: `${environment.baseUrl}/api/${environment.version}/settings/attribute/create`
    },
    update: {
      method: 'Patch',
      url: `${environment.baseUrl}/api/${environment.version}/settings/attribute/update`
    },
    delete: {
      method: 'Delete',
      url: `${environment.baseUrl}/api/${environment.version}/settings/attribute/delete`
    }
  },
  attributeSet: {
    filter: {
      method: 'Post',
      url: `${environment.baseUrl}/api/${environment.version}/settings/attributeSet/filter`
    },
    get: {
      method: 'Get',
      url: `${environment.baseUrl}/api/${environment.version}/settings/attributeSet/get` // /:id
    },
    create: {
      method: 'Post',
      url: `${environment.baseUrl}/api/${environment.version}/settings/attributeSet/create`
    },
    update: {
      method: 'Patch',
      url: `${environment.baseUrl}/api/${environment.version}/settings/attributeSet/update`
    },
    delete: {
      method: 'Delete',
      url: `${environment.baseUrl}/api/${environment.version}/settings/attributeSet/delete`
    }
  }
};
