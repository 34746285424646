import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {ToggleService} from '../../../services/toggle.service';
import {ElectronService} from 'ngx-electron';
import {LocalizationService} from '../../../services/localization.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Workgroup} from '../../../interfaces/common/workgroup';
import {ApiService} from '../../../services/api.service';
import {GlobalService} from '../../../services/global.service';
import * as _ from 'lodash';
import {AuthService} from '../../../services/auth.service';

@Component({
  selector: 'app-workgroup-modal',
  templateUrl: './workgroup-modal.component.html',
  styleUrls: ['./workgroup-modal.component.scss']
})
export class WorkgroupModalComponent implements OnInit, OnDestroy {

  public clickOutsideCounterModal: number = 1;
  public form: FormGroup;
  public workGroup: Workgroup = null;

  private returnObj: any = {
    type: 'workgroup',
    data: null
  };

  @Input() workGroups: Workgroup[] = [];
  @Input() url: any; //Url object
  @Input() module: string; //model type (user, company, project ...)
  @Output() onChange: any = new EventEmitter(); //Emit onChange event with the changed object

  constructor(public _electronService: ElectronService,
              public _locale: LocalizationService,
              public _toggle: ToggleService,
              private _global: GlobalService,
              private _fb: FormBuilder,
              private _auth: AuthService,
              private _api: ApiService) {
  }

  onClickOutsideGlobalPrimaryModal(event) {
    if (this._toggle.showGlobalPrimaryModal.getValue()) {
      this.clickOutsideCounterModal = this.clickOutsideCounterModal + 1;
      if (this._toggle.showGlobalPrimaryModal.getValue() && this.clickOutsideCounterModal === 3) {
        this.clickOutsideCounterModal = 1;
        this._toggle.showGlobalPrimaryModal.next(false);
      }
    }
  }

  ngOnInit() {
    if (!this.workGroups) this.workGroups = [];
    this.buildForm();
  }

  ngOnDestroy() {
    this._toggle.workGroupModal.next(false);
  }

  //<editor-fold desc="FORM">
  buildForm(workGroup?: Workgroup): void {

    this.form = this._fb.group({
      id: [{value: workGroup ? workGroup.id : null, disabled: !workGroup}],
      name: [workGroup ? workGroup.name : '', Validators.compose([Validators.required, Validators.maxLength(100)])],
      type: 'workgroup',
      company: this._auth.loggedUser.company, //Company for which is created
    });
  }

  edit(workGroup: Workgroup): void {
    this.workGroup = workGroup;
    this.form.reset();
    this.buildForm(this.workGroup);
  }

  clear(clearWorkGroup?: boolean): void {
    this.form.reset();
    if (clearWorkGroup) this.workGroup = null;
    this.buildForm();
  }

  //</editor-fold>

  //<editor-fold desc="CRUD">
  create(formData: Workgroup): void {
    if (!this.form.valid) return this._global.checkFormErrors(this.form);
    this._api.send(this.url.create.method, this.url.create.url, formData).subscribe(res => {
      this._global.updateCommonData(this.module,'workgroup', res['data'], 'create'); //Update common data
      this.clear();
      this.onChange.emit(_.assign(this.returnObj, {data: formData})); //Send event to parent component
    });
  }

  update(formData: Workgroup): void {
    if (!this.form.valid) return this._global.checkFormErrors(this.form);

    this._api.send(this.url.update.method, `${this.url.update.url}/${formData.id}`, formData).subscribe(res => {
      this._global.updateCommonData(this.module,'workgroup', formData, 'update'); //Update common data
      this.clear(true);
      this.onChange.emit(_.assign(this.returnObj, {data: formData})); //Send event to parent component
    });
  }

  delete(workGroup: Workgroup): void {
    this._api.send(this.url.delete.method, `${this.url.delete.url}/${workGroup.id}`, workGroup).subscribe(res => {
      this._global.updateCommonData(this.module,'workgroup', workGroup, 'delete'); //Update common data
      this.onChange.emit(_.assign(this.returnObj, {data: workGroup})); //Send event to parent component
    });
  }

  //</editor-fold>
}
