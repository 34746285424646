import {UserDetails} from '../interfaces/user-details';
import {UserAddress} from '../interfaces/user-address';
import {UserContact} from '../interfaces/user-contact';
import {Permission} from '../../../../interfaces/permission';
import {Occupation} from '../../../../interfaces/common/occupation';
import {UserType} from '../../../../interfaces/common/user-type';
import {ContactType} from '../../../../interfaces/common/contact-type';
import {Country} from '../../../../interfaces/common/country';
import {ModulePermission} from '../../../../interfaces/module-permission';
import {Iban} from '../../../../interfaces/iban';
import {PromotionRequest} from '../../../../classes/promotion-request';
import {MentorRequest} from '../../../../classes/mentor-request';
import {LocalizationService} from '../../../../services/localization.service';
import {Exportable} from '../../../../interfaces/exportable';
import * as _ from 'lodash';

export type TeraType = 'admin' | 'tutor' | 'basic';

export class User implements Exportable {
  id?: number;
  email: string;
  username?: string;
  title?: string;
  language: string;
  tags?: string[];
  permission?: number;
  company: number | any;
  branch?: number = null;
  category?: number;
  label?: number[];
  type?: number | string;
  teraType: TeraType;
  occupation?: number = null;
  workgroups?: number[];
  hasAccess?: boolean;
  archived?: boolean;
  details?: UserDetails;
  addresses?: UserAddress[];
  contacts?: UserContact[];
  iconColor?: string = '#BF5783';
  permissions: Permission[] = null;
  modulePermissions: ModulePermission[] = null;
  results: any = null;
  ibans: Iban[];
  isAnonimysed:boolean;
  fullName: string;
  mentorRequests: MentorRequest[] = [];
  promotionRequest: PromotionRequest;
  mentor: User;
  firstName?: string;
  lastName?: string;
  isActivated: boolean = false;

  get isTeraAdmin(): boolean {
    return this.teraType === 'admin';
  }


  constructor(init: any) {

    if (init.id) this.id = init.id;
    this.email = init.email || '';
    if (init.username) this.username = init.username || '';
    this.title = init.title || '';
    this.language = init.language;
    if (init.tags) this.tags = init.tags;
    if (init.permission) this.permission = init.permission || null;
    if (this.permission) this.isActivated = true;
    this.company = init.company;
    if (init.results) this.results = init.results;
    if (init.branch) this.branch = init.branch || null;
    if (init.category) this.category = init.category || null;
    if (init.label) this.label = init.label || null;
    if (init.type) this.type = init.type || null;
    if (init.teraType) this.teraType = init.teraType || null;
    if (init.archived) this.archived = init.archived;
    if (init.occupation) this.occupation = init.occupation || null;
    if (init.workgroups) this.workgroups = init.workgroups || null;
    this.hasAccess = init.hasAccess || false;
    if (init.details) {
      this.details = init.details;
      if(init.details.firstName) this.firstName = init.details.firstName;
      if(init.details.lastName) this.lastName = init.details.lastName;
    }
    if (init.contacts) this.contacts = init.contacts;
    if (init.addresses) this.addresses = init.addresses;
    if (init.ibans) this.ibans = init.ibans;
    if (init.iconColor) this.iconColor = init.iconColor;
    if (init.permissions) this.permissions = init.permissions;
    if (init.modulePermissions) this.modulePermissions = init.modulePermissions;
    this.isAnonimysed = init.isAnonymised;
    this.fullName = this.getFullName();

    //Check if backend has returned notSet then transform it to empty string
    if (init.details && init.details.salutation == 'notSet') this.details.salutation = '';

    if (init.tutoringRequestsMade) this.mentorRequests = init.tutoringRequestsMade.map(rawRequest => new MentorRequest(rawRequest));
    if (init.promotionRequests && init.promotionRequests.length) this.promotionRequest = new PromotionRequest(init.promotionRequests[0]);
    if (init.mentor) {
     if(typeof init.mentor === 'object'){
       this.mentor = new User(init.mentor);
     }
     else {
       this.mentor = init.mentor;
     }
    }
  };

  getMentorFullName(): string {
    if(this.mentor && typeof this.mentor === 'object'){
      return this.mentor.fullName;
    }
  }

  toJson() { //Return JSON object (always use this function before sending to backend)

    let data = {};
    if (this.id) data['id'] = this.id;
    if (this.email) data['email'] = this.email;
    if (this.username) data['username'] = this.username;
    if (this.title) data['title'] = this.title;
    if(this.language) data['language'] = this.language;
    if (this.tags) data['tags'] = this.tags;
    if (this.permission) data['permission'] = this.permission;
    if (this.category) data['category'] = this.category;
    if (this.label) data['label'] = this.label;
    if (this.archived) data['archived'] = this.archived;
    if (this.type) data['type'] = this.type;
    if (this.occupation) data['occupation'] = this.occupation;
    if (this.workgroups) data['workgroups'] = this.workgroups;
    data['hasAccess'] = this.hasAccess;
    if (this.details) data['details'] = this.details;
    if (this.contacts) data['contacts'] = this.contacts;
    if (this.addresses) data['addresses'] = this.addresses;
    if (this.ibans) data['ibans'] = this.ibans;
    if (this.hasAccess) data['hasAccess'] = this.hasAccess;


    //If Birth date is datetime object , convert it to timestamp
    if (this.details && this.details.birthDate && this.details.birthDate instanceof Date) {
      data['details'].birthDate = this.details.birthDate.getTime();
    }

    //check for branch
    data['branch'] = (this.branch && (this.branch.hasOwnProperty('id'))) ? this.branch['id'] :
      this.branch ? this.branch : null;

    //Check for company
    data['company'] = (this.company && this.company.hasOwnProperty('id')) ? this.company['id'] :
      this.company ? this.company : null;

    // #ZAGIQUICKFIX
    data['details']['profileImage'] =
      data['details']['profileImage'] && typeof data['details']['profileImage'] === 'object' ?
      data['details'].profileImage.media : null;

    return data;
  }

  getInitials(): string {
    return `${this.details && this.details['lastName'] ? this.details['lastName'][0] : ''}${this.details && this.details['firstName'] ? this.details['firstName'][0] : ''}`;
  }

  getFullName(): string {
    return `${this.details && this.details['lastName'] ? this.details['lastName'] : ''} ${this.details && this.details['firstName'] ? this.details['firstName'] : ''}`;
  }

  getAddress(countries: Country[], translations: any) {
    if (!this.addresses || this.addresses.length < 1) return '-'; //If no addresses at all return -
    const address = this.addresses.find(address => address.type == 'main'); //find main address

    if (!address) return '-'; //If did not found address with type main return -

    const country = countries.find(country => country.id == address.country); //find the country

    let street = address.street ? `${address.street}` : '';
    let houseNumber = address.houseNumber ? `${address.houseNumber}` : '';
    let zipCode = address.zipCode ? address.zipCode : '';
    let city = address.city ? `${address.city},` : '';
    let region = address.region ? `${address.region},` : '';

    if (street !== '' && houseNumber !== '') {
      street = `${street} ${houseNumber},`;
      houseNumber = '';
    }

    return `${street} ${houseNumber} ${zipCode} ${city} ${region} ${translations[country.code.toLowerCase()]}`;
  }

  getMobile(contacts: ContactType[]): string {
    if (!this.contacts) return '-';
    if (!contacts) return '-';

    let type = contacts.find(c => c.slug == 'mobile');
    let contact = this.contacts.find(contact => contact.type == type.id);
    return contact ? contact.value : '-';
  }

  getOccupation(occupations: Occupation[]): string {
    if (!this.occupation) return '-';
    if (!occupations) return '-';

    let find = occupations.find(occ => occ.id == this.occupation);
    return find ? find.name : '-';
  }

  getType(userTypes: UserType[]): string {
    if (!this.type) return '-';
    if (!userTypes) return '-';

    let type = userTypes.find(uType => uType.id == this.type);
    return type ? type.slug : '-';
  }

  getCompanyName(): string {
    if (!this.company) return '-';
    return this.company && this.company.name ? this.company.name : '-';
  }

  forExport(locale: LocalizationService, headers: string[]): Object {
    let rowFilteredHeaders = _.pick(this, headers);
    let exportObject = {};
    for (var prop in rowFilteredHeaders) {
      if (Object.prototype.hasOwnProperty.call(rowFilteredHeaders, prop)) {
        if(prop === 'isActivated') { //if user doesnt have permission, he is not activated
          exportObject[locale.export['isActivated']] = this.permission ? locale.global.yes : locale.global.no;
        }
        else if(prop === 'teraType') {
          exportObject[locale.export['type']] = locale.user[this.teraType];
        }
        else {
          exportObject[locale.export[prop]] = this[prop];
        }
      }
    }
    return exportObject;
  }

}
