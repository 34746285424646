export const CategoryTranslations = {
  'eng': {
    manage: 'Manage categories',
    categoryNamePlaceholder: 'Category name',
    save: 'Save',
    add: 'Add',
    clear: 'Clear'
  },
  'hrv': {
    manage: 'Upravljaj kategorijama',
    categoryNamePlaceholder: 'Naziv kategorije',
    save: 'Spremi',
    add: 'Dodaj',
    clear: 'Očisti'
  }
};
