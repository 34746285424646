import {CompanyDetails} from '../interfaces/company-details';
import {CompanyAddress} from '../interfaces/company-address';
import {CompanyContact} from '../interfaces/company-contact';
import {ContactType} from '../../../../interfaces/common/contact-type';
import {Country} from '../../../../interfaces/common/country';
import {Iban} from '../../../../interfaces/iban';

export class Company {
  id?: number;
  name: string;
  shortName: string;
  website: string;
  isClient: boolean = false;
  industryType: number;
  companyType: number;
  archived: boolean;
  details: CompanyDetails;
  addresses: CompanyAddress[];
  contacts: CompanyContact[];
  ibans: Iban[];
  label?: number[];
  category?: number;
  iconColor: string = '#BF5783';
  showMore: boolean;

  constructor(init: any) {
    if (init.id) this.id = init.id;
    this.name = init.name;
    this.shortName = init.shortName;
    this.website = init.website;
    this.label = init.label || null;
    this.category = init.category || null;
    this.isClient = init.isClient;
    this.archived = init.archived;
    this.industryType = init.industryType;
    this.companyType = init.companyType;
    if (init.iconColor) this.iconColor = init.iconColor;
    this.details = init.details;
    this.addresses = init.addresses;
    this.contacts = init.contacts;
    if (init.ibans) this.ibans = init.ibans;
    this.showMore = false;
  }


  toJson() { //Return JSON object (always use this function before sending to backend)
    let json = {
      id: this.id,
      name: this.name || '',
      shortName: this.shortName || '',
      website: this.website || '',
      isClient: this.isClient,
      archived: this.archived,
      industryType: this.industryType || null,
      companyType: this.companyType || null,
      details: this.details,
      addresses: this.addresses,
      contacts: this.contacts,
      label: this.label || null,
      category: this.category || null,
    };

    if (this.ibans) json['ibans'] = this.ibans;
    if (this.details && this.details.logo) json['details']['logo'] = this.details.logo['media']; //Get logo id
    return json;
  }

  getAddress(countries: Country[], translations: any) {
    if (!this.addresses || this.addresses.length < 1) return '-'; //If no addresses at all return -
    const address = this.addresses.find(address => address.type == 'main'); //find main address

    if (!address) return '-'; //If did not found address with type main return -

    const country = countries.find(country => country.id == address.country); //find the country

    let street = address.street ? `${address.street}` : '';
    let houseNumber = address.houseNumber ? `${address.houseNumber}` : '';
    let zipCode = address.zipCode ? address.zipCode : '';
    let city = address.city ? `${address.city},` : '';
    let region = address.region ? `${address.region},` : '';

    if (street !== '' && houseNumber !== '') {
      street = `${street} ${houseNumber},`;
      houseNumber = '';
    }

    return `${street} ${houseNumber} ${zipCode} ${city} ${region} ${translations[country.code.toLowerCase()]}`;
  }

  getEmail(contacts: ContactType[]): string {
    if (!this.contacts.length) return '-';
    const type = contacts.find(c => c.slug == 'email');
    const contact = this.contacts.find(contact => contact.type == type.id);
    return contact ? contact.value : '-';
  }

  getPhone(contacts: ContactType[]): string {
    if (!this.contacts.length) return '-';
    const type = contacts.find(c => c.slug == 'phone');
    const contact = this.contacts.find(contact => contact.type == type.id);
    return contact ? contact.value : '-';
  }

  getMobile(contacts: ContactType[]): string {
    if (!this.contacts.length) return '-';
    const type = contacts.find(c => c.slug == 'mobile');
    const contact = this.contacts.find(contact => contact.type == type.id);
    return contact ? contact.value : '-';
  }

  getFax(contacts: ContactType[]): string {
    if (!this.contacts.length) return '-';
    const type = contacts.find(c => c.slug == 'fax');
    const contact = this.contacts.find(contact => contact.type == type.id);
    return contact ? contact.value : '-';
  }
}
