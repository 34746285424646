export const ServiceUnavailableTranslations = {
  'eng': {
    title: 'Service unavailable',
    text: 'The server is temporarily unable to service your request due to maintenance downtime or capacity problems. Please try again later.',
    back: 'Go back',
  },
  'hrv': {
    title: 'Interna greška na serveru',
    text: 'Server trenutno nije u mogučnosti procesuirati ovaj zahtjev. Molimo pokušajte ponovno',
    back: 'Vrati se natrag'
  }
};
