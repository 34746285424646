export const GlobalTranslations = {
  'eng': {
    confirm: 'Confirm',
    users: 'Users',
    studies: 'Studies',
    isActivated: 'Activated',
    export: 'Export',
    myProfile: 'My profile',
    move: 'Move',
    selectAll: 'Select all',
    deselectAll: 'Deselect all',
    showRows: 'Show rows',
    showGrid: 'Show grid',
    add: 'Add',
    remove: 'Remove',
    create: 'Create',
    save: 'Save',
    saveChanges: 'Save changes',
    view: 'View',
    edit: 'Edit',
    editAll: 'Edit all',
    archive: 'Archive',
    restore: 'Restore',
    cancel: 'Cancel',
    delete: 'Delete',
    search: 'Search...',
    select: '- Select -',
    back: 'Back',
    send: 'Send',
    success: 'Success',
    yes: 'Yes',
    no: 'No',
    ok: 'Ok',
    new: 'New',
    clear: 'Clear',
    download: 'Download',
    downloads: 'Downloads',
    category: 'Category',
    categories: 'Categories',
    label: 'Label',
    labels: 'Labels',
    activity: 'Activity',
    activities: 'Activities',
    calendar: 'Calendar',
    calendars: 'Calendars',
    manage: 'Manage',
    generalData: 'General data',
    enabled: 'Enabled',
    disabled: 'Disabled',
    image: 'Image',
    images: 'Images',
    newCategory: 'New category',
    editCategory: 'Edit category',
    categoryCreated: 'Category created',
    categoryUpdated: 'Category updated',
    setDefault: 'Set default',
    startDate: 'Start date',
    endDate: 'End date',
    startTime: 'Start time',
    endTime: 'End time',
    tags: 'Tags',
    description: 'Description',
    showMore: 'Show more',
    showLess: 'Show less',
    uploadVideo: 'Upload video',
    uploadFile: 'Upload file',
    all: 'All',
    none: 'None',
    online: 'Online',
    offline: 'Offline',
    by: 'By',
    created: 'Created',
    viewAll: 'View all',
    goTo: 'Go to',
    createComment: 'Leave a comment',
    addNew: 'Add new',
    milestone: 'Milestone',
    warning: 'Warning',
    completed: 'Completed',
    message: 'Message',
    viewProfile: 'View profile',
    supervisor: 'Supervisor',
    group: 'Group',
    street: 'Street address',
    houseNumber: 'House number',
    zipCode: 'Postal code',
    city: 'City',
    region: 'Region',
    country: 'Country',
    active: 'Active',
    type: 'Type',
    projectDiscussions: 'Project discussions',
    classroom: 'Classrooms',
    courseDiscussions: 'Course discussions',
    close: 'Close',
    hours: 'Hours',
    expense: 'Expense',
    saved: 'Saved',
    date: 'Date',
    notifications: 'Notifications',
    notification: 'Notification',
    ibans: 'IBAN',
    bank: 'Bank',
    accountNo: 'Account number',
    pricelist: 'Price list',
    name: 'Name',
    item: 'Item',
    currency: 'Currency',
    vatClass: 'VAT',
    updated: 'Updated',
    deleted: 'Deleted',
    validTo: 'Valid to',
    number: 'Number',
    start: 'Start',
    end: 'End',
    leader: 'Leader',
    createdBy: 'Created by',
    open: 'Open',
    closed: 'Closed',
    withCategories: 'Categories',
    makeRequest: 'Make request',
    accept: 'Accept',
    reject: 'Reject',
    print: 'Print',
    printMonthly: 'Print monthly representation',
    fromYear: 'From year',
    toYear: 'to year',
    year: 'year',
    for: 'for',
    increase: 'increase',
    decrease: 'decrease',
    valueOfFirst: 'Value of',
    optionsSelected: 'options selected',
    months: {
      jan: 'January',
      feb: 'February',
      mar: 'March',
      apr: 'April',
      may: 'May',
      jun: 'June',
      jul: 'July',
      aug: 'August',
      sep: 'September',
      oct: 'October',
      nov: 'November',
      dec: 'December',
    }
  },
  'hrv': {
    confirm: 'Potvrdi',
    users: 'Korisnici',
    studies: 'Studije',
    isActivated: 'Aktiviran',
    export: 'Izvezi',
    myProfile: 'Moj profil',
    move: 'Prebaci',
    selectAll: 'Označi sve',
    deselectAll: 'Odznači sve',
    showRows: 'Prikaži redove',
    showGrid: 'Prikaži mrežu',
    add: 'Dodaj',
    remove: 'Ukloni',
    create: 'Kreiraj',
    save: 'Spremi',
    saveChanges: 'Spremi promjene',
    view: 'Pogledaj',
    edit: 'Uredi',
    editAll: 'Uredi sve',
    archive: 'Arhiviraj',
    restore: 'Vrati',
    cancel: 'Odustani',
    delete: 'Obriši',
    search: 'Pretraži...',
    select: '- Odaberi -',
    back: 'Natrag',
    send: 'Pošalji',
    success: 'Uspjeh',
    yes: 'Da',
    no: 'Ne',
    ok: 'U redu',
    new: 'Novi',
    clear: 'Očisti',
    download: 'Preuzmi',
    downloads: 'Preuzimanja',
    category: 'Kategorija',
    categories: 'Kategorije',
    label: 'Oznaka',
    labels: 'Oznake',
    activity: 'Aktivnost',
    activities: 'Aktivnosti',
    calendar: 'Kalendar',
    calendars: 'Kalendari',
    manage: 'Upravljaj',
    generalData: 'Osnovni podatci',
    enabled: 'Omogućeno',
    disabled: 'Onemogućeno',
    image: 'Slika',
    images: 'Slike',
    newCategory: 'Nova kategorija',
    editCategory: 'Uredi kategoriju',
    categoryCreated: 'Kategorija kreirana',
    categoryUpdated: 'Kategorija uređena',
    setDefault: 'Postavi kao osnovno',
    startDate: 'Datum početka',
    endDate: 'Datum završetka',
    startTime: 'Vrijeme početka',
    endTime: 'Vrijeme završetka',
    tags: 'Tagovi',
    description: 'Opis',
    showMore: 'Prikaži više',
    showLess: 'Prikaži manje',
    uploadVideo: 'Učitaj video',
    uploadFile: 'Učitaj datoteku',
    all: 'Sve',
    none: 'Ništa',
    online: 'Online',
    offline: 'Offline',
    by: 'Od',
    created: 'Kreiran',
    viewAll: 'Prikaži sve',
    goTo: 'Idi na',
    createComment: 'Kreiraj komentar',
    addNew: 'Dodaj',
    milestone: 'Milestone',
    warning: 'Upozorenje',
    completed: 'Završeno',
    message: 'Poruka',
    viewProfile: 'Pogledaj profil',
    supervisor: 'Voditelj',
    group: 'Grupa',
    year: 'godine',
    street: 'Ime ulice',
    houseNumber: 'Kućni broj',
    zipCode: 'Poštanski broj',
    city: 'Grad',
    region: 'Regija',
    country: 'Država',
    active: 'Aktivan',
    deleted: 'Obrisan',
    type: 'Tip',
    projectDiscussions: 'Diskusije projekta',
    classrooms: 'Učionice',
    courseDicussions: 'Diskusije tečaja',
    close: 'Zatvori',
    hours: 'Vrijeme',
    expense: 'Trošak',
    saved: 'Spremljeno',
    date: 'Datum',
    notifications: 'Notifikacije',
    notification: 'Notifikacija',
    ibans: 'IBAN',
    bank: 'Banka',
    accountNo: 'Broj računa',
    pricelist: 'Cjenik',
    name: 'Naziv',
    item: 'Stavka',
    currency: 'Valuta',
    vatClass: 'Porezna stopa',
    updated: 'Editiran',
    validTo: 'Validan do',
    number: 'Broj',
    start: 'Početak',
    end: 'Kraj',
    leader: 'Voditelj',
    createdBy: 'Kreirao',
    open: 'Otvoreno',
    closed: 'Zatvoreno',
    withCategories: 'Kategorijama',
    makeRequest: 'Podnesi zahtjev',
    accept: 'Prihvati',
    reject: 'Odbaci',
    print: 'Ispis',
    printMonthly: 'Ispis mjesečnog prikaza',
    fromYear: 'Od godine',
    toYear: 'do godine',
    for: 'za',
    increase: 'povećanje',
    decrease: 'smanjenje',
    valueOfFirst: 'Vrijednost od',
    optionsSelected: 'odabranih stavki',
    months: {
      jan: 'Siječanj',
      feb: 'Veljača',
      mar: 'Ožujak',
      apr: 'Travanj',
      may: 'Svibanj',
      jun: 'Lipanj',
      jul: 'Srpanj',
      aug: 'Kolovoz',
      sep: 'Rujan',
      oct: 'Listopad',
      nov: 'Studeni',
      dec: 'Prosinac',
    }
  }
};
