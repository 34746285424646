export const ForbiddenTranslations = {
  'eng': {
    title: 'Forbidden',
    back: 'Go back'
  },
  'hrv': {
    title: 'Zabranjeno',
    back: 'Vrati se natrag'
  }
};
