import {ImportValue} from '../import-value';
import {ImportedDocument} from './imported-document';

export class Balance2021 extends ImportedDocument {

  unpaidCapitalClaims: number;
  fixedAssets: number;
  nonMaterialAssets: number;
  materialAssets: number;
  //accumulated amortization
  longTermFinancialAssets: number;
  claims: number;
  delayedTaxAssets: number;
  fleetingAssets: number;
  stockDays: number;
  claimsDays: number;
  shortTermFinancialAssets: number;
  money: number
  futurePaidExpenses: number;
  fullAssets: number;
  activeExtraBalance: number;
  capitalAndReserves: number;
  subscribedCapital: number;
  capitalReserves: number;
  subtractionReturns: number;
  revalorizationReserves: number;
  justValueReserves: number;
  keptProfitOrTransferredLoss: number;
  keptProfit: number;
  transferredLoss: number;
  balanceProfit: number;
  balanceReservations: number;
  longTermObligations: number;
  bankDebt: number;
  otherLongTermObligations: number;
  shortTermObligations: number;
  suppliersDays: number;
  employeesObligations: number;
  otherShortTermObligations: number;
  bankAndOtherObligations: number;
  loanObligations: number;
  delayedExpensesPayments: number;
  passiveSum: number;
  passiveExtraBalance: number;
  minorityInterest: number
  pensionsReserves: number
  taxReserves: number
  otherReserves: number
  claimSum: number

  get obligationsSum(): number {
    return this.shortTermObligations + this.longTermObligations;
  }

  get ownersCapital(): number {
    return this.capitalAndReserves + this.balanceReservations;
  }

  constructor(data: ImportValue[]) {
    super(data);
    this.unpaidCapitalClaims = this.getByAop(1);
    this.fixedAssets = this.getByAop(2);
    this.nonMaterialAssets = this.getByAop(3);
    this.materialAssets = this.getByAop(10);
    this.longTermFinancialAssets = this.getByAop(20);
    this.claims = this.getByAop(31);
    this.delayedTaxAssets = this.getByAop(36);
    this.fleetingAssets = this.getByAop(37);
    this.stockDays = this.getByAop(38);
    this.claimsDays = this.getByAop(46);
    this.shortTermFinancialAssets = this.getByAop(53);
    this.money = this.getByAop(63);
    this.futurePaidExpenses = this.getByAop(64);
    this.fullAssets = this.getByAop(65);
    this.activeExtraBalance = this.getByAop(66);
    this.capitalAndReserves = this.getByAop(67);
    this.subscribedCapital = this.getByAop(68);
    this.capitalReserves = this.getByAop(69);
    this.subtractionReturns = this.getByAop(70);
    this.revalorizationReserves = this.getByAop(76);
    this.justValueReserves = this.getByAop(77); //ispod se krene brkat i dodavat
    this.keptProfitOrTransferredLoss = this.getByAop(83);
    this.keptProfit = this.getByAop(84);
    this.transferredLoss = this.getByAop(85);
    this.balanceProfit = this.getByAop(86);
    this.minorityInterest = this.getByAop(87);
    this.balanceReservations = this.getByAop(90);
    this.pensionsReserves  = this.getByAop(91);
    this.taxReserves = this.getByAop(92);
    this.otherReserves = this.getByAop(96);
    this.longTermObligations = this.getByAop(97);
    this.bankDebt = this.getByAop(103);
    this.otherLongTermObligations = this.getByAop(107);
    this.shortTermObligations = this.getByAop(109);
    this.suppliersDays = this.getByAop(117);
    this.employeesObligations = this.getByAop(119);
    this.otherShortTermObligations = this.getByAop(123);
    this.bankAndOtherObligations = this.getByAop(115);
    this.loanObligations = this.getByAop(114) + this.getByAop(111);
    this.claimSum = this.getByAop(47) + this.getByAop(48) + this.getByAop(49);

    this.delayedExpensesPayments = this.getByAop(124);
    this.passiveSum = this.getByAop(125);
    this.passiveExtraBalance = this.getByAop(126);
  }

}
