// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.
// This is development env

export const environment = {
  production: false,
  mainDomain: `tera.jsteam.gaussx.com`,
  envName: 'development',
  baseUrl: `https://api.tera.jsteam.gaussx.com`,
  port: '2411',
  subdomain: 'terabackoffice',
  version: 'v1'
};
