import {ImportedDocument} from './imported-document';
import {ImportValue} from '../import-value';

export class ProfitAndLoss2021 extends ImportedDocument {

  fullIncome: number;
  businessIncome: number;
  salesIncome: number;
  otherBusinessIncome: number;
  //TODO  subsidiesIncome: number;
  financialIncome: number;
  fullExpense: number;
  salesExpense: number;
  businessExpense: number;
  stockDifference: number;
  materialExpense: number;
  //material subexpenses?
  //TODO service expenses?
  staffExpense: number;
  amortization: number;
  otherExpense: number;
  valueBalancing: number;
  reservations: number;
  otherBusinessExpense: number;
  financialExpense: number;
  profit: number;
  incomeTax: number;
  profitAfterTax: number;
  profitShare: number
  otherProfits: number
  lossShare: number
  otherLosses: number
  lossAfterTax: number
  profitOrLossAfterTax: number
  netProfitLossPeriod: number
  profitPeriod: number
  lossPeriod: number
  businessProfitLoss: number
  ebitda: number
  serviceExpense: number
  salesRevenue: number
  otherBusinessIncomeTwo: number
  otherBusinessIncomeThree: number

  get businessProfit(): number {
    return this.businessIncome - this.businessExpense;
  }

  constructor(data: ImportValue[]) {
    super(data);
    this.businessIncome = this.getByAop(127);
    this.salesIncome = this.getByAop(129);
    this.otherBusinessIncome = this.getByAop(127) - this.getByAop(129);
    this.otherBusinessIncomeThree = this.getByAop(127) - this.getByAop(129) - this.getByAop(128);
    //TODO  this.subsidiesIncome = ;
    // this.fullIncome = this.businessIncome + this.salesIncome + this.otherBusinessIncome;
    this.fullIncome = this.getByAop(127) + this.getByAop(156) + this.getByAop(175) + this.getByAop(176);
    this.financialIncome = this.getByAop(156);
    this.businessExpense = this.getByAop(134) + this.getByAop(135) + this.getByAop(139) + this.getByAop(143) + this.getByAop(144) + this.getByAop(145) + this.getByAop(148) + this.getByAop(155);
    this.stockDifference = this.getByAop(134);
    this.materialExpense = this.getByAop(136) + this.getByAop(137) + this.getByAop(138);
    //material subexpenses?
    this.serviceExpense = this.getByAop(137);
    this.staffExpense = this.getByAop(139);
    this.amortization = this.getByAop(143);
    this.otherExpense = this.getByAop(144);
    this.valueBalancing = this.getByAop(145);
    this.reservations = this.getByAop(148);
    this.otherBusinessExpense = this.getByAop(155);
    this.otherBusinessIncomeTwo = this.getByAop(132);
    this.financialExpense = this.getByAop(167);
    this.profitShare = this.getByAop(175);
    this.otherProfits = this.getByAop(176);
    this.lossShare = this.getByAop(177);
    this.otherLosses = this.getByAop(178)
    this.fullExpense = this.getByAop(133) + this.getByAop(167) + this.getByAop(177) + this.getByAop(178);
    // this.fullExpense = this.businessExpense + this.stockDifference + this.materialExpense + this.staffExpense
    //   + this.amortization + this.otherExpense + this.valueBalancing + this.reservations + this.otherBusinessExpense; //TODO service expenses?
    this.profit = this.getByAop(181);
    this.incomeTax = this.getByAop(184);
    this.profitOrLossAfterTax = this.getByAop(179) - this.getByAop(180);
    this.profitAfterTax = this.getByAop(181) - this.getByAop(184);
    this.lossAfterTax = this.getByAop(183);
    this.netProfitLossPeriod = this.getByAop(185);
    this.profitPeriod =this.getByAop(181) - this.getByAop(184);
    this.lossPeriod = this.getByAop(187);
    this.businessProfitLoss = this.businessProfit;
    this.ebitda = this.fullIncome - this.fullExpense;
    this.salesExpense = this.getByAop(135);
    this.salesRevenue = this.getByAop(128) + this.getByAop(129);
  }
}
