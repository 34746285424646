export const BranchTranslations = {
  'eng': {
    add: 'Add',
    remove: 'Remove',
    branch: 'Branch',
    branches: 'Branches',
    newbranch: 'New branch',
    editbranch: 'Edit branch',
    addnew: 'Add new',
    view: 'View',
    edit: 'Edit',
    archive: 'Archive',
    restore: 'Restore',
    manage: 'Manage',
    select: '- Select -',
    supervisor: 'Supervisor',

    name: 'Name',
    noteofbusinessarea: 'Note of business area',
    noteofbusinessareainfo: 'Obligatory if branch is issuing invoices',
    workhours: 'Work hours',
    label: 'Label',
    leader: 'Leader',

    city: 'City',
    zipcode: 'Zip code',
    region: 'Region',
    housenumber: 'House number',
    postalcode: 'Postal code',
    streetaddress: 'Street address',
    stateprovince: 'State/Province',
    country: 'Country',
    contacts: 'Contacts',
    type: 'Type',
    value: 'Value',
    cancel: 'Cancel',
    search: 'Search...',
    create: 'Create',
    savechanges: 'Save changes',

    success: 'Success',
    branchcreated: 'Branch created',
    branchupdated: 'Branch updated',
    brancharchived: 'Branch archived',
    branchRestored: 'Branch restored',

    cashregisters: 'Cash registers',
    delete: 'Delete',
    company: 'Company',

    newdevice: 'New device',
    editdevice: 'Edit device',
    devicetype: 'Type',
    retail: 'Retail',
    cashregister: 'Cash register ',
    invoicestartnumber: 'Invoice start number',
    cashregistercreated: 'Cash register created'

  },
  'hrv': {
    add: 'Dodaj',
    remove: 'Ukloni',
    branch: 'Poslovnica',
    branches: 'Poslovnice',
    newbranch: 'Nova poslovnica',
    editbranch: 'Uredi poslovnicu',
    addnew: 'Dodaj',
    view: 'Prikaži',
    edit: 'Uredi',
    archive: 'Arhiviraj',
    restore: 'Vrati',
    manage: 'Upravljaj',
    select: '- Odaberi -',

    name: 'Naziv',
    noteofbusinessarea: 'Oznaka poslovnog prostora',
    noteofbusinessareainfo: 'Obavezno ako poslovnica izdaje račune',
    workhours: 'Radno vrijeme',
    label: 'Oznaka',
    leader: 'Voditelj',

    city: 'Grad',
    zipcode: 'Poštanski broj',
    region: 'Regija',
    housenumber: 'Kućni broj',
    postalcode: 'Poštanski broj',
    streetaddress: 'Ulica',
    stateprovince: 'Općina/Naselje',
    country: 'Država',
    contacts: 'Kontakti',
    type: 'Tip',
    value: 'Vrijednost',
    cancel: 'Poništi',
    search: 'Pretraži...',
    create: 'Kreiraj',
    savechanges: 'Spremi promjene',

    success: 'Uspjeh',
    branchcreated: 'Poslovnica je kreirana',
    branchupdated: 'Poslovnica je uređena',
    brancharchived: 'Poslovnica je arhivirana',
    branchRestored: 'Poslovnica je otvorena',

    cashregisters: 'Blagajne',
    delete: 'Obriši',
    company: 'Tvrtka',
    supervisor: 'Voditelj',

    newdevice: 'Nova blagajna',
    editdevice: 'Uređivanje blagajne',
    devicetype: 'Vrsta blagajne',
    retail: 'Maloprodajna',
    cashregister: 'Blagajna ',
    invoicestartnumber: 'Početni broj računa',
    cashregistercreated: 'Blagajna kreirana'
  }
};
