import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {ThemeService} from '../../services/theme.service';
import {LocalizationService} from '../../services/localization.service';
import {FilterData} from '../../classes/filter-data';
import {BehaviorSubject} from 'rxjs';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent implements OnInit {
  public changePage: number = null;
  public numberOfPages: any = [];
  public showLimit: boolean = false;

  @Input() filterData: BehaviorSubject<FilterData> = null;
  @Input() totalPages: number = null;
  @Input() totalRecords: number = null;
  @Input() limits: number[] = [10, 25, 50, 100];

  constructor(
    public _locale: LocalizationService,
    public  _theme: ThemeService) {
  }

  ngOnInit() {
    for (let i = 0; i <= this.totalPages; i++) {
      this.numberOfPages.push(i);
    }
  }

  next(): void {
    let currentData = this.filterData.getValue();
    if (currentData.page >= this.totalPages) return;
    this.customPageReset();
    currentData.page++;
    this.filterData.next(currentData);

  }

  prev(): void {
    let currentData = this.filterData.getValue();
    if (currentData.page > 1) {
      currentData.page--;
      this.customPageReset();
      this.filterData.next(currentData);
    }
  }

  customPage(page): void {
    let currentData = this.filterData.getValue();

    let customInputPage = page;

    if (customInputPage === '' || parseInt(customInputPage, 0) === 0 || customInputPage === null) return;

    if (customInputPage > this.totalPages) {
      this.changePage = this.totalPages;
      return;
    }

    currentData.page = parseInt(page, 0);
    this.filterData.next(currentData);
  }

  customPageReset(): void {
    this.changePage = null;
  }

  setLimit(limit: number): void {
    let currentData = this.filterData.getValue();
    if (currentData.limit == limit) return;
    currentData.limit = limit;
    currentData.page = 1;
    this.filterData.next(currentData);
    this.showLimit = false;
  }


}
