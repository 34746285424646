import {environment} from '../../environments/environment';

export const AuthRoutes = {
  register: {
    method: 'Post',
    url: `${environment.baseUrl}/api/${environment.version}/register`
  },
  login: {
    method: 'Post',
    url: `${environment.baseUrl}/api/${environment.version}/login`
  },
  refreshToken: {
    method: 'Post',
    url: `${environment.baseUrl}/api/${environment.version}/refresh`
  },
  permissionsGroup: {
    method: 'Get',
    url: `${environment.baseUrl}/api/${environment.version}/permissionGroups`
  },
  resetPassword: {
    method: 'Post',
    url: `${environment.baseUrl}/api/${environment.version}/user/password/reset`
  },
  forgotPassword: {
    method: 'Post',
    url: `${environment.baseUrl}/api/${environment.version}/user/password/forgot`
  },
  setNewPassword: {
    method: 'Post',
    url: `${environment.baseUrl}/api/${environment.version}/user/password/set`
  },
  checkUserParamAvailable: {
    method: 'Post',
    url: `${environment.baseUrl}/api/${environment.version}/user/username`
  }
};
