import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import * as flatpickrImport from 'flatpickr';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {Input, ViewChild, forwardRef} from '@angular/core';
import {ToggleService} from '../../services/toggle.service';
import * as moment from 'moment';

const flatpickr: any = flatpickrImport;

@Component({
  selector: 'app-flatpickr',
  templateUrl: './flatpickr.component.html',
  styleUrls: ['./flatpickr.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FlatpickrComponent),
      multi: true
    }
  ]
})
export class FlatpickrComponent implements OnInit, ControlValueAccessor {

  @ViewChild('datepicker') pickerEl;

  private innerValue: Date | Date[] | number | number[];
  private flatpickrInstance: any;

  @Input() config: any = {};
  @Input() disabled: boolean = false;
  @Input() selectedValue: Date;
  @Input('highlightedDays') highlightedDays: any = null;

  @Output('onSelect') onSelect: EventEmitter<any> = new EventEmitter<any>();

  constructor(public _toggle: ToggleService) {
  }

  ngOnInit() {

    this.config.onChange = (selectedDates) => {
      if (this.config.mode == 'multiple' || this.config.mode == 'range') this.setDate(selectedDates);
      else this.setDate(selectedDates[0]);
    };

    this.config.onMonthChange = () => {
      if (this.highlightedDays) this.displayHighlightedDays(this.flatpickrInstance);
    };

    this.config.onYearChange = () => {
      if (this.highlightedDays) this.displayHighlightedDays(this.flatpickrInstance);
    };

    if (this.selectedValue) this.config.defaultDate = new Date(this.selectedValue);
    // Fix click outisde in popup modal start
    this.config.onOpen = () => {
      this._toggle.datepickerState = true;
      if (this.highlightedDays) this.displayHighlightedDays(this.flatpickrInstance);
    };

    this.config.onClose = () => {
      this._toggle.datepickerState = false;
    };

    // Fix click outisde in popup modal end
    this.flatpickrInstance = flatpickr(this.pickerEl.nativeElement, this.config);
  }

  displayHighlightedDays(fp) {
    for (let i = 0; i < fp.days.children.length; i++) {
      if (this.highlightedDays && this.highlightedDays.includes(moment(fp.days.children[i].dateObj.getTime()).format('DD-MM-YYYY'))) fp.days.children[i].className += ' custom-style';
    }
  }

  setDate(date: Date) {
    this.innerValue = date;
    this.selectedValue = this.innerValue;
    this.propagateChange(this.innerValue);
    this.onSelect.emit(this.innerValue);
  }

  writeValue(value: Date) {
    if (value !== undefined) {
      this.flatpickrInstance.setDate(value);
      this.innerValue = value;
      this.onSelect.emit(this.innerValue);
    }
  }

  propagateChange(_: any) {
  }

  registerOnChange(fn) {
    this.propagateChange = fn;
  }

  registerOnTouched() {
  }

}
