import {Injectable} from '@angular/core';
import {urlValues} from '../configs/url.values';
import {Observable} from 'rxjs/index';
import {User} from '../modules/layout/user/classes/user';
import {StorageService} from './storage.service';
import {HttpClient} from '@angular/common/http';
import {GlobalService} from './global.service';
import {Router} from '@angular/router';
import * as _ from 'lodash';
import {ApiService} from './api.service';
import {throwError as observableThrowError} from 'rxjs';

@Injectable()
export class AuthService {

  //<editor-fold desc="Auth variables">
  public apiKey: string = '';
  public accessToken: string = '';
  public refreshToken: string = '';
  //</editor-fold>

  public loggedUser: User = null;

  public routerState: string = 'home';

  constructor(private _storage: StorageService,
              private _http: HttpClient,
              private _global: GlobalService,
              private _router: Router,
              private _api: ApiService) {

    this.apiKey = this._storage.get('api-key'); //Set api key from local storage
    this.accessToken = this._storage.get('access-token'); //Set access token from local storage
    this.refreshToken = this._storage.get('refresh-token'); //Set refresh token from local storage

    if (this._storage.get('user')) this.loggedUser = new User(this._storage.get('user')); //NO need to use setUserParams because it has already been done i auth component
  }

  //<editor-fold desc="Auth functions">
  getAuthToken(): string {
    return this.accessToken;
  }

  getRefreshToken(): string {
    return this.refreshToken;
  }

  refreshTheToken(): Observable<string> {
    return new Observable(obs => {
      this._http.get(urlValues.auth.refreshToken.url).subscribe(res => {
        //Update local storage
        this._storage.set('access-token', res['data'].tokens.accessToken);
        this._storage.set('refresh-token', res['data'].tokens.refreshToken);
        //Update service
        this.accessToken = res['data'].tokens.accessToken;
        this.refreshToken = res['data'].tokens.refreshToken;
        obs.next(this.accessToken); //Return only access token
        obs.complete();
      }, err => {
        this.accessToken = '';
        this._storage.set('access-token', '');
        obs.next(this.accessToken);
        obs.complete();
      });
    });
  }

  //Check users permissions, can user access a route or do something in app
  checkPermission(permission: string): boolean {
    if (!this.loggedUser) return;
    return _.includes(this.loggedUser.permissions, permission);
  }

  checkModulePermission(permissionName: string): boolean {
    if (!this.loggedUser) return;
    let permission = _.find(this.loggedUser.modulePermissions, {moduleName: permissionName});
    if (!permission) return false;
    return permission.access;
  }

  getUserPermissions(updateUser: boolean): Observable<any> {
    return new Observable<any>(obs => {
      this._api.send(urlValues.user.permissions.method, urlValues.user.permissions.url).subscribe(res => {
        if (updateUser) {
          this.loggedUser.permissions = _.map(res['data'].permissions, act => act.action); //Return only actions
          this.loggedUser.modulePermissions = res['data'].modulePermissions; //Return only actions
        }
        obs.next(res);
        obs.complete();
      }, err => {
        this._storage.clear();
        this._router.navigate(['/auth']);
        obs.error(err);
        obs.complete();
      });
    });
  }


  logOut(returnErr?: boolean) {
    //Remove data from local storage
    this._storage.clear();
    this.accessToken = '';
    this.refreshToken = '';
    this.loggedUser = null;
    this._global.settings = null;
    //Redirect back to login page
    // window.location.href = '/auth'; //TODO: figure out how to redirect with router
    this._router.navigate(['/auth']);
    if (returnErr) return observableThrowError('');
  }

  //</editor-fold>

  //<editor-fold desc="Logged user manipulations">
  returnUserLocalStorageJSON(user: any): any {
    return {
      id: user.id,
      role: user.role,
      company: user.company ? user.company : null,
      branch: user.branch,
      teraType: user.teraType,
      details: {
        firstName: user.details.firstName,
        lastName: user.details.lastName,
        displayName: `${user.details.firstName} ${user.details.lastName}`,
        profileImage: user.details.profileImage
      },
      iconColor: this._global.getRandomColor(user.id),
    };
  }

  updateLoggedUser(user: any, firstCompany?: boolean): void {
    let localStorageUser = this._storage.get('user');
    this._storage.set('user', this.returnUserLocalStorageJSON(_.merge(localStorageUser, user))); //Update local storage
    this.loggedUser = new User(_.merge(this.loggedUser, user));
    if (firstCompany) setTimeout(() => this._router.navigate(['home'])); //First main company created
  }

  updateLoggedUserCompany(companyId: number, redirect?: boolean): void {
    this.loggedUser.company = companyId;
    this._storage.set('user', this.returnUserLocalStorageJSON(this.loggedUser)); //Update local storage
    if (redirect) this._router.navigate(['home']);
  }

  //</editor-fold>
}
