export const AdminTranslations = {
  'eng': {
    general: 'General',
    lists: 'Lists',
    cancel: 'Cancel',
    save: 'Save changes',
    listsettings: 'List settings',
    generalsettings: 'General settings',
    settingssave: 'Settings save',
    success: 'Success',
    defaultTheme: 'Default',
    customTheme: 'Custom',
    businessTheme: 'Business',
    attributes: 'Attributes',
    attributeSets: 'Attribute sets',
    addNew: 'Add new',
    edit: 'Edit',
    remove: 'Remove',
    add: 'Add',
    attributeSet: 'Attribute set',
    name: 'Name',
    attributeSetCreated: 'Attribute set created',
    attributeSetUpdated: 'Attribute set updated',
    attributeSetArchived: 'Attribute set archived',
    attributeSetRestored: 'Attribute set restored',
    documents: 'Documents',
    uploadHeader: 'Upload header',
    uploadFooter: 'Upload footer',
    defaultType: 'Default type assignation',
    defaultTypeNote: 'Show document types to specific departments, workgroups or people.',
    enableMemo: 'Enable company memo',
    documentTraceability: 'Document traceability',
    documentTraceabilityNote: 'Define order in which documents can be created..',
    documentAuthorisation: 'Document authorisation',
    documentAuthorisationNote: 'Set authorisation persons for document types.',
    documentSettings: 'Document settings',
    company: 'Company',
    defaultLanguage: 'Default language',
    defaultColorTheme: 'Default color theme',
    currency: 'Currency',
    mainCompanyCurrency: 'Main company currency',
    currencyFormat: 'Currency format',
    numberFormat: 'Number format',
    dateAndTime: 'Date & Time',
    timeFormat: 'Time format',
    dateFormat: 'Date format',
    timeZone: 'Time zone',
    generalSettings: 'General settings',
    twoStepAuth: 'Two-step login authentication',
    daylightSavings: 'Daylight savings',
    entityType: 'Entity type',
    branch: 'Branch',
    department: 'Department',
    document: 'Document',
    course: 'Course',
    class: 'Class',
    exam: 'Exam',
    product: 'Product',
    project: 'Project',
    task: 'Task',
    user: 'User',
    warehouse: 'Warehouse',
    bin: 'Bin',
    eng: 'English',
    hrv: 'Croatian'
  },
  'hrv': {
    general: 'Općenito',
    lists: 'Liste',
    cancel: 'Poništi',
    save: 'Spremi promjene',
    listsettings: 'Postavke listi',
    generalsettings: 'Općenite postavke',
    settingssave: 'Postavke promijenjene',
    success: 'Uspješno',
    defaultTheme: 'Standardna',
    customTheme: 'Prilagođena',
    businessTheme: 'Poslovna',
    attributes: 'Atributi',
    attributeSets: 'Atribut setovi',
    addNew: 'Dodaj',
    edit: 'Uredi',
    remove: 'Ukloni',
    add: 'Dodaj',
    attributeSet: 'Atribut set',
    name: 'Naziv',
    attributeSetCreated: 'Atribut set je kreiran',
    attributeSetUpdated: 'Atribut set je uređen',
    attributeSetArchived: 'Atribut set je arhiviran',
    attributeSetRestored: 'Atribut set je vraćen',
    documents: 'Dokumenti',
    uploadHeader: 'Učitaj zaglavlje',
    uploadFooter: 'Učitaj podnožje',
    defaultType: 'Dodjela osnovnog tipa',
    defaultTypeNote: 'Prikaži tipove dokumenata specifičnim odjelima, radnim grupama ili osobama.',
    enableMemo: 'Omogući memorandum tvrtke',
    documentTraceability: 'Slijedivost dokumenata',
    documentTraceabilityNote: 'Definiraj red kojim se dokumenti mogu kreirati.',
    documentAuthorisation: 'Autorizacija dokumenata',
    documentAuthorisationNote: 'Postavi osobe za autorizaciju za tipove dokumenata.',
    documentSettings: 'Postavke dokumenata',
    company: 'Tvrtka',
    defaultLanguage: 'Osnovni jezik',
    defaultColorTheme: 'Osnovna tema',
    currency: 'Valuta',
    mainCompanyCurrency: 'Valuta tvrtke',
    currencyFormat: 'Format valute',
    numberFormat: 'Format broja',
    dateAndTime: 'Datum i vrijeme',
    timeFormat: 'Format vremena',
    dateFormat: 'Format datuma',
    timeZone: 'Vremenska zona',
    generalSettings: 'Opće postavke',
    twoStepAuth: 'Two-step login authentication',
    daylightSavings: 'Ljetno računanje vremena',
    entityType: 'Entity type',
    branch: 'Poslovnica',
    department: 'Odijel',
    document: 'Dokument',
    course: 'Tečaj',
    class: 'Učionica',
    exam: 'Ispit',
    product: 'Proizvod',
    project: 'Projekt',
    task: 'Zadatak',
    user: 'Korisnik',
    warehouse: 'Skladište',
    bin: 'Polica',
    eng: 'Engleski',
    hrv: 'Hrvatski'
  }
};
