<router-outlet></router-outlet>
<ngx-loading-bar [includeSpinner]="false"></ngx-loading-bar>

<p-toast *ngIf="(_global.appMessageSettings | async)"
         (onClose)="_global.onCloseAppMessage()"
>
</p-toast>

<!--TOAST VALUES
       [(value)]="_global.appMessages"
       [sticky]="(_global.appMessageSettings | async).sticky"
       [life]="(_global.appMessageSettings | async).life"
-->
