import {Component, Input, OnInit} from '@angular/core';
import {LocalizationService} from '../../services/localization.service';

@Component({
  selector: 'app-error-msg',
  templateUrl: './error-msg.component.html',
  styleUrls: ['./error-msg.component.scss']
})
export class ErrorMsgComponent implements OnInit {

  @Input() object: any;

  public messages: object = {};

  constructor(private _locale: LocalizationService) {
  }

  ngOnInit() {
    this.messages = {
      required: () => {
        return this._locale.error.required;
      },
      max: (values): string => {
        return `${this._locale.error.max} ${values.max}`;
      },
      email: (): string => {
        return this._locale.error.email;
      },
      minlength: (value): string => {
        return `${this._locale.error.minLength} ${value.requiredLength} ${this._locale.error.characters}`;
      },
      maxlength: (value): string => {
        return `${this._locale.error.maxLength} ${value.requiredLength} ${this._locale.error.characters}`;
      },
      validateOib: (): string => {
        return this._locale.error.validateOib;
      },
      validPhone: (): string => {
        return this._locale.error.validPhone;
      },
      validPassword: (): string => {
        return this._locale.error.validPassword;
      },
      mailFormat: (): string => {
        return this._locale.error.mailFormat;
      },
      salutationFormat: (): string => {
        return this._locale.error.salutationFormat;
      },
      shippingAddressMax: (): string => {
        return this._locale.error.shippingAddressMax;
      },
      billingAddressMax: (): string => {
        return this._locale.error.billingAddressMax;
      },
      mainAddressMax: (): string => {
        return this._locale.error.mainAddressMax;
      },
      isOfAge: (): string => {
        return this._locale.error.isOfAge;
      },
      maxCheckedItems: (): string => {
        return this._locale.error.maxCheckedItems;
      },
      spaceNotAllowed: (): string => {
        return this._locale.error.spaceNotAllowed;
      },
      passwordNotMatch: (): string => {
        return this._locale.error.passwordNotMatch;
      },
      notAvailableUsername: (suggested?: string[]): string => {
        return `${this._locale.error.notAvailableUsername} ${suggested.toString()}`;
      },
      notAvailableEmail: (): string => {
        return this._locale.error.notAvailableEmail;
      },
      dateStartEndGreater: (dates: string[]): string => {
        if (!dates) return this._locale.error.startDateGreaterThenEndDate;
        return `${this._locale.error[dates['dates'][0]]} ${this._locale.error.cantBeGreaterThen} ${this._locale.error[dates['dates'][1]]}`;
      },
      dateStartEndLesser: (): string => {
        return this._locale.error.endDateLesserThenStartDate;
      },
      timeRegExp: (): string => {
        return this._locale.error.timeRegExp;
      },
      numberFirst: (): string => {
        return this._locale.error.numberFirst;
      },
      maxTagLength: (err: any): string => {
        return `${this._locale.error.maxTagLength} ${err.max} ${this._locale.error.characters}`;
      },
      passingPointsNegative: (err: any): string => {
        return this._locale.error.passingPointsNegative;
      },
      passingPointsMoreThanTotal: (err: any): string => {
        return this._locale.error.passingPointsMoreThanTotal;
      },
      atLeastOneQuestion: (err: any): string => {
        return this._locale.error.atLeastOneQuestion;
      },
      atLeastOneCorrectAnswer: (err: any): string => {
        return this._locale.error.atLeastOneCorrectAnswer;
      },
      atLeastTwoAnswers: (err: any): string => {
        return this._locale.error.atLeastTwoAnswers;
      },
      sameAnswerText: (err: any): string => {
        return this._locale.error.sameAnswerText;
      },
      atLeastOneAnswer: (err: any): string => {
        return this._locale.error.atLeastOneAnswer;
      },
      numAnswerGaps: (err: any): string => {
        return this._locale.error.numAnswerGaps;
      },
      atLeastTwoPairs: (err: any): string => {
        return this._locale.error.atLeastTwoPairs;
      },
      minNotBiggerThenMaxStock: (): string => {
        return this._locale.error.minNotBiggerThenMaxStock;
      },
      usernameSpecialChars: (): string => {
        return this._locale.error.usernameSpecialChars;
      }
    };
  }

  returnErrors() {
    return this.object.errors ? Object.keys(this.object.errors) : [];
  }

  returnErrorMessage(key) {
    return this.messages[key](this.object.errors[key]);
  }

}
