import {User} from '../modules/layout/user/classes/user';

export class PromotionRequest {

  id: number;
  userId: number;
  user?: User;

  constructor(data: Object) {
    this.id = data['id'];
    if(typeof data['user'] === 'object') {
      this.user = new User(data['user']);
      this.userId = this.user.id;
    }
    else {
      this.userId = data['user'];
    }
  }

}
