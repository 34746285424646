import {Injectable} from '@angular/core';
import {IBreadcrumb} from '../interfaces/breadcrumbs';
import {ActivatedRoute, PRIMARY_OUTLET} from '@angular/router';

@Injectable()
export class BreadcrumbService {

  public breadcrumbs: IBreadcrumb[];

  constructor() {

  }

  public getBreadcrumbs(route: ActivatedRoute, url: string = '', breadcrumbs: IBreadcrumb[] = []): IBreadcrumb[] {
    const ROUTE_DATA_BREADCRUMB: string = 'breadcrumb';
    //get the child routes
    let children: ActivatedRoute[] = route.children;
    //return if there are no more children
    if (children.length === 0) return breadcrumbs;

    //iterate over each children
    for (let child of children) {
      //verify primary route
      if (child.outlet !== PRIMARY_OUTLET) continue;

      //verify the custom data property "breadcrumb" is specified on the route
      if (!child.snapshot.data.hasOwnProperty(ROUTE_DATA_BREADCRUMB)) {
        return this.getBreadcrumbs(child, url, breadcrumbs);
      }

      //get the route's URL segment
      let routeURL: string = child.snapshot.url.map(segment => segment.path).join('/');

      //append route URL to URL
      url += `/${routeURL}`;

      //add breadcrumb
      let breadcrumb: IBreadcrumb = {
        label: child.snapshot.data[ROUTE_DATA_BREADCRUMB],
        params: child.snapshot.params,
        url: url
      };

      //NOTE: set breadcrumb param in routing to empty string if you do not want to display it
      //Check if breadcrumb is empty string then do not push to breadcrumbs
      if (breadcrumb.label !== '') breadcrumbs.push(breadcrumb);

      //recursive
      return this.getBreadcrumbs(child, url, breadcrumbs);
    }
  }

  addCustomBreadcrumb(label: string, index?: number, url?: string): void { //Pushes a custom breadcrumb name, to the end of array
    if (this.breadcrumbs.find(breadcrumb => breadcrumb.label == label)) return;
    let newBreadcrumb = {label: label, custom: true};
    if (url) newBreadcrumb['url'] = url; //Add url if provided
    index ? this.breadcrumbs.splice(index, 0, newBreadcrumb) : this.breadcrumbs.push(newBreadcrumb);
  }

}

