export const ToolTipTranslations = {
  'eng': {
    showRows: 'Show rows',
    showGrid: 'Show grid'
  },
  'hrv': {
    showRows: 'Prikaži redove',
    showGrid: 'Prikaži kartice'
  }
};
