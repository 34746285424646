import {RouteReuseStrategy} from '@angular/router';
import {ActivatedRouteSnapshot, DetachedRouteHandle} from '@angular/router';
import { Injectable } from "@angular/core";

@Injectable()
export class CustomRouteReuseStrategy extends RouteReuseStrategy {

  shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
    let isNonReusable: boolean = future.component && (<any>future.component).isNonReusable;
    return future.routeConfig === curr.routeConfig && !isNonReusable;
  }

  shouldDetach(route: ActivatedRouteSnapshot): boolean { return false; }
  store(route: ActivatedRouteSnapshot, detachedTree: DetachedRouteHandle): void {}
  shouldAttach(route: ActivatedRouteSnapshot): boolean { return false; }
  retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle|null { return null; }

}

