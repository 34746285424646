import {ImportValue} from '../import-value';

export abstract class ImportedDocument {
  values: ImportValue[];
  endDate: string;

  protected constructor(data: ImportValue[]) {
    this.values = data;
  }

  getByAop(aop: number): number {
    let aopValue: ImportValue = this.values?.find(value => value.aop === aop);
    return aopValue && aopValue.value ? aopValue.value : 0;
  }

}
