import {environment} from '../../../../environments/environment';

export const ProjectRoutes = {
  filter: {
    url: `${environment.baseUrl}/api/${environment.version}/tera/project/filter`,
    method: 'Post'
  },
  create: {
    url: `${environment.baseUrl}/api/${environment.version}/tera/project/create`,
    method: 'Post'
  },
  get: {
    url: (id: number) => `${environment.baseUrl}/api/${environment.version}/tera/project/get/${id}`,
    method: 'Get'
  },
  update: {
    url: (id: number) => `${environment.baseUrl}/api/${environment.version}/tera/project/update/${id}`,
    method: 'Patch'
  },
  delete: {
    url: (id: number) => `${environment.baseUrl}/api/${environment.version}/tera/project/delete/${id}`,
    method: 'Delete'
  },

  projectActivities: {
    create: {
      url: (id: number) => `${environment.baseUrl}/api/${environment.version}/tera/project/${id}/activity/create`,
      method: 'Post'
    },
    get: {
      url: (id: number) => `${environment.baseUrl}/api/${environment.version}/tera/project/${id}/activity/get`,
      method: 'Get'
    },
    update: {
      url: (id: number) => `${environment.baseUrl}/api/${environment.version}/tera/project/activity/update/${id}`,
      method: 'Patch'
    },
    delete: {
      url: (id: number) => `${environment.baseUrl}/api/${environment.version}/tera/project/activity/delete/${id}`,
      method: 'Delete'
    },
  },

  budgetActivities: {
    create: {
      url: (id: number) => `${environment.baseUrl}/api/${environment.version}/tera/project/${id}/budget/create`,
      method: 'Post'
    },
    get: {
      url: (id: number) => `${environment.baseUrl}/api/${environment.version}/tera/project/${id}/budget/get`,
      method: 'Get'
    },
    update: {
      url: (id: number) => `${environment.baseUrl}/api/${environment.version}/tera/project/budget/update/${id}`,
      method: 'Patch'
    },
    delete: {
      url: (id: number) => `${environment.baseUrl}/api/${environment.version}/tera/project/budget/delete/${id}`,
      method: 'Delete'
    },
  },

  partnerDetails: {
    create: {
      url: (id: number) => `${environment.baseUrl}/api/${environment.version}/tera/project/${id}/partner/create`,
      method: 'Post'
    },
    get: {
      url: (id: number) => `${environment.baseUrl}/api/${environment.version}/tera/project/${id}/partner/get`,
      method: 'Get'
    },
    update: {
      url: (id: number) => `${environment.baseUrl}/api/${environment.version}/tera/project/partner/update/${id}`,
      method: 'Patch'
    },
    delete: {
      url: (id: number) => `${environment.baseUrl}/api/${environment.version}/tera/project/partner/delete/${id}`,
      method: 'Delete'
    },
  }

};
