import {ImportedDocument} from './imported-document';
import {ImportValue} from '../import-value';

export class ProfitAndLoss extends ImportedDocument {

  fullIncome: number;
  businessIncome: number;
  salesIncome: number;
  otherBusinessIncome: number;
  //TODO  subsidiesIncome: number;
  financialIncome: number;
  fullExpense: number;
  salesExpense: number;
  businessExpense: number;
  stockDifference: number;
  materialExpense: number;
  //material subexpenses?
  //TODO service expenses?
  staffExpense: number;
  amortization: number;
  otherExpense: number;
  valueBalancing: number;
  reservations: number;
  otherBusinessExpense: number;
  financialExpense: number;
  profit: number;
  incomeTax: number;
  profitAfterTax: number;
  profitShare: number
  otherProfits: number
  lossShare: number
  otherLosses: number
  lossAfterTax: number
  profitOrLossAfterTax: number
  netProfitLossPeriod: number
  profitPeriod: number
  lossPeriod: number
  businessProfitLoss: number
  ebitda: number
  serviceExpense: number
  salesRevenue: number
  otherBusinessIncomeTwo: number
  otherBusinessIncomeThree: number

  get businessProfit(): number {
    return this.businessIncome - this.businessExpense;
  }

  constructor(data: ImportValue[]) {
    super(data);
    this.businessIncome = this.getByAop(125);
    this.salesIncome = this.getByAop(127);
    this.otherBusinessIncome = this.getByAop(125) - this.getByAop(127);
    this.otherBusinessIncomeThree = this.getByAop(125) - this.getByAop(127) - this.getByAop(126);
    //TODO  this.subsidiesIncome = ;
    // this.fullIncome = this.businessIncome + this.salesIncome + this.otherBusinessIncome;
    this.fullIncome = this.getByAop(125) + this.getByAop(154) + this.getByAop(173) + this.getByAop(174);
    this.financialIncome = this.getByAop(154);
    this.businessExpense = this.getByAop(132) + this.getByAop(133) + this.getByAop(137) + this.getByAop(141) + this.getByAop(142) + this.getByAop(143) + this.getByAop(146) + this.getByAop(153);
    this.stockDifference = this.getByAop(132);
    this.materialExpense = this.getByAop(134) + this.getByAop(135) + this.getByAop(136);
    //material subexpenses?
    this.serviceExpense = this.getByAop(135);
    this.staffExpense = this.getByAop(137);
    this.amortization = this.getByAop(141);
    this.otherExpense = this.getByAop(142);
    this.valueBalancing = this.getByAop(143);
    this.reservations = this.getByAop(146);
    this.otherBusinessExpense = this.getByAop(153);
    this.otherBusinessIncomeTwo = this.getByAop(130);
    this.financialExpense = this.getByAop(165);
    this.profitShare = this.getByAop(173);
    this.otherProfits = this.getByAop(174)
    this.lossShare = this.getByAop(175)
    this.otherLosses = this.getByAop(176)
    this.fullExpense = this.getByAop(131) + this.getByAop(165) + this.getByAop(175) + this.getByAop(176);;
    // this.fullExpense = this.businessExpense + this.stockDifference + this.materialExpense + this.staffExpense
    //   + this.amortization + this.otherExpense + this.valueBalancing + this.reservations + this.otherBusinessExpense; //TODO service expenses?
    this.profit = this.getByAop(179);
    this.incomeTax = this.getByAop(182);
    this.profitOrLossAfterTax = this.getByAop(177) - this.getByAop(178);
    this.profitAfterTax = this.profit - this.incomeTax;
    this.lossAfterTax = this.getByAop(181);
    this.netProfitLossPeriod = this.getByAop(183);
    this.profitPeriod =this.getByAop(179) - this.getByAop(182);
    this.lossPeriod = this.getByAop(185);
    this.businessProfitLoss = this.businessProfit
    this.ebitda = this.fullIncome - this.fullExpense
    this.salesExpense = this.getByAop(133);
    this.salesRevenue = this.getByAop(126) + this.getByAop(127);
      }
}
