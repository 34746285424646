import {Params} from '@angular/router';

export interface FilterValue {
  key: string;
  value: boolean | number | string | number[] | string[] | {
    [logicalOperator: string]: boolean | number | string | number[] | string[] //Operator, != ....
  },
  default?: boolean; //If true that is a default filter and it will never be removed from filters array
}

export class FilterData implements Params{
  keywords: string = '';
  filters: FilterValue[] = [];
  page: number = 1;
  limit: number = 10;
  studyType: number = 1;
  type?: number;
  sort: {
    param?: string,
    order?: string // ASC, DESC
  } = {};
  list?: boolean = false; //Boolean if backend will return a list json or normal standard json

  constructor(init?: any) {
    if (init) {
      if (init.keywords) this.keywords = init.keywords;
      if (init.filters) this.filters = init.filters;
      if (init.page) this.page = init.page;
      if (init.limit) this.limit = init.limit;
      if (init.sort) this.sort = init.sort;
      if (init.studyType) this.studyType = init.studyType
    }
  }

  toQuery(): string {
    let query: string = '?';
    if(this.limit) query += '&limit='+this.limit;
    if(this.page) query += '&page='+this.page;
    if(this.sort) {
      if(this.sort.param) query += '&sortBy='+this.sort.param;
      if(this.sort.order) query += '&order='+this.sort.order;
    }
    if(this.keywords) query += '&keywords='+this.keywords;
    if(this.studyType) query += '&studyType='+this.studyType
    return query;
  }

}
