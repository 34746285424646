import {Component, Input, OnDestroy} from '@angular/core';
import {Activity} from './activity';
import {LocalizationService} from '../../services/localization.service';
import {ToggleService} from '../../services/toggle.service';
import {GlobalService} from '../../services/global.service';
import {ThemeService} from '../../services/theme.service';
import {FileUploadService} from '../../services/file-upload.service';
import {ActivityService} from '../../services/activity.service';
import {FilterData} from '../../classes/filter-data';

export interface ModificationLog {
  field: string;
  oldVale: string;
  newValue: string;
}

@Component({
  selector: 'app-activity',
  templateUrl: './activity.component.html',
  styleUrls: ['./activity.component.scss']
})
export class ActivityComponent implements  OnDestroy {

  @Input() public filters: FilterData = null;
  @Input() hasFilter: boolean = false; //Show or hide filter

  constructor(public _toggle: ToggleService,
              public _locale: LocalizationService,
              public _global: GlobalService,
              public _fileUpload: FileUploadService,
              public _activity: ActivityService,
              public _theme: ThemeService) {
  }

  ngOnDestroy() {
    this.filters = null;
    this._activity.activities = [];
  }

  displayActivity(activity: Activity): string {

    let string = `${this._locale.activity[activity.action]}
     ${activity.subject_type ? this._locale.activity[activity.subject_type] : ''} 
     ${activity.subject_name} `;

    if (activity.parent)
      string += `${activity.parent_type ? this._locale.activity[activity.parent_type] : ''} ${activity.parent_name}`;
    return string;
  }
}
