import {Injectable} from '@angular/core';
import {Resolve} from '@angular/router';
import {ApiService} from '../../../../services/api.service';
import {GlobalService} from '../../../../services/global.service';
import {Observable, zip} from 'rxjs';
import {urlValues} from '../../../../configs/url.values';
import {FilterData} from '../../../../classes/filter-data';
import {Label} from '../../../../interfaces/common/label';
import {Category} from '../../../../interfaces/common/categoty';

@Injectable()
export class UserCommonsResolver implements Resolve<any> {

  constructor(private _api: ApiService,
              private _global: GlobalService) {
  }

  resolve() {
    if (this._global.commonData.user) return this._global.commonData.user;

    this._global.commonData.user = { //If class commons do not exists create a new empty object
      label: [],
      category: [],
      occupation: [],
      workgroup: []
    }; //Set class commons

    let initFilters = new FilterData();
    delete initFilters.page;
    delete initFilters.limit;

    return new Observable(obs => {
      zip(
        this._api.send(urlValues.user.commons.label.filter.method, urlValues.user.commons.label.filter.url, initFilters),
        this._api.send(urlValues.user.commons.category.filter.method, urlValues.user.commons.category.filter.url, initFilters),
        this._api.send(urlValues.user.commons.occupation.filter.method, urlValues.user.commons.occupation.filter.url, initFilters),
        this._api.send(urlValues.user.commons.workgroup.filter.method, urlValues.user.commons.workgroup.filter.url, initFilters),
      ).subscribe(([label, category, occupation, workgroup]) => {
        this._global.commonData.user.label = label['data'].records.map(record => new Label(record));
        this._global.commonData.user.category = category['data'].records.map(record => new Category(record));
        this._global.commonData.user.occupation = occupation['data'].records;
        this._global.commonData.user.workgroup = workgroup['data'].records;
        obs.next(true);
        obs.complete();
      }, err => {
        obs.error(err);
        obs.complete();
      });
    });
  }
}
