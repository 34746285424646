import {Component, OnInit, Output, EventEmitter, AfterViewInit} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {Input, ViewChild, forwardRef} from '@angular/core';
import {LocalizationService} from '../../services/localization.service';


@Component({
  selector: 'app-multiselect',
  templateUrl: './multiselect.component.html',
  styleUrls: ['./multiselect.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MultiselectComponent),
      multi: true
    }
  ]
})
export class MultiselectComponent implements OnInit, AfterViewInit, ControlValueAccessor {

  public showMultiselectDropdown: boolean = false;
  private innerValue: any[] = [];
  public isOpen: boolean = false;
  public searchValue: string = '';
  private clearSearch: any;
  public allSelected: any = []; //If multiselect all selected values
  @Input() multiple: boolean = false; //true for multiple, false for single select
  @Input() name: string = 'name'; //property of option objects to show
  @Input() valueName: string; //property of option objects to use as value
  @Input() placeholder: string = '- Select- '; //text to show when nothing is selected
  @Input() searchPlaceholder: string = 'Search...'; //text to show when nothing is selected
  @Input() noRecordsPlaceholder: string = 'No records found...';
  @Input() displayNumber: number = 3; //max selected options to display
  @Input() options: any[] = []; //array of option objects
  @Input() readonly: boolean = false; //readonly attribute to prevent input typing
  @Input() isDisabled: boolean = false; //prevents opening select dropdown
  @Input() optionsSelectedText: string = 'options selected';
  @Input() minSearchLength: number = 3; // Min characters needed to start searching
  @Input() showLoadMore: boolean = false; //Show lead more button
  @Input() enableSearch: boolean = true; //Enable search input
  @Input() showDisplayValues: boolean = true; //Show display values in multiselect
  @Input() clearSelected: boolean = false;
  @Input() selectAsButton: boolean = false;
  @Input() newEntity: boolean = false;
  @Input() selectedValue: any = null;

  @Output() onSelect: EventEmitter<any> = new EventEmitter();
  @Output() onSearch: EventEmitter<any> = new EventEmitter();
  @Output() onLoadMore: EventEmitter<boolean> = new EventEmitter();
  @Output() createNewEntity: EventEmitter<boolean> = new EventEmitter();

  constructor(
    public _locale: LocalizationService
  ) {
  }

  ngOnInit() {
    if (!this.options) this.options = [];
  }

  ngAfterViewInit() {
    if (this.selectedValue) { //Check if ngModel option is enabled
      if (this.multiple) {
        this.innerValue = this.options.filter(option => this.selectedValue.find(value => value[this.valueName] == option[this.valueName]));
      } else {
        this.innerValue = [this.options.find(option => option[this.valueName] == this.selectedValue[this.valueName])].filter(item => item);
      }
    }
  }

  isSelectedOption(option: any) {
    return this.innerValue.findIndex(item => item?.id === option?.id) != -1;
  }

  openSelect(disabled) {
    if (disabled) return;
    this.isOpen = true;
  }

  closeSelect() {
    this.isOpen = false;
  }

  loadMore() {
    this.onLoadMore.emit(true);
  }

  getDisplayValue() {
    if (!this.showDisplayValues) return this.placeholder; //Do nothing if sowDisplayValues is turned off
    let opts = this.innerValue.filter(option => option); //Filter all options that are not null
    if (opts.length) this.allSelected = opts.map(option => option[this.name]);

    if (!opts.length) return this.placeholder;
    else if (opts.length < this.displayNumber) return opts.map(option => option[this.name]).join(', ');
    else return opts.length + ' ' + this._locale.global.optionsSelected;
  }

  selectOption(value: any) {
    if (this.multiple) {
      this.innerValue = this.innerValue.filter(val => val !== null); //Remove all null values if there are any
      if (this.innerValue.findIndex(item => item.id === value.id) === -1 && value !== null) this.innerValue.push(value);
      else this.innerValue.splice(this.innerValue.findIndex(item => item.id === value.id), 1);
      this.valueName ? this.propagateChange(this.innerValue.map(value => value[this.valueName])) : this.propagateChange(this.innerValue);
      this.onSelect.emit(this.innerValue);
      this.searchValue = null; //Reset search value on selected
    } else {
      if (value == this.innerValue[0]) {
        this.innerValue = [];
        this.onSelect.emit(null);
        this.propagateChange(null);
      } else {
        if (!this.clearSelected) this.innerValue = [value];
        this.valueName ? this.propagateChange(value[this.valueName]) : this.propagateChange(value);
        this.onSelect.emit(value);
      }

      this.searchValue = null; //Reset search value on selected
      this.closeSelect(); //Close the select if not multiple
    }
  }


  writeValue(value: any) {
    setTimeout(() => {
      if (value !== undefined) {
        if (!this.valueName) return Array.isArray(value) ? this.innerValue = value : this.innerValue = [value];
        let values = Array.isArray(value) ? value : [value];
        this.innerValue = values.map(value => this.options.find(option => option[this.valueName] == value)).filter(value => value);
        if (!this.multiple && this.innerValue.length) {
          //If valueName is set and not empty string return only that value from object else return the whole object
          (this.valueName && this.valueName !== '') ? this.onSelect.emit(this.innerValue[0][this.valueName]) : this.onSelect.emit(this.innerValue[0]);
          this.readonly = !!this.valueName;
        }
      }
    });
  }

  propagateChange(_: any) {
  }

  registerOnChange(fn) {
    this.propagateChange = fn;
  }

  registerOnTouched() {
  }

  onSearchStart(event) {
    //Check if arrow keys where pressed
    if (event.keyCode == 37 || event.keyCode == 38 || event.keyCode == 39 || event.keyCode == 40) return;

    clearTimeout(this.clearSearch);
    if (this.searchValue.length > this.minSearchLength || this.searchValue == '') {
      this.clearSearch = setTimeout(() => this.onSearch.emit(this.searchValue), 500);
    }

  }

  preventCheck(e) {
    if (this.clearSelected) e.target.checked = false;
  }


  onShowMultiselectDropdown() {
    this.showMultiselectDropdown = true;
  }

  onHideMultiselectDropdown() {
    this.showMultiselectDropdown = false;
  }

}
