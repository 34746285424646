import { Pipe, PipeTransform } from '@angular/core';
import {DecimalPipe} from '@angular/common';
import {LocalizationService} from '../services/localization.service';

@Pipe({
  name: 'localizedNumber'
})
export class LocalizedNumberPipe implements PipeTransform {

  constructor(private decimalPipe: DecimalPipe, private _locale: LocalizationService) {}

  transform(value: any, hasDecimalPart: boolean = true, digitsInfo: string = '1.2-2', locale?: string): string {
    if (hasDecimalPart) {
      const formattedValue = value.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 });
      return formattedValue.replace(/,/g, '.');
    } else {
      // return the unformatted value
      return this.decimalPipe.transform(value, digitsInfo, locale ? locale : this._locale.getCurrentLocaleShort());;
    }
  }


}
