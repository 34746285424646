import {ImportedDocument} from './imported-document';
import {ImportValue} from '../import-value';

export class CashFlow extends ImportedDocument {

  customerIncome: number
  commissionsIncome: number
  insuranceIncome: number
  taxReturnIncome: number
  supplierExpense: number
  employeeExpense: number
  insuranceExpense: number
  otherIncomeExpense: number
  businessMoney: number
  interestExpenses: number
  paidIncomeTax: number
  operatingActivityCashFlow: number
  longTermAssetsIncome: number
  financeIncome: number
  interestIncome: number
  dividendsIncome: number
  loanSavingsIncome: number
  investmentActivityIncome: number
  investmentActivityIncomeTotal: number
  longTermAssetsExpense: number
  financialExpense: number
  loanSavingsExpense: number
  subsidiary: number
  investmentActivityExpense: number
  investmentActivityExpenseTotal: number
  investmentActivityCashFlow: number
  coreCapitalIncome: number
  financialEquityDebtIncome: number
  loanPrincipalIncome: number
  financialActivityIncome: number
  financialActivityIncomeTotal: number
  loanPrincipalExpense: number
  dividendsExpense: number
  financialLeasesExpense: number
  coreCapitalExpense: number
  financialActivityExpense: number
  financialActivityExpenseTotal: number
  financialActivityCashFlow: number
  exchangeRateDifference: number
  cashFlowDifference: number
  startCoreCash: number
  endCoreCash: number

  constructor(data: ImportValue[]) {
    super(data);
    this.customerIncome = this.getByAop(1);
    this.commissionsIncome = this.getByAop(2);
    this.insuranceIncome = this.getByAop(3);
    this.taxReturnIncome = this.getByAop(4);
    this.supplierExpense = this.getByAop(5);
    this.employeeExpense = this.getByAop(6);
    this.insuranceExpense = this.getByAop(7);
    this.otherIncomeExpense = this.getByAop(8);
    this.businessMoney = this.getByAop(9);
    this.interestExpenses = this.getByAop(10);
    this.paidIncomeTax = this.getByAop(11);
    this.operatingActivityCashFlow = this.getByAop(12);
    this.longTermAssetsIncome = this.getByAop(13);
    this.financeIncome = this.getByAop(14);
    this.interestIncome = this.getByAop(15);
    this.dividendsIncome = this.getByAop(16);
    this.loanSavingsIncome = this.getByAop(17);
    this.investmentActivityIncome = this.getByAop(18);
    this.investmentActivityIncomeTotal = this.getByAop(19);
    this.longTermAssetsExpense = this.getByAop(20);
    this.financialExpense = this.getByAop(21);
    this.loanSavingsExpense = this.getByAop(22);
    this.subsidiary = this.getByAop(23);
    this.investmentActivityExpense = this.getByAop(24);
    this.investmentActivityExpenseTotal = this.getByAop(25);
    this.investmentActivityCashFlow = this.getByAop(26);
    this.coreCapitalIncome = this.getByAop(27);
    this.financialEquityDebtIncome = this.getByAop(28);
    this.loanPrincipalIncome = this.getByAop(29);
    this.financialActivityIncome = this.getByAop(30);
    this.financialActivityIncomeTotal = this.getByAop(31);
    this.loanPrincipalExpense = this.getByAop(32);
    this.dividendsExpense = this.getByAop(33);
    this.financialLeasesExpense = this.getByAop(34);
    this.coreCapitalExpense = this.getByAop(35);
    this.financialActivityExpense = this.getByAop(36);
    this.financialActivityExpenseTotal = this.getByAop(37);
    this.financialActivityCashFlow = this.getByAop(38);
    this.exchangeRateDifference = this.getByAop(39);
    this.cashFlowDifference = this.getByAop(40);
    this.startCoreCash = this.getByAop(41);
    this.endCoreCash = this.getByAop(42);
  }
}
