import {User} from '../modules/layout/user/classes/user';

export class MentorRequest {

  id: number;
  requestingUser?: User;
  requestingUserId: number;
  receivingUser?: User;
  receivingUserId: number;
  createdAt: Date;

  constructor(data: Object) {
    this.id = data['id'];
    if(typeof data['requestingUser'] === 'object') {
      this.requestingUser = new User(data['requestingUser']);
      this.requestingUserId = this.requestingUser.id;
    }
    else {
      this.requestingUserId = data['requestingUser'];
    }
    if(typeof data['receivingUser'] === 'object') {
      this.receivingUser = new User(data['receivingUser']);
      this.receivingUserId = this.receivingUser.id;
    }
    else {
      this.receivingUserId = data['receivingUser'];
    }
    this.createdAt = data['createdAt'];
  }

}
