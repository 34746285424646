import {Injectable, EventEmitter} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {Params, Router} from '@angular/router';
import {urlValues} from '../configs/url.values';
import * as moment from 'moment';
import * as _ from 'lodash';
import {LocalizationService} from './localization.service';
import {Message} from 'primeng/api';
import {FormGroup} from '@angular/forms';
import {AppMessageSettings} from '../classes/app-message-settings';
import {CommonData} from '../interfaces/common/common-data';
import {Settings} from '../interfaces/settings/settings';
import {environment} from '../../environments/environment';
import {ApiService} from './api.service';
import {ThemeService} from './theme.service';
import * as numeral from 'numeral';
import {StorageService} from './storage.service';

@Injectable()
export class GlobalService {

  //<editor-fold desc="App messages variables">
  public appMessages: Message[] = [];
  public appMessageSettings: BehaviorSubject<AppMessageSettings> = new BehaviorSubject<AppMessageSettings>(null);
  //</editor-fold>

  public urlValues: any = urlValues;

  public commonData: CommonData = null; //common.resolver for commons across app, and each module has its own common resolver
  public settings: Settings = null;
  public documentCommons = {}; // documentCommons.resolver for common data on document module (only used on documents for now)

  //Loading -> show / hide spinner div
  public loading: BehaviorSubject<boolean> = new BehaviorSubject(false);

  //405 error event
  public saleError: EventEmitter<any> = new EventEmitter<any>();

  //<editor-fold desc="Date, time settings">
  public initTime: object = moment().set({hours: 0, minutes: 0}).toDate();
  public today: object = new Date();
  //</editor-fold>

  public env: object;

  //<editor-fold desc="Flatpickr configs">
  public datePicker: object = {
    altInput: true,
    altFormat: 'd.m.Y',
    dateFormat: 'Y-m-d',
    allowInput: false, //Allows user to enter date manually
    locale: {
      firstDayOfWeek: 1
    }
  };

  public dateAndTimePicker: object = {
    enableTime: true,
    time_24hr: true,
    dateFormat: 'Y-m-d H:i',
    altFormat: 'd.m.Y H:i',
    altInput: true,
    locale: {
      firstDayOfWeek: 1
    }
  };

  public timePicker: object = {
    enableTime: true,
    noCalendar: true,
    time_24hr: true,
    dateFormat: 'H:i',
    defaultHour: '00',
    defaultMinute: '00'
  };

  public rangePicker: object = {
    mode: 'range',
    altFormat: 'd.m.Y',
    dateFormat: 'd.m.Y',
  };

  //</editor-fold>

  constructor(
    public _locale: LocalizationService,
    public _router: Router,
    private _api: ApiService,
    private _theme: ThemeService,
    private _storage: StorageService) {

    this.appMessages = []; //Set all errorMessages to empty
    this.createMixins(); //Create custom lodash functions
    this.env = environment;
  }

  //<editor-fold desc="Date time manipulations">
  //Converts timestamp to string format DD.MM.YYYY
  timestampToDate(timestamp: number) {
    if (!timestamp) return '-';
    let date = moment(timestamp);
    let dateFormat = _.find(this.settings.global, {key: 'dateFormat'});
    return dateFormat ? date.format(dateFormat.value.value.toUpperCase()) : date;
  }

  //Accepts JS date object and returns timestamp
  dateToTimestamp(date: Date, format?: string) {
    return moment(date, format || 'DD.MM.YYYY HH:mm').valueOf();
  }

  formatDateObject(date: Date, format?: string): string {
    return moment(date).format(format || 'DD.MM.YYYY');
  };

  getTimeFromTimestamp(timestamp: number): string {
    let timeFormat = _.find(this.settings.global, {key: 'timeFormat'});
    return timestamp ? moment(timestamp).format(timeFormat ? timeFormat.value.value : 'HH:mm') : '-';
  }

  timeToString(seconds: number): string {
    return this.zeroPad(Math.floor(seconds / 3600)) + ':' + this.zeroPad(Math.floor(seconds / 60) % 60) + ':' + this.zeroPad(seconds % 60);
  }

  zeroPad(number: number): string {
    let numberString = number.toString();
    return numberString.length > 1 ? numberString : '0' + numberString;
  }

  //</editor-fold>

  //<editor-fold desc="String manipulations">
  capitalizeFirstLetter(string: string): string {
    if (!string) return;
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  toLowerCase(word: string): string {
    return word.toLowerCase();
  }

  removeLastChar(str: string): string {
    return str.substring(0, str.length - 1);
  }

  removeHtmlFromString(text: string): string {
    let tmp = document.createElement('DIV'); //Create tmp div
    tmp.innerHTML = text; //Set his inner html
    return tmp.textContent || tmp.innerText || '';
  }

  slugify(text: string, replaceChar?: string): string {
    return text.toString().toLowerCase()
      .replace(/\s+/g, replaceChar ? replaceChar : '-') // Replace spaces with -
      .replace(/[^\w\-]+/g, replaceChar ? replaceChar : '-') // Remove all non-word chars
      .replace(/\-\-+/g, replaceChar ? replaceChar : '-')  // Replace multiple - with single -
      .replace(/^-+/, '') // Trim - from start of text
      .replace(/-+$/, ''); // Trim - from end of text
  }

  replaceAt(string: string, index: number, replacement: string): string {
    return string.substr(0, index) + replacement + string.substr(index + replacement.length);
  }

  //</editor-fold>

  //<editor-fold desc="App messages">
  //Add messages to the application messages and display them on page
  //Available types : success, info, warn, error
  pushAppMessage(type: string, headerText: string, bodyText: string, settings?: AppMessageSettings): void {
    this.appMessages = [];
    this.appMessageSettings.next((settings) ? settings : new AppMessageSettings());
    this.appMessages.push({severity: type, summary: headerText, detail: bodyText});
  }

  updateAppMessage(index: number, data: object) {
    if (index >= this.appMessages.length) return;
    for (let key in data) {
      this.appMessages[index][key] = data[key];
    }
  }

  clearAppMessages() {
    this.appMessages = [];
  }

  onCloseAppMessage() {
    this.appMessageSettings.next(null);
    this.appMessages = [];
  }

  //</editor-fold>

  //<editor-fold desc="Currency & Number Formats">
  formatCurrency(number: number): string {
    let format = _.find(this.settings['global'], {key: 'currencyFormat'});
    return numeral(number).format(format.value.value);
  }

  formatNumber(number: number): string {
    let currency = _.find(this.settings['global'], {key: 'defaultCurrency'});

    let customNumeral = {
      delimiters: {
        thousands: '.',
        decimal: ','
      },
      abbreviations: {
        thousand: 'k',
        million: 'm',
        billion: 'b',
        trillion: 't'
      },
      ordinal: function (num) {
        return '';
      },
      currency: {
        symbol: currency.value.symbol
      }
    };
    numeral.register('locale', 'custom', customNumeral);
    numeral.locale('custom');


    let format = _.find(this.settings['global'], {key: 'numberFormat'});
    return numeral(number).format(format.value.value);
  }

  //</editor-fold>

  //<editor-fold desc="Form Data manipulations">
  getChangedValues(formValues, selectedValue) { //formValues = new values, selectedValue = old values to compare with
    let changedKeys = _.keys(formValues).filter(key => !_.isEqual(selectedValue[key], formValues[key]));

    return _.zipObject(changedKeys, changedKeys.map(field => {
      return (_.isObject(formValues[field]) && !_.isArray(formValues[field])) ? this.getChangedValues(formValues[field], selectedValue[field]) : formValues[field];
    }));
  }

  //Accepts fromGroup, iterates trough it and marks controls as touched
  //Depends on error-msg component which displays errors
  checkFormErrors(formGroup: FormGroup) {
    _.each(formGroup.controls, control => {
      control.markAsTouched();

      if (control.controls) _.each(control.controls, c => {
        c.markAsTouched();
        this.checkFormErrors(c);
      });
    });
  }

  //</editor-fold>

  //<editor-fold desc="Random functions">
  getRandomColor(id?: number): string {
    let colors = ['#BF5783', '#1DBFBF', '#6F80C7', '#EFA02A', '#ED6C76'];

    if (id) return colors[id % colors.length]; //If id then bind color to that id
    else return _.sample(colors); //Return random color
  }

  updateCommonData(module: string, key: string, value: object, method: 'create' | 'update' | 'delete'): void {
    if (!this.commonData[module][key]) this.commonData[module][key] = []; //If it does not exists create anew array

    switch (method) {
      case 'create': //Pushes a new label that has been created to labels array
        this.commonData[module][key].push(value);
        break;
      case 'update': //Splices the old label with the new one
        let index = _.findIndex(this.commonData[module][key], {id: value['id']});
        this.commonData[module][key].splice(index, 1, value);
        break;
      case 'delete': //Removes the label from labels array
        _.remove(this.commonData[module][key], ['id', value['id']]);
        break;
      default:
        return;
    }
  }

  setGridPercentages(headers: object): string { //Returns string of grid widths (5% 16% 12% ....)
    return _.map(headers, header => header.width).join(' ');
  }

  isEmptyObject(obj: object): boolean {
    if (!obj) return;
    return (Object.keys(obj).length === 0 && obj.constructor === Object);
  }

  isEmptyArray(array: any): boolean {
    return (Object.keys(array).length === 0 && array.constructor === Array);
  }

  isAllowedKeyCode(event): boolean {
    if ((event.keyCode == 37 || event.keyCode == 38 || event.keyCode == 39 || event.keyCode == 40)) return false;
    else return true;
  }

  removeNullsFromForm(form: any): any {
    return Object.entries(form).reduce((a,[k,v]) => (v === null ? a : (a[k]=v, a)), {})
  }

  //</editor-fold>

  getUserSettings(): Observable<boolean> {
    return new Observable<boolean>(obs => {
      this._api.send(urlValues.settings.user.method, urlValues.settings.user.url).subscribe(res => {
        this.settings = res['data'];
        _.each(this.settings.lists, (list) => list.values = _.orderBy(list.values, ['orderNo'])); //Sort list values by orderNumber
        //Set default language in localization service
        if(!this._storage.get('language-token'))
        {let lang = _.find(res['data'].global, ['key', 'language']);
         this._locale.set(lang ? lang.value.code : this._storage.get('language-token'));}
        //Set default color scheme for app
        let scheme = _.find(res['data'].global, ['key', 'colorScheme']);
        this._theme.colorScheme = scheme ? scheme.value : 'defaultTheme';
        obs.next(true);
        obs.complete();
      });
    });
  }



  createMixins(): void {//Create a bunch of custom lodash functions
    _.mixin({
      'mapFilterFields': (collection) => {
        return _.map(collection, item => {
          return {name: item.name, sendValue: item.id};
        });
      },
      'mapEmployeesSelect': (users, type) => {
        return _.map(_.filter(users, user => user.type == type), employee => {
          return {
            id: employee.id,
            name: `${employee.details.firstName} ${employee.details.lastName}`,
            initials: `${employee.details.firstName[0]}${employee.details.lastName[0]}`,
            profileImage: employee.details.profileImage ? employee.details.profileImage.path : null,
            iconColor: this.getRandomColor(employee.id)
          };
        });
      },
      'mapUsersSelect': (users, forFilter?: boolean) => {
        return _.map(users, user => {
          let json = {
            id: user.id,
            name: `${user.details.firstName} ${user.details.lastName}`,
            initials: `${user.details.firstName[0]}${user.details.lastName[0]}`,
            profileImage: user.details.profileImage ? user.details.profileImage.thumbnail : null,
            iconColor: this.getRandomColor(user.id),
            type: user.type
          };
          forFilter ? json['sendValue'] = user.id : json['id'] = user.id;
          return json;
        });
      },
      'mapPriceListItems': (items: any) => {
        return _.map(items, item => {
          return {
            id: item.id,
            itemName: item.name,
            netPrice: item.retailPrice,
            totalPrice: item.retailPrice,
            unit: item.unit,
            vatClass: item.taxClass,
            currency: item.currency ? item.currency : null
          };
        });
      },
      'mapPriceListItem': (item: any) => {
        return {
          id: item.id,
          itemName: item.name,
          netPrice: item.retailPrice,
          totalPrice: item.retailPrice,
          unit: item.unit,
          vatClass: item.taxClass,
          currency: item.currency ? item.currency : null
        };
      },
      'mapSingleUsersSelect': (user) => {
        if (!user) return;
        return {
          id: user.id,
          name: `${user.details.firstName} ${user.details.lastName}`,
          initials: `${user.details.firstName[0]}${user.details.lastName[0]}`,
          profileImage: user.details.profileImage ? user.details.profileImage.path : null,
          iconColor: this.getRandomColor(user.id),
          type: user.type
        };
      },
      'mapSingleUsersListItemSelect': (user) => {
        if (!user) return;
        return {
          id: user.id,
          name: `${user.firstName} ${user.lastName}`,
          initials: `${user.firstName[0]}${user.lastName[0]}`,
          profileImage: user.profileImage ? user.profileImage.path : null,
          iconColor: this.getRandomColor(user.id),
          type: user.type
        };
      },
      'mapUserForActivityLogFilter': (users) => {
        if (!users) return;
        return _.map(users, user => {
          return {
            sendValue: user.id,
            name: `${user.firstName} ${user.lastName}`
          };
        });
      },
      'mapCompanySelect': (companies) => {
        return _.map(companies, company => {
          return {
            id: company.id,
            name: company.name,
          };
        });
      },
      'mapSingleCompanySelect': (company) => {
        if (!company) return;
        return {
          id: company.id,
          name: company.name,
        };
      },
      'mapCalendarEvent': (objects: object[], type: string) => {
        return _.map(objects, object => {
          let data: any = {
            title: object.uniqueNumber ? `${object.uniqueNumber}: ${object.name}` : object.title,
            eventTitle: object.uniqueNumber ? `${object.uniqueNumber}: ${object.name}` : object.title,
            start: moment(object.startDate || object.start || object.startEvent).toDate(),
            end: moment(object.endDate || object.start || object.endEvent).toDate(),
            // id: object.id,
            eventId: object.id,
            type: type,
            recurring: type === 'customEvent' ? object.recurring : false,
            className: `calendar-label-${type}`
          };
          if (type === 'customEvent') {
            let user: any = JSON.parse(localStorage.getItem('user'));
            data.startEvent = moment(object.startEvent).toDate();
            data.endEvent = moment(object.endEvent).toDate();
            data.startRecurring = object.startRecurring ? moment(object.startRecurring).toDate() : null;
            data.endRecurring = object.endRecurring ? moment(object.endRecurring).toDate() : null;
            data.recurringType = object.recurringType;
            data.private = object.private;
            data.note = object.note;
            data.branch = user.branch;
            data.department = user.department ? user.department : null;
          }
          return data;
        });
      },
      'move': (array: any[], from: number, to: number) => {
        array.splice(to, 0, array.splice(from, 1)[0]);
        return array;
      }
    });
  }
}
