import {Injectable} from '@angular/core';
import {CanDeactivate} from '@angular/router';
import  {Observable} from 'rxjs';
import {Location, PlatformLocation} from '@angular/common';

export interface CanComponentDeactivate {
  canDeactivate: () => Observable<boolean> | Promise<boolean> | boolean;
}

@Injectable({
  providedIn: 'root'
})
export class CanLeaveGuard implements CanDeactivate<CanComponentDeactivate> {

  constructor(private _loc: PlatformLocation) {}

  canDeactivate(component: CanComponentDeactivate) {

    this._loc.onPopState(() => {
      return true;
    });

    return true;
  }

}
