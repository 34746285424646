export const UserTypeTranslations = {
  'eng': {
    employee: 'Employee',
    customer: 'Customer',
    student: 'Student',
    externalAssociate: 'External associate'
  },
  'hrv': {
    employee: 'Zaposlenik',
    customer: 'Kupac',
    student: 'Student',
    externalAssociate: 'Vanjski suradnik'
  }
};
