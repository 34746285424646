import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';

const appRoutes: Routes = [
  {path: '', loadChildren: () => import('./modules/layout/layout.module').then(m => m.LayoutModule)},
  {path: 'auth', loadChildren: () => import('./components/auth/auth.module').then(m => m.AuthModule)},
  {path: 'not-found', loadChildren: () => import('./components/errors/not-found/not-found.module').then(m => m.NotFoundModule)},
  {path: 'forbidden', loadChildren: () => import('./components/errors/forbidden/forbidden.module').then(m => m.ForbiddenModule)},
  {
    path: 'internal-server-error',
    loadChildren: () => import('./components/errors/internal-server-error/internal-server-error.module').then(m => m.InternalServerErrorModule)
  },
  {
    path: 'service-unavailable',
    loadChildren: () => import('./components/errors/service-unavailable/service-unavailable.module').then(m => m.ServiceUnavailableModule)
  },
  {path: '**', redirectTo: 'not-found'}
];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes, { useHash: true, onSameUrlNavigation: 'reload', relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
  providers: []
})
export class AppRoutingModule {
}
