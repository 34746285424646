export const UserTranslations = {
  'eng': {
    users:' Users',
    mentorsUsers: 'Mentors users',
    mentorsRequests: 'Requests to mentor',
    title: 'Title',
    iban: 'IBAN',
    newuser: 'New user',
    edituser: 'Edit user',
    add: 'Add',
    remove: 'Remove',
    create: 'Create',
    view: 'View',
    edit: 'Edit',
    archive: 'Archive',
    restore: 'Restore',
    savechanges: 'Save changes',
    firstname: 'First name',
    lastname: 'Last name',
    username: 'Username',
    password: 'Password',
    type: 'Type',
    userType: 'Type',
    occupation: 'Occupation',
    permission: 'Permission',
    company: 'Company',
    branch: 'Branch',
    companybranch: 'Company branch',
    email: 'Email',
    phonenumber: 'Phone number',
    mobile: 'Mobile',
    oib: 'Personal ID Number',
    birthdate: 'Birth date',
    placeofbirth: 'Place of birth',
    notes: 'Notes',
    addresses: 'Addresses',
    address: 'Address',
    streetaddress: 'Street address',
    postalcode: 'Postal code',
    city: 'City',
    stateprovince: 'State/Province',
    country: 'Country',
    contacts: 'Contacts',
    value: 'Value',
    hasaccess: 'Has access',
    cancel: 'Cancel',
    search: 'Search...',
    selectpermission: 'Select permission',
    select: '- Select -',
    label: 'Label',
    category: 'Category',
    manage: 'Manage',
    workGroup: 'Workgroup',
    tags: 'Tags',
    gender: 'Gender',
    salutation: 'Salutation',
    vatNumber: 'Vat number',
    weak: 'Weak',
    medium: 'Medium',
    strong: 'Strong',
    enterPassword: 'Enter password',
    male: 'Male',
    female: 'Female',
    success: 'Success',
    usercreated: 'User was created',
    userupdated: 'User was updated',
    userarchived: 'User was archived',
    userrestored: 'User was restored',
    useranonymised: 'User was anonymised',
    useridentified: 'User was anonymised',
    sendmail: 'Send email',
    documents: 'Documents',
    details: 'Details',
    activity: 'Activities',
    projects: 'Projects',
    courses: 'Courses',
    back: 'Back',
    warning: 'Warning',
    selecttype: 'Please select type',
    dateofbirth: 'Date of birth',
    individuaalinformation: 'Individual information',
    occupationinformation: 'Occupation information',
    companyname: 'Company name',
    companyaddress: 'Company address',
    profileImageRemoved: 'Profile image removed',
    anonymise:'Anonymise',
    identify:'Identify',
    isAnonimysed: 'Anonymize',
    firstName: 'First name',
    lastName: 'Last name',
    mentoring: 'Mentoring',
    mentor: 'Mentor',
    mentors: 'Mentors',
    chooseMentor: 'Choose a mentor',
    changeMentor: 'Change mentor',
    mentorRequestSent: 'Mentor request sent',
    promotionRequestSent: 'Request for mentor role sent',
    becomeMentor: 'Become a mentor',
    promotionRequestMsg: 'promotionRequestMsg',
    promotionRequestAccepted: 'Mentor role request accepted',
    promotionRequestRejected: 'Mentor role request rejected',
    setAsMentor: 'Set as mentor',
    mentorRequestAccepted: 'Mentor request accepted',
    mentorRequestRejected: 'Mentor request rejected',
    userRemovedFromMentoring: 'User removed from mentoring',
    activate: 'Activate',
    deactivate: 'Deactivate',
    registerSuccess: 'Registered',
    passwordReset: 'The email was successfully sent. If you did not receive email please check spam folder or contact administrator.',
    unactivated: 'Unactivated',
    activated: 'Activated',
    //teraTypes
    basic: 'User',
    tutor: 'Mentor',
    admin: 'Admin',
    language: 'Language',
    chooseLanguage: 'Choose language'
  },
  'hrv': {
    users: 'Korisnici',
    mentorsUsers: 'Mentorovi korisnici',
    mentorsRequests: 'Zahtjevi za mentoriranje',
    newuser: 'Novi korisnik',
    edituser: 'Uredi korisnika',
    iban: 'IBAN',
    add: 'Dodaj',
    remove: 'Ukloni',
    create: 'Kreiraj',
    savechanges: 'Spremi promjene',
    firstname: 'Ime',
    lastname: 'Prezime',
    username: 'Korisničko ime',
    password: 'Lozinka',
    type: 'Tip',
    userType: 'Tip',
    occupation: 'Zanimanje',
    permission: 'Ovlaštenja',
    branch: 'Poslovnica',
    company: 'Tvrtka',
    companybranch: 'Poslovnica',
    email: 'Email',
    phonenumber: 'Telefon',
    mobile: 'Mobitel',
    oib: 'OIB',
    birthdate: 'Datum rođenja',
    placeofbirth: 'Mjesto rođenja',
    notes: 'Napomena',
    addresses: 'Adrese',
    address: 'Adresa',
    streetaddress: 'Ulica',
    postalcode: 'Poštanski broj',
    city: 'Grad',
    stateprovince: 'Općina/Naselje',
    country: 'Država',
    contacts: 'Kontakti',
    value: 'Vrijednost',
    hasaccess: 'Ima pristup',
    cancel: 'Odustani',
    search: 'Pretraži...',
    selectpermission: 'Odaberi ovlaštenje',
    select: '- Odaberi -',
    label: 'Oznaka',
    category: 'Kategorija',
    manage: 'Upravljaj',
    workGroup: 'Radna grupa',
    tags: 'Tagovi',
    gender: 'Spol',
    salutation: 'Titula',
    vatNumber: 'Porezni broj',
    weak: 'Slabo',
    medium: 'Srednje',
    strong: 'Jako',
    enterPassword: 'Unesite lozinku',
    male: 'Muško',
    female: 'Žensko',
    view: 'Prikaži',
    edit: 'Uredi',
    archive: 'Arhiviraj',
    restore: 'Vrati',
    success: 'Uspjeh',
    usercreated: 'Korisnik je kreiran',
    userupdated: 'Korisnik je uređen',
    userarchived: 'Korisnik je arhiviran',
    userrestored: 'korisnik je vraćen',
    sendmail: 'Pošalji mail',
    documents: 'Dokumenti',
    details: 'Detalji',
    activity: 'Aktivnosti',
    projects: 'Projekti',
    courses: 'Tečajevi',
    back: 'Natrag',
    title: 'Titula',
    warning: 'Upozorenje',
    selecttype: 'Odaberite tip',
    dateofbirth: 'Datum rođenja',
    individuaalinformation: 'Osobni podatci',
    occupationinformation: 'Podatci o firmi',
    companyname: 'Naziv tvrtke',
    companyaddress: 'Adresa tvrtke',
    profileImageRemoved: 'Profilna slika je uklonjena',
    anonymise:'Anonimiziraj',
    identify:'Identificiraj',
    isAnonimysed: 'Anonizimiran',
    firstName: 'Ime',
    lastName: 'Prezime',
    mentoring: 'Mentoriranje',
    mentor: 'Mentor',
    mentors: 'Mentori',
    chooseMentor: 'Odaberi mentora',
    changeMentor: 'Promijeni mentora',
    mentorRequestSent: 'Zahtjev za mentorom poslan',
    promotionRequestSent: 'Zahtjev za rolom mentora poslan',
    becomeMentor: 'Postani mentor',
    promotionRequestMsg: 'promotionRequestMsg',
    promotionRequestAccepted: 'Zahtjev za rolom mentora prihvaćen',
    promotionRequestRejected: 'Zahtjev za rolom mentora odbijen',
    setAsMentor: 'Postavi kao mentora',
    mentorRequestAccepted: 'Zahtjev za mentoriranje prihvaćen',
    mentorRequestRejected: 'Zahtjev za mentoriranje odbijen',
    userRemovedFromMentoring: 'Korisnik ukolnjen sa mentoriranja',
    activate: 'Aktiviraj',
    deactivate: 'Deaktiviraj',
    registerSuccess: 'Registriran',
    passwordReset: 'Email je uspješno poslan.Ako niste dobili email molimo vas provjerite spam folder ili kontaktirajte administratora.',
    unactivated: 'Neaktivaran',
    activated: 'Aktiviran',
    //teraTypes
    basic: 'Korisnik',
    tutor: 'Mentor',
    admin: 'Admin',
    language: 'Jezik',
    chooseLanguage: 'Odaberi jezik'
  }
};
