import {environment} from '../../../../environments/environment';

export const StudyRoutes = {
  filter: {
    url: `${environment.baseUrl}/api/${environment.version}/tera/study/filter`,
    method: 'Post'
  },
  create: {
    url: `${environment.baseUrl}/api/${environment.version}/tera/study`,
    method: 'Post'
  },
  get: {
    url: (id: number) => `${environment.baseUrl}/api/${environment.version}/tera/study/${id}`,
    method: 'Get'
  },
  update: {
    url: (id: number) => `${environment.baseUrl}/api/${environment.version}/tera/study/${id}`,
    method: 'Patch'
  },
  delete: {
    url: (id: number) => `${environment.baseUrl}/api/${environment.version}/tera/study/${id}`,
    method: 'Delete'
  },
  userStudies: {
    url: (userId: number) => `${environment.baseUrl}/api/${environment.version}/tera/user/${userId}/study`,
    method: 'Get'
  },
  mentorsUsersStudies: {
    url: (mentorId: number) => `${environment.baseUrl}/api/${environment.version}/tera/mentor/${mentorId}/study`,
    method: 'Get'
  },
  year: {
    getAll: {
      url: (studyId: number) => `${environment.baseUrl}/api/${environment.version}/tera/study/${studyId}/year`,
      method: 'Post'
    },
  },
  product: { //with yearProducts
    getAll: {
      url: (studyId: number) => `${environment.baseUrl}/api/${environment.version}/tera/study/${studyId}/product`,
      method: 'Get'
    },
    create: {
      url: (studyId: number) => `${environment.baseUrl}/api/${environment.version}/tera/study/${studyId}/product`,
      method: 'Post'
    },
    delete: {
      url: (id: number) => `${environment.baseUrl}/api/${environment.version}/tera/product/${id}`,
      method: 'Delete'
    },
    updateMany: {
      url: (id: number) => `${environment.baseUrl}/api/${environment.version}/tera/study/${id}/product`,
      method: 'Patch'
    }
  },
  material: { //with yearMaterials
    getAll: {
      url: (studyId: number) => `${environment.baseUrl}/api/${environment.version}/tera/study/${studyId}/material`,
      method: 'Get'
    },
    create: {
      url: (studyId: number) => `${environment.baseUrl}/api/${environment.version}/tera/study/${studyId}/material`,
      method: 'Post'
    },
    delete: {
      url: (id: number) => `${environment.baseUrl}/api/${environment.version}/tera/material/${id}`,
      method: 'Delete'
    },
    updateMany: {
      url: (id: number) => `${environment.baseUrl}/api/${environment.version}/tera/study/${id}/material`,
      method: 'Patch'
    }
  },
  marketingActivity: { //with yearMarketingActivities
    getAll: {
      url: (studyId: number) => `${environment.baseUrl}/api/${environment.version}/tera/study/${studyId}/marketing_activity`,
      method: 'Get'
    },
    create: {
      url: (studyId: number) => `${environment.baseUrl}/api/${environment.version}/tera/study/${studyId}/marketing_activity`,
      method: 'Post'
    },
    delete: {
      url: (id: number) => `${environment.baseUrl}/api/${environment.version}/tera/marketing_activity/${id}`,
      method: 'Delete'
    },
    //Also updates yearMarketingActivities
    updateMany: {
      url: (studyId: number) => `${environment.baseUrl}/api/${environment.version}/tera/study/${studyId}/marketing_activity`,
      method: 'Patch'
    }
  },
  estimateValue: {
    getAll: {
      url: (studyId: number) => `${environment.baseUrl}/api/${environment.version}/tera/study/${studyId}/estimate_value`,
      method: 'Get'
    },
    create: {
      url: (studyId: number) => `${environment.baseUrl}/api/${environment.version}/tera/study/${studyId}/estimate_value`,
      method: 'Post'
    },
    delete: {
      url: (id: number) => `${environment.baseUrl}/api/${environment.version}/tera/estimate_value/${id}`,
      method: 'Delete'
    },
    updateMany: {
      url: (studyId: number) => `${environment.baseUrl}/api/${environment.version}/tera/study/${studyId}/estimate_value`,
      method: 'Patch'
    }
  },
  sector: {
    getAll: {
      url: (studyId: number) => `${environment.baseUrl}/api/${environment.version}/tera/study/${studyId}/sector`,
      method: 'Get'
    },
    create: {
      url: (studyId: number) => `${environment.baseUrl}/api/${environment.version}/tera/study/${studyId}/sector`,
      method: 'Post'
    },
    delete: {
      url: (id: number) => `${environment.baseUrl}/api/${environment.version}/tera/sector/${id}`,
      method: 'Delete'
    },
    //Also updates yearSectors
    updateMany: {
      url: (studyId: number) => `${environment.baseUrl}/api/${environment.version}/tera/study/${studyId}/sector`,
      method: 'Patch'
    }
  },
  qualification: {
    getAll: {
      url: `${environment.baseUrl}/api/${environment.version}/tera/qualification`,
      method: 'Get'
    }
  },
  yearQualification: {
    updateMany: {
      url: (studyId: number) => `${environment.baseUrl}/api/${environment.version}/tera/study/${studyId}/yearQualification`,
      method: 'Patch'
    }
  },
  expense: {
    getAll: {
      url: (studyId: number) => `${environment.baseUrl}/api/${environment.version}/tera/study/${studyId}/expense`,
      method: 'Get'
    },
    create: {
      url: (studyId: number) => `${environment.baseUrl}/api/${environment.version}/tera/study/${studyId}/expense`,
      method: 'Post'
    },
    delete: {
      url: (id: number) => `${environment.baseUrl}/api/${environment.version}/tera/expense/${id}`,
      method: 'Delete'
    },
    //Also updates yearExpenses
    updateMany: {
      url: (studyId: number) => `${environment.baseUrl}/api/${environment.version}/tera/study/${studyId}/expense`,
      method: 'Patch'
    }
  },
  amortizationItem: {
    getAll: {
      url: (studyId: number) => `${environment.baseUrl}/api/${environment.version}/tera/study/${studyId}/amortization_item`,
      method: 'Get'
    },
    create: {
      url: (studyId: number) => `${environment.baseUrl}/api/${environment.version}/tera/study/${studyId}/amortization_item`,
      method: 'Post'
    },
    delete: {
      url: (id: number) => `${environment.baseUrl}/api/${environment.version}/tera/amortization_item/${id}`,
      method: 'Delete'
    },
    //Also updates yearMarketingActivities
    updateMany: {
      url: (studyId: number) => `${environment.baseUrl}/api/${environment.version}/tera/study/${studyId}/amortization_item`,
      method: 'Patch'
    }
  },
  totalIncome: {
    update: {
      url: (studyId: number) => `${environment.baseUrl}/api/${environment.version}/tera/study/${studyId}/total_income`,
      method: 'Patch'
    },
  },
  cashFlow: {
    update: {
      url: (studyId: number) => `${environment.baseUrl}/api/${environment.version}/tera/study/${studyId}/cash_flow`,
      method: 'Patch'
    },
  },
  profitAndLoss: {
    update: {
      url: (studyId: number) => `${environment.baseUrl}/api/${environment.version}/tera/study/${studyId}/profit_and_loss`,
      method: 'Patch'
    },
  },
  totalExpense: {
    update: {
      url: (studyId: number) => `${environment.baseUrl}/api/${environment.version}/tera/study/${studyId}/total_expense`,
      method: 'Patch'
    },
  },
  flow: {
    update: {
      url: (studyId: number) => `${environment.baseUrl}/api/${environment.version}/tera/study/${studyId}/flow`,
      method: 'Patch'
    },
  },
  balance: {
    update: {
      url: (studyId: number) => `${environment.baseUrl}/api/${environment.version}/tera/study/${studyId}/balance`,
      method: 'Patch'
    },
  },
  yearEstimateValueExpenses: {
    getAll: {
      url: (studyId: number) => `${environment.baseUrl}/api/${environment.version}/tera/study/${studyId}/year_estimate_value_expense`,
      method: 'Get'
    },
    updateMany: {
      url: (studyId: number) => `${environment.baseUrl}/api/${environment.version}/tera/study/${studyId}/year_estimate_value_expense`,
      method: 'Patch'
    }
  },
  yearEstimateValueCovers: {
    getAll: {
      url: (studyId: number) => `${environment.baseUrl}/api/${environment.version}/tera/study/${studyId}/year_estimate_value_cover`,
      method: 'Get'
    },
    updateMany: {
      url: (studyId: number) => `${environment.baseUrl}/api/${environment.version}/tera/study/${studyId}/year_estimate_value_cover`,
      method: 'Patch'
    }
  },
  estimateValueCover: {
    create: {
      url: (estimateValue: number) => `${environment.baseUrl}/api/${environment.version}/tera/estimate_value/${estimateValue}/estimate_value_cover`,
      method: 'Post'
    },
    delete: {
      url: (estimateValue: number) => `${environment.baseUrl}/api/${environment.version}/tera/estimate_value_cover/${estimateValue}`,
      method: 'Delete'
    },
  },
  risk: {
    getAll: {
      url: (studyId: number) => `${environment.baseUrl}/api/${environment.version}/tera/study/${studyId}/risk`,
      method: 'Get'
    },
    create: {
      url: (studyId: number) => `${environment.baseUrl}/api/${environment.version}/tera/study/${studyId}/risk`,
      method: 'Post'
    },
    delete: {
      url: (id: number) => `${environment.baseUrl}/api/${environment.version}/tera/risk/${id}`,
      method: 'Delete'
    },
    updateMany: {
      url: (studyId: number) => `${environment.baseUrl}/api/${environment.version}/tera/study/${studyId}/risk`,
      method: 'Patch'
    }
  },
  marketingReport: {
    getAll: {
      url: (studyId: number) => `${environment.baseUrl}/api/${environment.version}/tera/study/${studyId}/marketing_report`,
      method: 'Get'
    },
    updateMany: {
      url: (studyId: number) => `${environment.baseUrl}/api/${environment.version}/tera/study/${studyId}/marketing_report`,
      method: 'Patch'
    }
  },
  marketingReportItem: {
    create: {
      url: (studyId: number) => `${environment.baseUrl}/api/${environment.version}/tera/study/${studyId}/marketing_report_item`,
      method: 'Post'
    },
    delete: {
      url: (id: number) => `${environment.baseUrl}/api/${environment.version}/tera/marketing_report_item/${id}`,
      method: 'Delete'
    },
  },
  marketingReportSubItem: {
    create: {
      url: (parentItemId: number) => `${environment.baseUrl}/api/${environment.version}/tera/marketing_report_item/${parentItemId}/marketing_report_sub_item`,
      method: 'Post'
    },
    delete: {
      url: (id: number) => `${environment.baseUrl}/api/${environment.version}/tera/marketing_report_sub_item/${id}`,
      method: 'Delete'
    }
  },
  projection: {
    create: {
      url: `${environment.baseUrl}/api/${environment.version}/tera/projection`,
      method: 'Post'
    },
    delete: {
      url: (id: number) => `${environment.baseUrl}/api/${environment.version}/tera/projection/${id}`,
      method: 'Delete'
    },
  },
  import: {
    get: {
      url: (studyId: number) => `${environment.baseUrl}/api/${environment.version}/tera/study/${studyId}/imported_year`,
      method: 'Get'
    },
    update: {
      url: (studyId: number) => `${environment.baseUrl}/api/${environment.version}/tera/study/${studyId}/imported_year`,
      method: 'Patch'
    },
    clear: {
      url: (studyId: number) => `${environment.baseUrl}/api/${environment.version}/tera/study/${studyId}/imported_year`,
      method: 'Delete'
    },
  }
};
