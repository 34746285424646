import {throwError as observableThrowError, Observable, BehaviorSubject} from 'rxjs';

import {take, filter, catchError, switchMap, finalize} from 'rxjs/operators';
import {Injectable, Injector, NgZone} from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpSentEvent,
  HttpHeaderResponse,
  HttpProgressEvent,
  HttpResponse,
  HttpUserEvent,
  HttpErrorResponse
} from '@angular/common/http';

import * as _ from 'lodash';
// import {analyticsUrls} from '../modules/layout/analytics/analytics.urls';

import {AuthService} from '../services/auth.service';
import {GlobalService} from '../services/global.service';
import {LocalizationService} from '../services/localization.service';

@Injectable()
export class ApiInterceptor implements HttpInterceptor {

  private isRefreshingToken: boolean = false;
  private tokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  constructor(private injector: Injector) {
  }

  private applyCredentials = function (req, accessToken: string, refreshToken: string) {

    // if (_.includes(_.map(analyticsUrls, route => route), req.url)) return req.clone();

    return req.clone({
      setHeaders: {
        'Content-type': 'application/json',
        'accessToken': accessToken || 'at',
        'refreshToken': refreshToken || 'rt'
      }
    });
  };

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpSentEvent | HttpHeaderResponse | HttpProgressEvent | HttpResponse<any> | HttpUserEvent<any>> {
    const authService = this.injector.get(AuthService);

    return <any> next.handle(this.applyCredentials(req, authService.getAuthToken(), authService.getRefreshToken())).pipe(
      catchError(error => {
        if (error instanceof HttpErrorResponse) {
          if ((<HttpErrorResponse>error).status == 0) return observableThrowError(error);

          const global = this.injector.get(GlobalService);
          const locale = this.injector.get(LocalizationService);

          switch ((<HttpErrorResponse>error).status) {
            case 400:
            case 404:
            case 403:
            case 406:
            case 500:
            case 503:
              global.pushAppMessage('error', locale.layout.error, error.error.message);
              return observableThrowError(error);
            case 401:
              return this.handle401Error(req, next);
            case 405:
              global.saleError.emit();
              return observableThrowError(error);
          }
        } else return observableThrowError(error);
      }));
  }

  handle401Error(req: HttpRequest<any>, next: HttpHandler) {
    const authService = this.injector.get(AuthService);

    if (!this.isRefreshingToken) {
      this.isRefreshingToken = true;
      // Reset here so that the following requests wait until the token
      // comes back from the refreshToken call.
      this.tokenSubject.next(null);

      return authService.refreshTheToken().pipe(
        switchMap((newToken: string) => {
          if (newToken == '') return authService.logOut(true); // If we don't get a new token, we are in trouble so logout.
          this.tokenSubject.next(newToken);
          return next.handle(this.applyCredentials(req, newToken, authService.getRefreshToken()));
        }),
        catchError(error => authService.logOut(true)), // If there is an exception calling 'refreshToken', bad news so logout. TODO: Check why error is not returned here
        finalize(() => this.isRefreshingToken = false));
    } else {
      return this.tokenSubject.pipe(
        filter(token => token != null),
        take(1),
        switchMap(token => {
          return next.handle(this.applyCredentials(req, token, authService.getRefreshToken()));
        }));
    }
  }
}
