import {Injectable, NgZone} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {StorageService} from './storage.service';
import {BehaviorSubject} from 'rxjs';

@Injectable()
export class ToggleService {

  public datepickerState: boolean = false;

  // To be removed
  public reportsModalNumber: number = null;
  public showReportsModal: boolean = false;
  // To be removed

  public toolsChecker: boolean = false
  public showChat: boolean = false;
  public showGlobalAdd: boolean = false;
  public showSubmenu: boolean = false;
  public sidebarActived: boolean = false;
  public width: any;
  private currentWidth: any;
  public showMenu: boolean = false;
  public showProfile: boolean = false;
  public showSidebar: boolean = false;
  public showFilter: boolean = false;
  public showGlobalDropdown: boolean = false;
  public showGlobalMoreDropdown: boolean = false;
  public showGlobalSidebarFixedRight: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public showGlobalPrimaryModal: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public showPassword: boolean = false;
  public showFormInfoIcon: boolean = false;
  public showGlobalTableGrid: boolean = false;
  public hideSubmenuItems: BehaviorSubject<boolean> = new BehaviorSubject(false);

  public changePasswordModal: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public labelModal: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public mentorRequestModal: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public languageRequestModal: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public promotionRequestModal: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public categoryModal: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public occupationModal: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public workGroupModal: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public groupModal: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public manageModal: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public milestoneModal: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public estimationUnitModal: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public estimationLogModal: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public customEventCreateModal: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public userSidebar: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public companySidebar: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public departmentSidebar: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public productMaterialSidebar: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);


  public manageParticipantsModal: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public manageModeratorsModal: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public classroomModal: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(private _router: Router,
              private _ngZone: NgZone,
              private _storage: StorageService) {

    this.sidebarActived = false;
    this.showSubmenu = false;

    this._router.events.subscribe(($event) => {
      if (!($event instanceof NavigationEnd)) return;
      // SAVE LAST TABLE STATE AND USE GRID OR LIST BASED ON WHAT USER CHOSE
      if (this._storage.get('table')) this.showGlobalTableGrid = true;

      if (this.currentWidth <= 1280) {}
    });

    this._ngZone.run(() => this.width = window.innerWidth);

    if (this.width <= 1280) {}

    if (this.width > 1280) {}
  }

  onResize(event) {
    this.currentWidth = event.target.innerWidth;
    if (event.target.innerWidth <= 1280) {
    } else {
    }
  }

  onShowMenu() {
    this.showMenu = !this.showMenu;
  }

  onShowPassword() {
    this.showPassword = !this.showPassword;
  }

  onProfileShow() {
    this.showProfile = !this.showProfile;
  }

  onShowSubmenu() {
    this.showSubmenu = !this.showSubmenu;
  }

  onShowSidebar() {
    this.showSidebar = !this.showSidebar;
  }

  onShowFilter() {
    this.showFilter = !this.showFilter;
  }

  onShowGlobalDropdown() {
    this.showGlobalDropdown = !this.showGlobalDropdown;
  }

  onShowGlobalTableGrid() {
    this.showGlobalTableGrid = !this.showGlobalTableGrid;
    this._storage.set('table', this.showGlobalTableGrid);
  }

  onShowChat() {
    this.showChat = !this.showChat;
  }

}


