
export const LayoutTranslations = {
  'eng': {
    language: 'Language',
    eng: 'English',
    hrv: 'Croatian',
    updateprofile: 'Update profile',
    changepassword: 'Change password',
    settings: 'Settings',
    logout: 'Log out',
    default: 'Default',
    custom: 'Custom',
    business: 'Business',
    home: 'Home',
    users: 'Users',
    company: 'Company',
    clientcompany: 'Clinet company',
    contacts: 'Contacts',
    products: 'Products',
    productsAndMaterials: 'Products & materials',
    documents: 'Documents',
    projects: 'Projects',
    elearning: 'e-Learning',
    lead: 'Lead',
    warehouse: 'Warehouse',
    humanresources: 'Human Resources',
    calendar: 'Calendar',
    reports: 'Reports',
    analytics: 'Analytics',
    admin: 'Admin',
    NoCompanyWarningHeader: 'No company registered',
    NoCompanyWarningText: 'You do not have a company registered, please create a company in order to use the application',
    error: 'Error',
    colorscheme: 'Color scheme',
    myStudies: 'My studies',
    mentorsUsers: 'Mentoring',
    investmentStudy: 'Investment study',
    projectDefining: 'Project defining',
    tools: 'Tools',
    systemSettings: 'System settings',
    addnewuser: 'Add new user',
    addnewcompany: 'Add new company',
    addnewbranch: 'Add new branch',
    addnewdepartment: 'Add new department',
    defaultLanguage: 'Default system language',
    studyLanguage: 'Default Study language',
    printLanguage: 'Choose print language',
    whatIsOnScreen: 'What\'s on the screen?',
  },

  'hrv': {
    language: 'Jezik',
    eng: 'Engleski',
    hrv: 'Hrvatski',
    updateprofile: 'Uredi profil',
    changepassword: 'Promijeni lozinku',
    settings: 'Postavke',
    logout: 'Odjava',
    default: 'Standardna',
    custom: 'Prilagođena',
    business: 'Poslovna',
    home: 'Početna',
    users: 'Korisnici',
    company: 'Tvrtka',
    clientcompany: 'Klijent tvrtka',
    contacts: 'Kontakti',
    products: 'Proizvodi',
    productsAndMaterials: 'Proizvodi i materijali',
    documents: 'Dokumenti',
    projects: 'Projekti',
    elearning: 'e-Učenje',
    lead: 'Lead',
    warehouse: 'Skladište',
    humanresources: 'Ljudski Resursi',
    calendar: 'Kalendar',
    reports: 'Izvještaji',
    analytics: 'Analitika',
    admin: 'Admin',
    NoCompanyWarningHeader: 'Nema registrirane tvrtke',
    NoCompanyWarningText: 'Nemate registriranu tvrtku, molimo da ju kreirate kako biste koristili aplikaciju',
    error: 'Greška',
    colorscheme: 'Boja',
    myStudies: 'Moje studije',
    mentorsUsers: 'Mentorstvo',
    investmentStudy: 'Investicijska studija',
    projectDefining: 'Definiranje projekta',
    tools: 'Alati',
    systemSettings: 'Postavke sustava',
    addnewuser: 'Dodaj novog korisnika',
    addnewcompany: 'Dodaj novu tvrtku',
    addnewbranch: 'Dodaj novu poslovnicu',
    addnewdepartment: 'Dodaj novi odjel',
    defaultLanguage: 'Zadani jezik sustava',
    studyLanguage: 'Zadani jezik studije',
    printLanguage: 'Odaberi jezik za print',
    whatIsOnScreen: 'Što se nalazi na ekranu?',
  }
};
