import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {AuthService} from '../services/auth.service';

@Injectable()
export class HasPermissionResolver implements Resolve<any> {

  constructor(private _auth: AuthService, private _router: Router) {
  }

  resolve(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    let hasAccess = this._auth.checkPermission(next.data.permission);
    if (hasAccess) return true;

    this._router.navigate(['forbidden']);
  }

}
