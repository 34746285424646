export  const ListTranslations = {

  'eng': {
    contactName: 'Contact name',
    email: 'Email',
    mobile: 'Mobile',
    occupation: 'Occupation',
    type: 'Type',
    name: 'Name',
    phone: 'Phone',
    address: 'Address',
    state: 'State',
    basicPrice: 'Basic price',
    retailPrice: 'Retail price',
    label: 'Label',
    number: 'Number',
    category: 'Category',
    start: 'Start',
    end: 'End',
    leader: 'Leader',
    createdAt: 'Created at',
    createdBy: 'Created by',
    loggedUnit: 'Logged unit',
    date: 'Date',
    value: 'Value',
    billable: 'Billable',
    task: 'Task',
    taskCategory: 'Task category',
    summary: 'Summary',
    loggedBy: 'Logged by',
    capacity: 'Capacity',
    group: 'Group',
    supervisor: 'Supervisor',
    description: 'Description',
    user: 'User',
    timeElapsed: 'Time elapsed',
    submittedOn: 'Submitted on',
    score: 'Score',
    results: 'Results',
    company: 'Company',
    netPrice: 'Net price',
    totalPrice: 'Total price',
    vat: 'Vat',
    unit: 'Unit',
    currency: 'Currency',
    sku: 'SKU'
  },
  'hrv': {
    contactName: 'Ime kontakta',
    email: 'E-mail',
    mobile: 'Mobitel',
    occupation: 'Zanimanje',
    type: 'Tip',
    name: 'Naziv',
    phone: 'Telefon',
    address: 'Adresa',
    state: 'Stanje',
    basicPrice: 'VPC',
    retailPrice: 'MPC',
    label: 'Oznaka',
    number: 'Broj',
    category: 'Kategorija',
    start: 'Početak',
    end: 'Završetak',
    leader: 'Voditelj',
    createdAt: 'Datum kreiranja',
    createdBy: 'Kreirao',
    loggedUnit: 'Upisana jedinica',
    date: 'Datum',
    value: 'Vrijednost',
    billable: 'Naplativo',
    task: 'Zadatak',
    taskCategory: 'Kategorija zad.',
    summary: 'Opis',
    loggedBy: 'Upisao',
    capacity: 'Kapacitet',
    group: 'Grupa',
    supervisor: 'Voditelj',
    description: 'Opis',
    user: 'Korisnik',
    timeElapsed: 'Proteklo vrijeme',
    submittedOn: 'Predan',
    score: 'Bodovi',
    results: 'Rezultat',
    company: 'Kompanija',
    netPrice: 'Neto cijena',
    totalPrice: 'Ukupna cijena',
    vat: 'PDV',
    unit: 'Mjerna jedinica',
    currency: 'Valuta',
    sku: 'SKU'
  }

};
