import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GuidelinesRoutingModule } from './guidelines-routing.module';
import { GuidelinesComponent } from './guidelines.component';
 import {MarkdownModule} from 'ngx-markdown';
@NgModule({
  imports: [
    CommonModule,
    GuidelinesRoutingModule,
    MarkdownModule.forRoot()
  ],
  declarations: [GuidelinesComponent]
})
export class GuidelinesModule { }
