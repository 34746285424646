import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MultiselectComponent } from './multiselect.component';
import { MultiselectSearchPipe } from './multiselect-search.pipe';
import { FormsModule } from '@angular/forms';
import {ClickOutsideModule} from '../../directives/clickOutside/clickOutside.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ClickOutsideModule
  ],
  declarations: [
    MultiselectComponent,
    MultiselectSearchPipe
  ],
  exports: [
    MultiselectComponent
  ]
})
export class MultiselectModule { }
