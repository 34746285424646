<div class="layout-container-sidebar-tag">
  <div class="layout-container-sidebar-content">
    <p-scrollPanel [style]="{width: '100%', height: 'calc(100vh - 123px)'}"
                   styleClass="layout-container-sidebar-content-inner">
      <div class="layout-container-sidebar-content-inner-header">
        <div class="layout-container-sidebar-content-inner-header-logo">
          <div
            *ngIf="user.details?.profileImage; else userInitials"
            class="avatar clickable_link"
            [routerLink]="['/user/', user.id, 'details']">
            <img src="{{_fileUpload.getMediaPath(user.details?.profileImage['thumbnail'])}}" alt="">
          </div>
          <ng-template #userInitials>
            <div class="initials clickable_link"
                 [routerLink]="['/user/', user.id, 'details']"
                 [ngStyle]="{'background-color': user.iconColor}">
              <span>{{user.getInitials()}}</span>
            </div>
          </ng-template>
        </div>
        <div class="layout-container-sidebar-content-inner-header-name">
          <span class="capitalize">{{user?.getFullName() || '-'}}</span>
        </div>
        <div class="layout-container-sidebar-content-inner-header-desc colored">
          <span class="capitalize">{{user?.company?.name || '-'}}</span>
        </div>
        <div class="layout-container-sidebar-content-inner-header-mail" *ngIf="user?.email">
          <a href="mailto:{{user.email}}" class="form-button btn-blue display_inline_block">
            <i class="icon-envelope"></i>{{_locale.user.sendmail}}</a>
        </div>
      </div>

      <!--ADDRESS-->
      <div class="layout-container-sidebar-content-inner-grid" *ngIf="user?.addresses.length">
        <div class="layout-container-sidebar-content-inner-name">
          {{_locale.user.address}}
        </div>
        <div class="layout-container-sidebar-content-inner-desc">
          {{user.getAddress(_global.commonData.countries, _locale.countries)}}
        </div>
      </div>
      <!--TYPE-->
      <div class="layout-container-sidebar-content-inner-grid" *ngIf="type">
        <div class="layout-container-sidebar-content-inner-name">
          {{_locale.user.type}}
        </div>
        <div class="layout-container-sidebar-content-inner-desc">
          <span class="capitalize">{{_locale.userTypes[type?.slug]}}</span>
        </div>
      </div>
      <!--TITLE-->
      <div class="layout-container-sidebar-content-inner-grid" *ngIf="user?.title !== ''">
        <div class="layout-container-sidebar-content-inner-name">
          {{_locale.user.title}}
        </div>
        <div class="layout-container-sidebar-content-inner-desc">
          <span class="capitalize">{{user?.title || '-'}}</span>
        </div>
      </div>
      <!--COMPANY-->
      <div class="layout-container-sidebar-content-inner-grid" *ngIf="user?.company">
        <div class="layout-container-sidebar-content-inner-name">
          {{_locale.user.company}}
        </div>
        <div class="layout-container-sidebar-content-inner-desc">
          <span class="capitalize">{{user?.company?.name || '-'}}</span>
        </div>
      </div>
      <!--BRANCH-->
      <div class="layout-container-sidebar-content-inner-grid" *ngIf="user?.branch">
        <div class="layout-container-sidebar-content-inner-name">
          {{_locale.user.branch}}
        </div>
        <div class="layout-container-sidebar-content-inner-desc">
          {{user.branch?.name || '-'}}
        </div>
      </div>
      <!--PERSONAL ID NUMBER-->
      <div class="layout-container-sidebar-content-inner-grid" *ngIf="user?.details?.personalIdNumber">
        <div class="layout-container-sidebar-content-inner-name">
          {{_locale.user.oib}}
        </div>
        <div class="layout-container-sidebar-content-inner-desc">
          {{user.details?.personalIdNumber || '-'}}
        </div>
      </div>
      <!--BIRTH DATE-->
      <div class="layout-container-sidebar-content-inner-grid">
        <div class="layout-container-sidebar-content-inner-name">
          {{_locale.user.birthdate}}
        </div>
        <div class="layout-container-sidebar-content-inner-desc" *ngIf="user?.details?.birthDate">
          {{user.details && user.details.birthDate ? _global.timestampToDate(user.details.birthDate) : '-'}}
        </div>
      </div>

      <!--<p class="layout-container-sidebar-content-inner-pricelist-grid-name colored">-->
        <!--Price List-->
      <!--</p>-->

      <!--<div class="layout-container-sidebar-content-inner-pricelist-grid">-->
        <!--<div class="layout-container-sidebar-content-inner-pricelist-product font_bold">-->
          <!--Product-->
        <!--</div>-->
        <!--<div class="layout-container-sidebar-content-inner-pricelist-unit font_bold">-->
          <!--Unit-->
        <!--</div>-->
        <!--<div class="layout-container-sidebar-content-inner-pricelist-price font_bold">-->
          <!--Price-->
        <!--</div>-->
      <!--</div>-->

      <!--<div class="layout-container-sidebar-content-inner-pricelist-grid" *ngFor="let data of [1,2,3,4,5,6,7]">-->
        <!--<div class="layout-container-sidebar-content-inner-pricelist-product">-->
          <!--Lorem ipsum dolor sit amet, consectetur adipiscing elit.-->
        <!--</div>-->
        <!--<div class="layout-container-sidebar-content-inner-pricelist-unit">-->
          <!--km-->
        <!--</div>-->
        <!--<div class="layout-container-sidebar-content-inner-pricelist-price">-->
          <!--150-->
        <!--</div>-->
      <!--</div>-->

    </p-scrollPanel>
  </div>
</div>
