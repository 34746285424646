import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FilterComponent} from './filter.component';
import {ClickOutsideModule} from '../../directives/clickOutside/clickOutside.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MultiselectModule} from '../multiselect/multiselect.module';
import {ChipsModule} from 'primeng/chips';
import {FlatpickrModule} from '../flatpickr/flatpickr.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MultiselectModule,
    ClickOutsideModule,
    FlatpickrModule,
    ChipsModule
  ],
  providers: [
  ],
  declarations: [
    FilterComponent,
  ],
  exports: [
    FilterComponent
  ]
})
export class FilterModule {
}
