import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {BreadcrumbsTranslations} from '../localizations/breadcrumbs';
import {DatepickerTranslations} from '../localizations/datepicker';
import {LayoutTranslations} from '../localizations/layout';
import {UserTranslations} from '../modules/layout/user/localizations/user';
import {CompanyTranslations} from '../modules/layout/company/localizations/company';
import {PaginationTranslations} from '../components/pagination/pagination-localization';
import {FilterTranslations} from '../components/filter/filter-localization';
import {ArchiveTranslations} from '../components/archive/archive-localizations';
import {SearchTranslations} from '../components/search/search-localization';
import {LabelTranslations} from '../components/modals/label-modal/label-modal-localization';
import {CategoryTranslations} from '../components/modals/category-modal/category-modal-localization';
import {OccupationTranslations} from '../components/modals/occupation-modal/occupation-modal-licalization';
import {WorkgroupTranslations} from '../components/modals/workgroup-modal/workgroup-modal-localization';
import {NoRecordsTranslations} from '../components/no-records/no-records.localization';
import {BreadcrumbService} from './breadcrumb.service';
import {ActivatedRoute} from '@angular/router';
import {ForbiddenTranslations} from '../components/errors/forbidden/forbidden-localization';
import {CountriesTranslations} from '../localizations/common/countries';
import {IndustryTypeTranslations} from '../localizations/common/industry-type';
import {ContactTypeTranslations} from '../localizations/common/contact-types';
import {AddressTypeTranslations} from '../localizations/common/address-types';
import {InternalServerErrorTranslations} from '../components/errors/internal-server-error/internal-server-error-localization';
import {ContactTranslations} from '../modules/layout/contacts/localizations/contacts-localization';
import {ConfirmationDialogTranslations} from '../localizations/confirmation-dialog';
import {UserTypeTranslations} from '../localizations/common/user-types';
import {AdminTranslations} from '../modules/layout/admin/localizations/admin';
import {BranchTranslations} from '../modules/layout/company/localizations/branch';
import {AuthTranslations} from '../components/auth/auth-localizations';
import {SalutationTranslations} from '../localizations/common/salutations';
import {FileUploadTranslations} from '../localizations/file-upload';
import {ToolTipTranslations} from '../localizations/tooltip';
import {GlobalTranslations} from '../localizations/global';
import {ActivityTranslations} from '../components/activity/activity-localizations';
import {VideoPlayerTranslations} from '../components/video-player/video-player.localization';
import {ParticipantModalTranslations} from '../components/manage-participants-modal/participant-modal.localization';
import {CalendarMonthNumbersTranslations} from '../localizations/calendar-months';
import {GroupModalTranslations} from '../components/modals/group-modal/group-modal-localization';
import {ListTranslations} from '../localizations/lists';
import {HomeTranslations} from '../modules/layout/home/localizations/home';
import {ErrorTranslations} from '../components/error-msg/error-msg-localization';
import {ServiceUnavailableTranslations} from '../components/errors/service-unavailable/service-unavailable.localization';
import {StudyTranslations} from '../modules/layout/study/localizations/study';
import {ExportTranslations} from '../localizations/export';
import {HoverTooltipTranslations} from '../components/hover-tooltip/hoover-tooltip-localization';
import {WizardTranslations} from '../localizations/wizard';
import {StorageService} from './storage.service';
import {ProjectTranslations} from '../modules/layout/study/localizations/project';

@Injectable()
export class LocalizationService {
  setLanguage: string
  public languageList: string[] = ['hrv', 'eng'];
  public main: BehaviorSubject<string> = new BehaviorSubject<string>('hrv');

  public error: any;

  public global: any;
  public export: any;
  public home: any;
  public calendar: any;

  public lists: any;

  public auth: any;
  public fileUpload: any;
  public monthNumbers: any;

  public breadcrumbs: any;
  public datePicker: any;
  public activity: any;
  public video: any;

  public layout: any;
  public user: any;
  public company: any;
  public branch: any;
  public contacts: any;
  public admin: any;
  public product: any;
  public eLearning: any;
  public project: any;
  public warehouse: any;
  public documents: any;
  public reports: any;
  public mailbox: any;
  public study: any;

  //<editor-fold desc="Components">
  public pagination: any;
  public filter: any;
  public archive: any;
  public search: any;
  public noRecords: any;
  public forbidden: any;
  public internalServerError: any;
  public serviceUnavailable: any;
  //</editor-fold>

  //<editor-fold desc="Modals">
  public labelModal: any;
  public categoryModal: any;
  public occupationModal: any;
  public workGroupModal: any;
  public participantModal: any;
  public groupModal: any;
  //</editor-fold>

  //<editor-fold desc="Commons">
  public countries: any;
  public industryTypes: any;
  public contactTypes: any;
  public addressTypes: any;
  public userTypes: any;
  public salutation: any;
  //</editor-fold>

  public confirm: any;
  public tooltip: any;
  public hoverTooltip: any;
  public wizard: any;

  constructor(private _breadcrumb: BreadcrumbService,
              private _storage: StorageService,
              private _route: ActivatedRoute
  ) {


    this.main.subscribe(lang => {
      lang = lang.toLowerCase(); //Change it to lower case in case someone managed to type it in uppercase
      this.error = ErrorTranslations[lang];

      this.global = GlobalTranslations[lang];
      this.export = ExportTranslations[lang];
      this.home = HomeTranslations[lang];

      this.lists = ListTranslations[lang];

      this.auth = AuthTranslations[lang];
      this.fileUpload = FileUploadTranslations[lang];
      this.video = VideoPlayerTranslations[lang];
      this.monthNumbers = CalendarMonthNumbersTranslations[lang];

      this.breadcrumbs = BreadcrumbsTranslations[lang];
      this.datePicker = DatepickerTranslations[lang];
      this.activity = ActivityTranslations[lang];
      this.layout = LayoutTranslations[lang];
      this.user = UserTranslations[lang];
      this.company = CompanyTranslations[lang];
      this.branch = BranchTranslations[lang];
      this.contacts = ContactTranslations[lang];
      this.admin = AdminTranslations[lang];
      this.study = StudyTranslations[lang];
      this.project = ProjectTranslations[lang];

      this.pagination = PaginationTranslations[lang];
      this.filter = FilterTranslations[lang];
      this.archive = ArchiveTranslations[lang];
      this.search = SearchTranslations[lang];
      this.noRecords = NoRecordsTranslations[lang];
      this.forbidden = ForbiddenTranslations[lang];
      this.internalServerError = InternalServerErrorTranslations[lang];
      this.serviceUnavailable = ServiceUnavailableTranslations[lang];


      this.labelModal = LabelTranslations[lang];
      this.categoryModal = CategoryTranslations[lang];
      this.occupationModal = OccupationTranslations[lang];
      this.workGroupModal = WorkgroupTranslations[lang];
      this.participantModal = ParticipantModalTranslations[lang];
      this.groupModal = GroupModalTranslations[lang];

      this.countries = CountriesTranslations[lang];
      this.industryTypes = IndustryTypeTranslations[lang];
      this.contactTypes = ContactTypeTranslations[lang];
      this.addressTypes = AddressTypeTranslations[lang];
      this.userTypes = UserTypeTranslations[lang];
      this.salutation = SalutationTranslations[lang];

      this.confirm = ConfirmationDialogTranslations[lang];
      this.tooltip = ToolTipTranslations[lang];
      this.hoverTooltip = HoverTooltipTranslations[lang];
      this.wizard = WizardTranslations[lang];
    });
  }

  set(lang: string): void {
    this.main.next(lang);
    this._breadcrumb.getBreadcrumbs(this._route.root);
  }

  getCurrentLocaleShort(): string {
    const mainValue: string = this.main.getValue();
    let locale: string;
    switch (mainValue) {
      case 'eng':
        locale = 'en';
        break;
      case 'hrv':
        locale = 'hr';
        break;
      case 'ger':
        locale = 'de';
        break;
      case 'srp':
        locale = 'sr';
        break;
    }
    return locale;
  }

}
