<div id="modal-primary" class="manage-assignees">
  <div class="modal-overlay"
       [class.electron]="_electronService.isElectronApp"
       [class.active]="_toggle.groupModal.getValue()"
       (visible)="_toggle.groupModal.getValue()">
    <div class="modal-container">
      <div class="modal-content" (appClickOutside)="onClickOutsideGlobalPrimaryModal($event)"
           [class.electron]="_electronService.isElectronApp">
        <div class="global-close-btn right-side">
          <i class="icon-remove" (click)="_toggle.groupModal.next(false); clickOutsideCounterModal = 1;"></i>
        </div>
        <h4 class="form-name colored">{{_locale.groupModal.manage}}</h4>
        <div class="modal-content-box">
          <form class="" [formGroup]="form" (submit)="group ? update(form.value) : create(form.value)">
            <div class="form-colors" *ngFor="let color of colors">
              <div class="form-colors-inline" [class.selectedColor]="color === (selectedColor | async)"
                   (click)="selectLabelColor(color)">
                <div class="form-label-color" [ngStyle]="{'background-color': color}">
                  <i class="icon-checked" [hidden]="color != (selectedColor | async)"></i>
                </div>
              </div>
            </div>
            <div class="form-grid full" style="position: relative; top: -25px;">
              <app-error-msg [object]="form.get('color')"></app-error-msg>
            </div>
            <div class="form-grid full">
              <div class="form-grid-full">
                <div class="form-flex-input">
                  <input
                    type="text"
                    [placeholder]="_locale.groupModal.groupNamePlaceholder"
                    formControlName="name"
                  >
                  <button class="form-button btn-blue" type="submit">
                    {{group ? _locale.groupModal.save : _locale.groupModal.add}}
                  </button>
                </div>
                <app-error-msg [object]="form.get('name')"></app-error-msg>
              </div>
            </div>
            <div class="form-grid full">
              <div class="form-grid-full">
                <div>
                  <label for="description">{{_locale.groupModal.description}}</label>
                  <textarea
                    name="description"
                    id="description"
                    cols="30"
                    rows="30"
                    formControlName="description"
                    class="input-colored"
                    style="height: 120px"
                  ></textarea>
                </div>
              </div>
            </div>
            <div class="form-grid full" *ngFor="let group of groups; let i = index">
              <div class="form-grid-full">
                <div class="form-flex flex_space_between">
                  <div class="label" [ngStyle]="{'background-color': group.color}">
                    {{group.name}}
                  </div>
                  <div class="form-flex no-border">
                    <div class="remove" (click)="edit(group)">
                      <div class="icon-rounded clickable_link">
                        <i class="icon-edit"></i>
                      </div>
                    </div>
                    <div class="remove" (click)="delete(group)">
                      <div class="icon-rounded clickable_link">
                        <i class="icon-remove"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="form-grid full margin_top">
              <div class="form-grid-full">
                <button class="form-button btn-blue"
                        type="button"
                        (click)="clear(true)"
                >
                  {{_locale.groupModal.clear}}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
