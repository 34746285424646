export const CountriesTranslations = {
  'eng': {
    abw: 'Aruba',
    afg: 'Afghanistan',
    ago: 'Angola',
    aia: 'Anguilla',
    alb: 'Albania',
    and: 'Andorra',
    are: 'United Arab Emirates',
    arg: 'Argentina',
    arm: 'Armenia',
    asm: 'American Samoa',
    ata: 'Antarctica',
    atf: 'French Southern Territories',
    atg: 'Antigua and Barbuda',
    aus: 'Australia',
    aut: 'Austria',
    aze: 'Azerbaijan',
    bdi: 'Burundi',
    bel: 'Belgium',
    ben: 'Benin',
    bes: 'Bonaire',
    bfa: 'Burkina Faso',
    bgd: 'Bangladesh',
    bgr: 'Bulgaria',
    bhr: 'Bahrain',
    bhs: 'Bahamas',
    bih: 'Bosnia and Herzegovina',
    blm: 'Saint Barthelemy',
    blr: 'Belarus',
    blz: 'Belize',
    bmu: 'Bermuda',
    bol: 'Bolivia',
    bra: 'Brazil',
    brb: 'Barbados',
    brn: 'Brunei Darussalam',
    btn: 'Bhutan',
    bvt: 'Bouvet Island',
    bwa: 'Botswana',
    caf: 'Central African Republic',
    can: 'Canada',
    cck: 'Cocos (Keeling) Islands',
    che: 'Switzerland',
    chl: 'Chile',
    chn: 'China',
    civ: 'Cote d\'Ivoire',
    cmr: 'Cameroon',
    cod: 'Democratic Republic of the Congo',
    cog: 'Congo',
    cok: 'Cook Islands',
    col: 'Colombia',
    com: 'Comoros',
    cpv: 'Cape Verde',
    cri: 'Costa Rica',
    cub: 'Cuba',
    cuw: 'Curacao',
    cxr: 'Christmas Island',
    cym: 'Cayman Islands',
    cyp: 'Cyprus',
    cze: 'Czech Republic',
    deu: 'Germany',
    dji: 'Djibouti',
    dma: 'Dominica',
    dnk: 'Denmark',
    dom: 'Dominican Republic',
    dza: 'Algeria',
    ecu: 'Ecuador',
    egy: 'Egypt',
    eri: 'Eritrea',
    esh: 'Western Sahara',
    esp: 'Spain',
    est: 'Estonia',
    eth: 'Ethiopia',
    fin: 'Finland',
    fji: 'Fiji',
    flk: 'Falkland Islands (Malvinas)',
    fra: 'France',
    fro: 'Faroe Islands',
    fsm: 'Micronesia, Federated States of',
    gab: 'Gabon',
    gbr: 'United Kingdom',
    geo: 'Georgia',
    ggy: 'Guernsey',
    gha: 'Ghana',
    gib: 'Gibraltar',
    gin: 'Guinea',
    glp: 'Guadeloupe',
    gmb: 'Gambia',
    gnb: 'Guinea-Bissau',
    gnq: 'Equatorial Guinea',
    grc: 'Greece',
    grd: 'Grenada',
    grl: 'Greenland',
    gtm: 'Guatemala',
    guf: 'French Guiana',
    gum: 'Guam',
    guy: 'Guyana',
    hkg: 'Hong Kong',
    hmd: 'Heard Island and McDonald Islands',
    hnd: 'Honduras',
    hrv: 'Croatia',
    hti: 'Haiti',
    hun: 'Hungary',
    idn: 'Indonesia',
    imn: 'Isle of Man',
    ind: 'India',
    iot: 'British Indian Ocean Territory',
    irl: 'Ireland',
    irn: 'Iran, Islamic Republic of',
    irq: 'Iraq',
    isl: 'Iceland',
    isr: 'Israel',
    ita: 'Italy',
    jam: 'Jamaica',
    jey: 'Jersey',
    jor: 'Jordan',
    jpn: 'Japan',
    kaz: 'Kazakhstan',
    ken: 'Kenya',
    kgz: 'Kyrgyzstan',
    khm: 'Cambodia',
    kir: 'Kiribati',
    kna: 'Saint Kitts and Nevis',
    kor: 'Korea, Republic of',
    kwt: 'Kuwait',
    lao: 'Lao People\'s Democratic Republic',
    lbn: 'Lebanon',
    lbr: 'Liberia',
    lby: 'Libya',
    lca: 'Saint Lucia',
    lie: 'Liechtenstein',
    lka: 'Sri Lanka',
    lso: 'Lesotho',
    ltu: 'Lithuania',
    lux: 'Luxembourg',
    lva: 'Latvia',
    mac: 'Macao',
    maf: 'Saint Martin (French part)',
    mar: 'Morocco',
    mco: 'Monaco',
    mda: 'Moldova, Republic of',
    mdg: 'Madagascar',
    mdv: 'Maldives',
    mex: 'Mexico',
    mhl: 'Marshall Islands',
    mkd: 'Macedonia, the Former Yugoslav Republic of',
    mli: 'Mali',
    mlt: 'Malta',
    mmr: 'Myanmar',
    mne: 'Montenegro',
    mng: 'Mongolia',
    mnp: 'Northern Mariana Islands',
    moz: 'Mozambique',
    mrt: 'Mauritania',
    msr: 'Montserrat',
    mtq: 'Martinique',
    mus: 'Mauritius',
    mwi: 'Malawi',
    mys: 'Malaysia',
    myt: 'Mayotte',
    nam: 'Namibia',
    ncl: 'New Caledonia',
    ner: 'Niger',
    nfk: 'Norfolk Island',
    nga: 'Nigeria',
    nic: 'Nicaragua',
    niu: 'Niue',
    nld: 'Netherlands',
    nor: 'Norway',
    npl: 'Nepal',
    nru: 'Nauru',
    nzl: 'New Zealand',
    omn: 'Oman',
    pak: 'Pakistan',
    pan: 'Panama',
    pcn: 'Pitcairn',
    per: 'Peru',
    phl: 'Philippines',
    plw: 'Palau',
    png: 'Papua New Guinea',
    pol: 'Poland',
    pri: 'Puerto Rico',
    prk: 'Korea, Democratic People\'s Republic of',
    prt: 'Portugal',
    pry: 'Paraguay',
    pse: 'Palestine, State of',
    pyf: 'French Polynesia',
    qat: 'Qatar',
    reu: 'Reunion',
    rou: 'Romania',
    rus: 'Russian Federation',
    rwa: 'Rwanda',
    sau: 'Saudi Arabia',
    sdn: 'Sudan',
    sen: 'Senegal',
    sgp: 'Singapore',
    sgs: 'South Georgia and the South Sandwich Islands',
    shn: 'Saint Helena',
    sjm: 'Svalbard and Jan Mayen',
    slb: 'Solomon Islands',
    sle: 'Sierra Leone',
    slv: 'El Salvador',
    smr: 'San Marino',
    som: 'Somalia',
    spm: 'Saint Pierre and Miquelon',
    srb: 'Serbia',
    ssd: 'South Sudan',
    stp: 'Sao Tome and Principe',
    sur: 'Suriname',
    svk: 'Slovakia',
    svn: 'Slovenia',
    swe: 'Sweden',
    swz: 'Swaziland',
    sxm: 'Sint Maarten (Dutch part)',
    syc: 'Seychelles',
    syr: 'Syrian Arab Republic',
    tca: 'Turks and Caicos Islands',
    tcd: 'Chad',
    tgo: 'Togo',
    tha: 'Thailand',
    tjk: 'Tajikistan',
    tkl: 'Tokelau',
    tkm: 'Turkmenistan',
    tls: 'Timor-Leste',
    ton: 'Tonga',
    tto: 'Trinidad and Tobago',
    tun: 'Tunisia',
    tur: 'Turkey',
    tuv: 'Tuvalu',
    twn: 'Taiwan, Province of China',
    tza: 'United Republic of Tanzania',
    uga: 'Uganda',
    ukr: 'Ukraine',
    ury: 'Uruguay',
    usa: 'United States',
    uzb: 'Uzbekistan',
    vat: 'Holy See (Vatican City State)',
    vct: 'Saint Vincent and the Grenadines',
    ven: 'Venezuela',
    vgb: 'British Virgin Islands',
    vir: 'US Virgin Islands',
    vnm: 'Viet Nam',
    vut: 'Vanuatu',
    wlf: 'Wallis and Futuna',
    wsm: 'Samoa',
    yem: 'Yemen',
    zaf: 'South Africa',
    zmb: 'Zambia',
    zwe: 'Zimbabwe'
  },
  'hrv': {
    abw: 'Aruba',
    afg: 'Afganistan',
    ago: 'Angola',
    aia: 'Anguila',
    alb: 'Albanija',
    and: 'Andora',
    are: 'Ujedinjeni Arapski Emirati ',
    arg: 'Argentina',
    arm: 'Armenija',
    asm: 'Američka Samoa',
    ata: 'Antarktika',
    atf: 'Južni Francuski Teritoriji',
    atg: 'Antigua i Barbuda',
    aus: 'Australija',
    aut: 'Austrija',
    aze: 'Azerbajdžan',
    bdi: 'Burundi',
    bel: 'Belgija',
    ben: 'Benin',
    bes: 'Bonaire',
    bfa: 'Burikina Faso',
    bgd: 'Bangladeš',
    bgr: 'Bugarska',
    bhr: 'Bahrein',
    bhs: 'Bahami',
    bih: 'Bosna i Hercegovina',
    blm: 'Sveti Bartolomej',
    blr: 'Bjelorusija',
    blz: 'Belize',
    bmu: 'Bermuda',
    bol: 'Bolivija',
    bra: 'Brazila',
    brb: 'Barbados',
    brn: 'Bruneji',
    btn: 'Butan',
    bvt: 'Otok Bouvet',
    bwa: 'Bocvana',
    caf: 'Srednjoafrička Republika',
    can: 'Kanada',
    cck: 'Keeling Otoci',
    che: 'Švicarska',
    chl: 'Čile',
    chn: 'Kina',
    civ: 'Obala Bjelokosti',
    cmr: 'Kameron',
    cod: 'Demokratska Republika Kongo',
    cog: 'Kongo',
    cok: 'Cookovi Otoci',
    col: 'Kolumbija',
    com: 'Komori',
    cpv: 'Zelenortska Republika',
    cri: 'Kostarika',
    cub: 'Kuba',
    cuw: 'Curacao',
    cxr: 'Božićni Otoci',
    cym: 'Kajmanski Otoci',
    cyp: 'Cipar',
    cze: 'Češka',
    deu: 'Njemačka',
    dji: 'Džibuti',
    dma: 'Dominika',
    dnk: 'Danska',
    dom: 'Dominikanska Republika',
    dza: 'Alžir',
    ecu: 'Ekvador',
    egy: 'Egipat',
    eri: 'Eritreja',
    esh: 'Zapadna Sahara',
    esp: 'Španjolska',
    est: 'Estonija',
    eth: 'Etiopija',
    fin: 'Finska',
    fji: 'Fidži',
    flk: 'Falkandski Otoci',
    fra: 'Francuska',
    fro: 'Farski Otoci',
    fsm: 'Mikronezija',
    gab: 'Gabon',
    gbr: 'Ujedinjeno Kraljevstvo',
    geo: 'Gruzija',
    ggy: 'Guernsey',
    gha: 'Gana',
    gib: 'Gibraltar',
    gin: 'Gvineja',
    glp: 'Guadelupe',
    gmb: 'Gambija',
    gnb: 'Gvineja Bisau',
    gnq: 'Ekvatorijalna Gvineja',
    grc: 'Grčka',
    grd: 'Grenada',
    grl: 'Grenland',
    gtm: 'Guatemala',
    guf: 'Francuska Gvajana',
    gum: 'Guam',
    guy: 'Gvajana',
    hkg: 'Hong Kong',
    hmd: 'Otok Heard i McDonald Otoci',
    hnd: 'Honduras',
    hrv: 'Hrvatska',
    hti: 'Haiti',
    hun: 'Mađarska',
    idn: 'Indonezija',
    imn: 'Isle of Man',
    ind: 'Indija',
    iot: 'Britanski teritoriji U Indijskom oceanu',
    irl: 'Irska',
    irn: 'Iran',
    irq: 'Irak',
    isl: 'Island',
    isr: 'Izrael',
    ita: 'Italija',
    jam: 'Jamajka',
    jey: 'Jersey',
    jor: 'Jordan',
    jpn: 'Japan',
    kaz: 'Kazahstan',
    ken: 'Kenija',
    kgz: 'Krigistan',
    khm: 'Kambodža',
    kir: 'Kiribati',
    kna: 'Sveti Kitts i Nevis',
    kor: 'Rebublika Koreja',
    kwt: 'Kuvajt',
    lao: 'Laos',
    lbn: 'Libanon',
    lbr: 'Liberija',
    lby: 'Libija',
    lca: 'Sveta Lucija',
    lie: 'Lihtenštajn',
    lka: 'Šri Lanka',
    lso: 'Lesoto',
    ltu: 'Litva',
    lux: 'Luksmburg',
    lva: 'Latvija',
    mac: 'Makao',
    maf: 'Sveti Martin (fr.dio)',
    mar: 'Maroko',
    mco: 'Monako',
    mda: 'Moldavija',
    mdg: 'Madagaskar',
    mdv: 'Maldivi',
    mex: 'Meksiko',
    mhl: 'Maršalovi',
    mkd: 'Makedonija',
    mli: 'Mali',
    mlt: 'Malta',
    mmr: 'Mjanmar',
    mne: 'Crna Gora',
    mng: 'Mongolija',
    mnp: 'Sjeverni Marijanski Otoci',
    moz: 'Mozambik',
    mrt: 'Mauritanija',
    msr: 'Monteserat',
    mtq: 'Martinik',
    mus: 'Mauricius',
    mwi: 'Malavi',
    mys: 'Malezija',
    myt: 'Mayotte',
    nam: 'Namibija',
    ncl: 'Nova Kaledonija',
    ner: 'Niger',
    nfk: 'Norfolk',
    nga: 'Nigerija',
    nic: 'Nikaragva',
    niu: 'Niue',
    nld: 'Nizozemska',
    nor: 'Norveška',
    npl: 'Nepal',
    nru: 'Nauru',
    nzl: 'Novi Zeland',
    omn: 'Oman',
    pak: 'Pakistan',
    pan: 'Panama',
    pcn: 'Pitcairn',
    per: 'Peur',
    phl: 'Filipini',
    plw: 'Palau',
    png: 'Papua Nova Gvineja',
    pol: 'Poljska',
    pri: 'Portoriko',
    prk: 'DNR Koreja',
    prt: 'Protugal',
    pry: 'Paragvaj',
    pse: 'Palestina',
    pyf: 'Francuska Polinezija',
    qat: 'Katar',
    reu: 'Reunion',
    rou: 'Rumunjska',
    rus: 'Ruska Federacija',
    rwa: 'Ruanda',
    sau: 'Saudijska Arabija',
    sdn: 'Sudan',
    sen: 'Senegal',
    sgp: 'Singapur',
    sgs: 'Južna Georgia i južni Sandwich Otoci',
    shn: 'Sveta Helena',
    sjm: 'Svalvard i Jan Mayen',
    slb: 'Salomoski Otoci',
    sle: 'Siera Leone',
    slv: 'Salvador',
    smr: 'San Marino',
    som: 'Somalija',
    spm: 'Sveti Petar i Miquelon',
    srb: 'Srbija',
    ssd: 'Južni Sudan',
    stp: 'Sveti Toma i Princip',
    sur: 'Surinam',
    svk: 'Slovačka',
    svn: 'Slovenija',
    swe: 'Švedska',
    swz: 'Swaziland',
    sxm: 'Sveti Martin (niz.dio)',
    syc: 'Sejšeli',
    syr: 'Sirija',
    tca: 'Otoci Turks i Caicos',
    tcd: 'Čad',
    tgo: 'Togo',
    tha: 'Tajland',
    tjk: 'Tadžikistan',
    tkl: 'Tokelau',
    tkm: 'Turkmenistan',
    tls: 'Istočni Timor',
    ton: 'Tonga',
    tto: 'Trinidad i Tobago',
    tun: 'Tunis',
    tur: 'Turska',
    tuv: 'Tuvalu',
    twn: 'Tajvan',
    tza: 'Tanaznija',
    uga: 'Uganda',
    ukr: 'Ukrajina',
    ury: 'Urugvaj',
    usa: 'Sjedinjenje Američke Države',
    uzb: 'Uzbekistan',
    vat: 'Vatikan',
    vct: 'Sveti Vincent i Grenadini',
    ven: 'Venezuela',
    vgb: 'Britanski Djevičanski Otoci',
    vir: 'Američki Djevičanski Otoci',
    vnm: 'Vietnam',
    vut: 'Vanuatu',
    wlf: 'Wallis i Futuna',
    wsm: 'Samoa',
    yem: 'Jemen',
    zaf: 'Južna Afrika',
    zmb: 'Zambija',
    zwe: 'Zimbabve'
  }
};
