<div id="pagination" *ngIf="totalPages > 0"
     [class.defaultTheme]="_theme.colorScheme === 'defaultTheme'"
     [class.customTheme]="_theme.colorScheme === 'customTheme'"
     [class.businessTheme]="_theme.colorScheme === 'businessTheme'">
  <div class="pagination-grid">
    <div class="pagination-pages">
      <span class="fromto">
        <span *ngIf="filterData.getValue() && filterData.getValue().page === 1; else otherPage">1</span>
      </span>
      <ng-template *ngIf="filterData.getValue()" #otherPage>{{ 1 + ((filterData.getValue().limit * filterData.getValue().page) - filterData.getValue().limit) }} </ng-template>

      {{_locale.pagination.to}} {{filterData.getValue().limit * filterData.getValue().page}}  {{_locale.pagination.of}} {{totalRecords}} {{_locale.pagination.entries}}

      <span class="current">
        <span (click)="showLimit = true"
              (appClickOutside)="showLimit = false"
        ><span class="clickable_link" [class.colored]="showLimit">{{_locale.pagination.show}} {{filterData.getValue().limit}} <i class="icon-arrow-up"></i></span>
          <div class="limitDropdown" *ngIf="showLimit">
            <ul>
              <li [class.active]="limit === filterData.getValue().limit"
                  *ngFor="let limit of limits"
                  (click)="setLimit(limit)">
                {{limit}}
              </li>
            </ul>
          </div>
        </span>
      </span>
    </div>
    <div class="pagination-selection">
      <div class="pagination-box">
        <div class="pagination-float">

          <div class="move previous" (click)="prev()" *ngIf="filterData.getValue() && totalPages > 1" [ngClass]="{'disabled' : filterData.getValue().page === 1}">
            < <span class="prev-text">{{_locale.pagination.previous}}</span>
          </div>

          <!--<div class="number-container"-->
               <!--*ngFor="let data of numberOfPages; let i = index"-->
               <!--[ngClass]="{'show' : (i + 1) < totalPages}">-->
            <!--<div class="number"-->
                 <!--*ngIf="i <= (filterData.getValue().page + 1) && i > (filterData.getValue().page - 5) || i < (filterData.getValue().page - 1) &&  i > (filterData.getValue().page - 5)"-->
                 <!--[ngClass]="{'active' : filterData.getValue().page === i + 1}"-->
                 <!--(click)="customPage((i + 1)); customPageReset()"-->
            <!--&gt;-->
              <!--<span *ngIf="(i + 1) < totalPages">{{i + 1}}</span>-->
            <!--</div>-->
          <!--</div>-->

          <div class="number"
               *ngIf="filterData.getValue() && filterData.getValue().page > 3"
               [ngClass]="{'active' : filterData.getValue().page === 1}"
               (click)="customPage(1)">1</div>

          <div class="number-container" [ngClass]="{'show' : filterData.getValue() && filterData.getValue().page && filterData.getValue().page > 1 }">
            <div class="number"
                 [ngClass]="{'active' : filterData.getValue() && filterData.getValue().page && filterData.getValue().page - 1 === filterData.getValue().page}"
                 (click)="customPage(filterData.getValue().page - 1);  customPageReset()"
            >
              <span>{{filterData.getValue().page - 1}}</span>
            </div>
          </div>

          <div class="number-container" [ngClass]="{'show' : filterData.getValue() && filterData.getValue().page < totalPages }">
              <div class="number"
                   [ngClass]="{'active' : filterData.getValue() && filterData.getValue().page === filterData.getValue().page}"
                   (click)="customPage(filterData.getValue().page); customPageReset()"
              >
                <span *ngIf="filterData.getValue()">{{filterData.getValue().page}}</span>
              </div>
          </div>

          <div class="number-container" [ngClass]="{'show' : filterData.getValue() && filterData.getValue().page + 1 < totalPages }">
            <div class="number"
                 [ngClass]="{'active' : filterData.getValue() && filterData.getValue().page + 1 === filterData.getValue().page}"
                 (click)="customPage(filterData.getValue().page + 1);  customPageReset()"
            >
              <span *ngIf="filterData.getValue()">{{filterData.getValue().page + 1}}</span>
            </div>
          </div>

          <div class="number-container" [ngClass]="{'show' : filterData.getValue() && filterData.getValue().page + 2 < totalPages }">
            <div class="number"
                 [ngClass]="{'active' : filterData.getValue() && filterData.getValue().page + 2 === filterData.getValue().page}"
                 (click)="customPage(filterData.getValue().page + 2);  customPageReset()"
            >
              <span *ngIf="filterData.getValue()">{{filterData.getValue().page + 2}}</span>
            </div>
          </div>

          <div class="number input" [ngClass]="{'show' : totalPages >= 3}">
            <input type="text"
                   minlength="1"
                   maxlength="4"
                   placeholder="..."
                   (keyup)="customPage(changePage)"
                   [(ngModel)]="changePage"
                   [ngModelOptions]="{standalone: true}"
            >
          </div>

          <div class="number"
               [ngClass]="{'active' : filterData.getValue() && filterData.getValue().page === totalPages}"
               (click)="customPage(totalPages)">{{totalPages}}</div>

          <div class="move last" (click)="next()" [ngClass]="{'disabled' : filterData.getValue() && filterData.getValue().page === totalPages}">
            <span class="next-text">{{_locale.pagination.next}}</span> >
          </div>

        </div>
      </div>
    </div>
  </div>
</div>
