import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve} from '@angular/router';
import {UserService} from '../user.service';
import {Observable} from 'rxjs';
import {User} from '../classes/user';
import {ApiService} from '../../../../services/api.service';

@Injectable()
export class UserGetResolver implements Resolve<any> {

  constructor(private _user: UserService, private _api: ApiService) {
  }

  resolve(route: ActivatedRouteSnapshot): Observable<User> {
    return this._api.handleRequest(this._user.getUser(parseInt(route.params.id)));
  }
}
