export const ParticipantModalTranslations = {
  'eng': {
    manage: 'Manage',
    educators: 'Educators',
    educatorsOn: 'Educators on course',
    students: 'Students',
    studentsOn: 'Students on course',
    employees: 'Employees',
    employeesOn: 'Employees on project',
    clients: 'Clients',
    clientsOn: 'Clients on project'
  },
  'hrv': {
    manage: 'Upravljaj',
    educators: 'Predavači',
    educatorsOn: 'Predavači na tečaju',
    students: 'Polaznici',
    studentsOn: 'Polaznici na tečaju',
    employees: 'Djelatnici',
    employeesOn: 'Djelatnici na projektu',
    clients: 'Klijenti',
    clientsOn: 'Klijenti na projektu'

  }
};
