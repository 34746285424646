import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {ClickOutsideModule} from '../../../directives/clickOutside/clickOutside.module';
import {OccupationModalComponent} from './occupation-modal.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ClickOutsideModule
  ],
  providers: [
  ],
  declarations: [
   OccupationModalComponent
  ],
  exports: [
    OccupationModalComponent
  ]
})
export class OccupationModalModule {
}
