export const GroupModalTranslations = {
  'eng': {
    manage: 'Manage groups',
    add: 'Add',
    save: 'Save',
    groupNamePlaceholder: 'Group name',
    clear: 'Clear',
    description: 'Description'
  },
  'hrv': {
    manage: 'Upravljaj grupama',
    add: 'Dodaj',
    save: 'Sačuvaj',
    groupNamePlaceholder: 'Ime grupe',
    clear: 'Očisti',
    description: 'Opis'
  }
};
