export const AuthTranslations = {
  'eng': {
    welcome: 'Welcome!',
    registration: 'Registration',
    register: 'Register',
    password: 'Password',
    username: 'Username/Email',
    changePassword: 'Change password',
    oldPassword: 'Old password',
    newPassword: 'New password',
    confirmPassword: 'Confirm password',
    save: 'Save',
    cancel: 'Cancel',
    back: 'Back',
    forgotPassword: 'Forgot password?',
    resetPassword: 'Reset password',
    login: 'Log in',
    enterUsernameEmail: 'Enter username or e-mail',
    enterPassword: 'Enter password',
    enterUsername: 'Enter username',
    enterName: 'Enter first name',
    enterSurname: 'Enter surname',
    enterEmail: 'Enter e-mail',
    wrongCredentials : 'Wrong username or password',
    noPermission : 'You\'re not allowed to access this site! Contact administrator.',
  },
  'hrv': {
    welcome: 'Dobrodošli!',
    registration: 'Registracija',
    register: 'Registriraj se',
    password: 'Lozinka',
    username: 'Korisničko ime/Email',
    changePassword: 'Promijeni lozinku',
    oldPassword: 'Stara lozinka',
    newPassword: 'Nova lozinka',
    confirmPassword: 'Potvrdi lozinku',
    save: 'Spremi',
    cancel: 'Odustani',
    back: 'Natrag',
    forgotPassword: 'Zaboravili ste lozinku?',
    resetPassword: 'Promjena lozinke',
    login: 'Prijavi se',
    enterUsernameEmail: 'Unesi korisničko ime ili e-mail',
    enterPassword: 'Unesi lozinku',
    enterUsername: 'Unesi korisničko ime',
    enterName: 'Unesi ime',
    enterSurname: 'Unesi prezime',
    enterEmail: 'Unesi e-mail',
    wrongCredentials : 'Krivo korisničko ime ili lozinka',
    noPermission : 'Nije vam dopušten pristup ovoj stranici! Kontaktirajte administratora.',
  }
};
