export const FilterTranslations = {
  'eng': {
    filter: 'Filters',
    select: '- Select -',
    search: 'Search...',
    apply: 'Apply',
    clear: 'Clear',
    role: 'Role',
    labels: 'Labels',
    category: 'Category',
    workgroup: 'Workgroup',
    occupation: 'Occupation',
    type: 'Type',
    industry: 'Industry',
    tags: 'Tags',
    company: 'Company',
    inputtype: 'Input type',
    startdate: 'Start date',
    enddate: 'End date',
    state: 'State',
    label: 'Label',
    createdat: 'Created at',
    start: 'Start',
    end: 'End',
    date: 'Date',
    hours: 'Hours',
    expense: 'Expense',
    milestones: 'Milestones',
    milestone: 'Milestone',
    issuedon:'Issued on',
    duedate:'Due date',
    participant: 'Participant',
    loggedby: 'Logged by',
    flowtype:'Flow type',
    issuedto:'Issued to',
    group: 'Group',
    selectTwoDates: 'Select two dates',
    removezerostock: 'Remove zero stock',
    status: 'Status',
    applicant: 'Applicant',
    partners: 'Partners',
  },
  'hrv': {
    filter: 'Filteri',
    select: '- Odaberi -',
    search: 'Pretraži...',
    apply: 'Primjeni',
    clear: 'Poništi',
    role: 'Rola',
    labels: 'Oznake',
    category: 'Kategorija',
    workgroup: 'Radna grupa',
    occupation: 'Zanimanje',
    type: 'Tip',
    industry: 'Industrija',
    tags: 'Tagovi',
    company: 'Tvrtka',
    inputtype: 'Tip polja',
    startdate: 'Datum početka',
    enddate: 'Datum završetka',
    state: 'Stanje',
    label: 'Oznaka',
    createdat: 'Datum kreiranja',
    start: 'Početak',
    end: 'Završetak',
    date: 'Datum',
    hours: 'Sati',
    expense: 'Trošak',
    milestones: 'Ključne faze',
    milestone: 'Ključna faza',
    issuedon: 'Datum izdavanja',
    duedate: 'Datum dospijeća',
    participant: 'Sudionik',
    loggedby: 'Upisano od',
    flowtype:'Tip toka',
    issuedto:'Primatelj',
    group: 'Grupa',
    selectTwoDates: 'Odaberite dva datuma',
    removezerostock: 'Ukloni nultu zalihu',
    status: 'Stanje',
    applicant: 'Prijavitelj',
    partners: 'Partneri',
  }
};
