<div class="section-content-header-filter-box"
     (click)="_toggle.showFilter = true"
     (appClickOutside)="_toggle.showFilter = false">
  <button class="form-button filter" [class.colored]="isActive()"><i class="icon-equalizer"></i> <span class="filter-single-name">{{_locale.filter.filter}}</span></button>
  <div class="section-content-header-filter-dropdown" *ngIf="_toggle.showFilter">
    <div class="section-content-header-filter-dropdown-grid">

      <div class="section-content-header-filter-dropdown-selecttype" *ngIf="fields.length">
            <div class="section-content-header-filter-dropdown-selecttype-grid">
              <div class="section-content-header-filter-dropdown-selecttype-grid-container" *ngFor="let field of fields">
                <span class="font_medium">{{_locale.filter[_global.slugify(field.name)]}}</span>

                <div *ngIf="field.type == 'select' || field.type == 'multiselect'" class="input-select-box">
                  <app-multiselect
                    [options]="field.values"
                    [readonly]="true"
                    [multiple]="field.type == 'multiselect'"
                    [placeholder]="_locale.filter.select"
                    [searchPlaceholder]="_locale.filter.search"
                    [(ngModel)]="field.selected"
                    (onSearch)="onSearchStart($event)"
                  >
                  </app-multiselect>
                  <i class="icon-arrow-down"></i>
                </div>

                <div *ngIf="field.type == 'tags'" class="input-select-box">
                  <p-chips id="tags" [(ngModel)]="field.selected" class="input-colored" [allowDuplicate]="false"></p-chips>
                </div>

                <div class="input-select-box" *ngIf="field.type == 'date-range'">
                  <app-flatpickr
                    class="input-colored"
                    [config]="_global.rangePicker"
                    [(ngModel)]="field.selected"
                  ></app-flatpickr>
                  <i class="icon-calendar"></i>
                  <span class="global-error-message" *ngIf="field.selected && field.selected.length == 1">{{_locale.filter.selectTwoDates}}</span>
                </div>

                <div class="input-select-box" *ngIf="field.type == 'date'">
                  <app-flatpickr
                    class="input-colored"
                    [config]="_global.datePicker"
                    [(ngModel)]="field.selected"
                  ></app-flatpickr>
                  <i class="icon-calendar"></i>
                </div>

                <div class="input-select-box" *ngIf="field.type == 'switch'">
                  <div class="custom-switchbox">
                    <label class="input-switch">
                      <input
                        type="checkbox"
                        [checked]="field.selected"
                        (change)="setSwitchValue($event, field)"
                      >
                      <span class="slider round" style="width: 52px;"></span>
                    </label>
                  </div>
                </div>

              </div>
            </div>
      </div>

      <div class="section-content-header-filter-dropdown-clear clickable" (click)="clear()">
        <span>{{_locale.filter.clear}}</span>
      </div>
      <div class="section-content-header-filter-dropdown-apply clickable" (click)="getData()">
        {{_locale.filter.apply}}
      </div>
    </div>
  </div>
</div>
