import {Injectable} from '@angular/core';
import {Resolve} from '@angular/router';
import {UserService} from '../user.service';
import {FilterData} from '../../../../classes/filter-data';
import {Observable} from 'rxjs/index';
import {User} from '../classes/user';
import {ApiService} from '../../../../services/api.service';

@Injectable()
export class UserAllResolver implements Resolve<any> {

  constructor(private _user: UserService,private _api: ApiService) {
  }

  resolve(): Observable<User[]> {
    let initFilters = new FilterData();
    initFilters.filters = [{key: 'archived', value: false, default: true}];
    return this._api.handleRequest(this._user.getUsers(initFilters));
  }

}
