export const ExportTranslations = {
  'eng': {
    users: 'Users',
    studies: 'Studies',
    export: 'Export',
    isActivated: 'Activated',
    type: 'Type',
    teraType: 'Type',
    firstName: 'First name',
    lastName: 'Last name',
    createdAt: 'Created at',
    name: 'Name'
  },
  'hrv': {
    users: 'Korisnici',
    studies: 'Studije',
    export: 'Izvezi',
    isActivated: 'Aktiviran',
    type: 'Tip',
    teraType: 'Tip',
    firstName: 'Ime',
    lastName: 'Prezime',
    createdAt: 'Vrijeme stvaranja',
    name: 'Naziv'
  }
};
