export const HoverTooltipTranslations = {
  'eng': {
    mainTitle: 'For complete clarity in the title, be sure to use both generic and commercial terminology for products and services.',
    entrepreneurDescription: '• Briefly describe the entrepreneur or owner\n' +
                             ' (e.g. when it was founded, who is the founder and in what capacity,\n ' +
                             ' are there other co-founders and owners, what is the main activity, etc.)',
    entrepreneurInfo: '• Describe the existing legal entity\n' +
                      '• Make a projection for the future legal entity\n' +
                      '•  Provide information on key people\n',
    entrepreneurBusinessSubject: '• Describe the basic characteristics of an entrepreneurial venture',
    recentBusinessFinancialAnalysis: '• Descriptively enter the financial analysis and support it with indicators',
    potentialDevelopmentRating: '• List the proven business skills of the entrepreneur\n' +
      '• Describe and calculate all the elements of a business venture that are included\n' +
      ' with the ultimate goal of increasing productivity, competitiveness and competence on the market\n' +
      '• evaluate business development opportunities',
    entrepreneurCurrentAssets: '• Describe the existing assets with which the project is entered',
    marketAnalysis: '• Provide secondary data about the product, customers, competition\n' +
                    '• Do market segmentation and analysis',
    procurementMarket: '• Describe the situation and trends in the procurement market, as well as market factors\n' +
                        ' which directly or indirectly affect procurement conditions (suppliers, procurement items,\n' +
                        ' prices, procurement routes, environmental requirements, transport, customs, taxes, etc.)',
    salesMarket: '• Describe the sales market related to the project\n' +
                 '• Provide secondary data on the target market, size of the target market, geographical distribution, etc.\n' +
                 '• Provide information about the product, consumer group, price and competition commentary',
    marketAnalysisSummary: '• Describe and analyze the external environment (market)\n ' +
                            '• Set the goals of the marketing strategy of the product (project)\n' +
                            '• Describe the assessment of income realization',
    employeesDynamicAndStructure: '• Describe how many people are employed on the project, what are their qualifications, professional training\n' +
                                  '• Describe additional employment needs and employment dynamics in future years',
    wantedStaffAnalysis: '• Describe how many people are employed on the project, what are their qualifications,\n' +
      ' vocational training, additional employment, dynamics over the years of how many people will be employed in the following years',
    yearSalaryBudget: '• Describe the trend in the movement of workers and gross wages by year\n',
    techInvestmentElements: '• It is fulfilled if the project has the specified features necessary for implementation\n' +
                            '  (if it is not necessary, then it is not necessary to fill it in, and the technical elements\n' +
                            '  of the investment will not be visible when printing the document)\n' +
                            '• Describe the planned investment, the purpose of the investment, the location',
    techProcess: '• Make an overview of the state of the art\n' +
                 '• Select the technology and describe the technological process\n',
    techMaterialsAndEnergy: '• Specify the consumption framework of raw materials, materials and energy\n' +
                            '  which will be used for a specific product that is to be realized\n' +
                            '• Describe the raw materials, materials and energy sources that are available in stock\n' +
                            '• Describe the costs that will arise during the realization of the product, how many materials and energy sources will be consumed',
    techInvestmentStructure: '• Describe the development strategy of the technical-technological process \n' +
                             ' (in what way will raw materials, material, energy sources,\n' +
                             ' organization of tasks, distribution of working time and necessary costs for more efficient implementation)',
    buildingsCharacteristics: '• State and describe the location of the real estate and the accompanying infrastructure in the area\n' +
                              '• Is there a building permit and all the necessary documentation for construction',
    techCapacity: '• Describe the planned capacity and production structure\n' +
                  '• Describe the planned income (from which it will be generated)',
    location: '• Brief description of the location of the project area',
    locationAnalysis: '• Describe the selected location.\n' +
                      '• State the legal basis for the use and describe it (ownership, lease or something else).\n' +
                      '• Make sure you are required to have an environmental impact study. If so, check who is authorized to make it.\n' +
                      '• Make sure you are required to obtain permits to operate at your chosen location.\n'+
                      '  If so, check which institution is responsible for issuing it.\n' +
                      '• Make sure you are required to carry out certain energy efficiency activities.\n',
    environmentProtection: '• This part is filled in if the investment study or project has an impact on the environment\n' +
                           '• State what kind of production it is and to what extent the project has an impact on the environment\n' +
                           '• Display all environmental impact data',
    environmentEffects: '• Describe whether the project has positive impacts on the environment\n' +
                        '• State the measures that the investor will undertake to preserve the environment\n' +
                        '• Give examples of the implementation of environmental protection measures for the easier realization of an investment study or project\n' +
                        '• Specify the legal framework to which the project is subject in accordance with the Law',
    investmentRealization: '• Describe the duration of the realization of the project/investment (it can be one, two, three years...)\n' +
                           ' that is, the estimated time when the entire project will be completed and put into operation',
    workDynamics: '• Describe the feasibility of the project and the feasibility of the work, that is, include\n' +
                  ' all economic, legal, technological, locational, organizational, time and other components\n' +
                  ' which must be entered as the reason why the project/investment study should be implemented',
    marketingPlan: '• Describe the marketing activities that are planned\n' +
                   '  to be used in the realization of the project/investment study',
    marketingActivitiesPlan: '• Define the direction of marketing activities and the focus of employees on activities\n' +
                             '  necessary for the success of the product brand and/or the business entity itself.\n' +
                             '• To define strategies that would attract new customers, as well as keep the existing ones\n' +
                             '• Define the market, consumers, competitors, overlook the coming changes\n' +
                             '• Define internal and external factors and elements of the environment in which the business entity operates',
    economicFinancialAnalysis: '• Write in general how much money is planned to be invested in the project in order to start the project\n' +
                               '• Indicate whether it is an existing company or a new one\n' +
                               '• State the share of own and other people\'s funds\n' +
                               '• State the positive effects of investment in the project, in how many years the return on investment is expected',
    fixedAssetsEstimateInvestment: '• State how much funds are planned to be invested in the acquisition of fixed assets\n' +
                                   '• State what is planned to be acquired from fixed assets and describe the purpose of the assets that are planned to be acquired',
    currentAssetsEstimateInvestment: '• Specify the amount that is planned to be invested in short-term assets\n' +
                                     '• List the categories of short-term assets that are planned to be financed\n' +
                                     '  (e.g. energy costs, office supplies, consulting services, etc.)',
    sumEstimateInvestment: '• Describe the fixed assets that are acquired\n' +
                           '• Define the amount of necessary permanent working capital (TOS)\n' +
                           '• State which resources we already own or invest them possibly as business assets',
    financeSourcesAndCredit: '• State the amount and dynamics of investment funds\n' +
                             '• Indicate sources of funding (what amount of funds is planned to be financed\n' +
                             '  from grants, and how many will be financed with own or other people\'s funds)',
    creditCalculation: '• Specify the creditor\'s name, loan amount, interest rate, loan repayment period,\n' +
                       '  type of repayment (monthly, quarterly, annually)',
    amortization: '• Describe the chosen method of calculating depreciation\n' +
                  '• State the useful life of the assets and the depreciation rates',
    expensesBudgetAndPrices: '• Enter additional information about costs (what types of costs are\n' +
                             '  most represented in total costs, clarification, etc.)\n' +
                             '• Explain the trend of the movement of costs through the observed years',
    totalIncome: '• Describe which activity the company generates the largest share of revenues\n' +
                 '• Identify market opportunities and determine product demand\n' +
                 '• Explain the expected sales trend by year',
    totalExpense: '• State which are the most significant categories of expenditure (state the share in total expenditure)\n' +
                  '• Explain the trend of the movement of costs through the observed years',
    profitAndLoss: '• Explain the obtained results about the achieved profit or loss of the project\n' +
                   '• Analyze possible losses in the initial years of the project and justify them',
    currentAssetsProjection: '• State which type of working capital is the most represented in financing and clarify\n' +
                             '• Indicate from which sources most of the working capital needs are financed\n' +
                             '• Analyze binding days\n' +
                             '• Analyze the availability of working capital through the observed years',
    financialFlow: '• Evaluate the efficiency of the project with regard to the results of the financial flow\n' +
                   '• Assess the liquidity of the project (analyze the receipts and sources and expenditures of the project)\n' +
                   '• It is desirable that the net receipts be positive in all periods shown',
    economicFlow: '• Analyze the sustainability of the project (whether receipts in all periods are greater than expenditures)\n' +
                  '• Analyze individual items from the table that significantly affect the amount of net receipts',
    balanceProjection: '• Analyze the structure of assets and the structure of financing sources\n' +
                       '• List the most significant positions of assets and capital and liabilities, explain the trend of the movement of the positions\n' +
                       '• Analyze changes in the most significant balance sheet positions throughout the observed years',
    marketRating: '• Analyze the impact of the project on the economic development of the company\n' +
                  '• Analyze the impact of the project on the company\'s profit',
    staticEfficiencyRating: '• Analyze the efficiency of the project with regard to the positions of the balance sheet and the profit and loss account\n' +
                            '• Analyze the obtained results of financial indicators',
    dynamicRating: '• Describe the obtained results of all four methods in the dynamic evaluation of the project\n' +
                   '• Based on the obtained results of the presented methods, give a dynamic evaluation of the project',
    roiMethod: '• Based on the obtained result of the method, write how much time is\n' +
               '  it is necessary to return the total funds invested in the project',
    netValueMethod: '• Based on the obtained result, give an assessment of acceptability for realization',
    relativeValueMethod: '• Based on the obtained result, evaluate the acceptability of the project\n',
    internalProfitabilityMethod: '• Based on the obtained result, evaluate whether the project is profitable\n' +
                                 '• Comment on the project acceptability threshold',
    sensitivityAnalysis: '• Detect risks that affect business success and strategies to reduce/avoid said risk\n' +
                         '• Detect parts of the business that are particularly sensitive to changes',
    projectSensitivityAnalysis: '• State the specific assumptions on which the sensitivity analysis for the specified project is based\n' +
                                '• Comment on the calculation of the sensitivity of the project when changing some of the variables\n' +
                                '• Give a conclusion regarding the sensitivity and riskiness of the project',
    conclusion: '• List the most important information related to the project we are working on\n' +
                '  (the information must not appear for the first time but must be listed in the previous tables)',
    finalRating: '• Highlight the most important information provided in the previous sections\n' +
                 '• Describe 4 sections on the feasibility of the project, conclusion on the technological feasibility of the project,\n' +
                 ' conclusion on the marketing feasibility of the project, conclusion on the financial feasibility of the project,\n' +
                 ' and the overall evaluation of the project and recommendations for the implementation of the project',
    //todo: add english
    liquidityIndicators:
      'Likvidnost poslovanja predstavlja sposobnost trgovačkog društva za pravodobno namirenje obveza. U skladu s time pokazatelji likvidnosti pokazuju koliko je društvo sposobno podmirivati svoje dospjele kratkoročne obveze. Uz pokazatelje zaduženosti spadaju u grupu pokazatelja koji nas informiraju o sigurnosti poslovanja društva.',
    currentRatio:
      '<b>Koeficijent tekuće likvidnosti = Kratkotrajna imovina / Kratkoročne obveze</b>\n' +
      'Koeficijent tekuće likvidnosti je odnos između obrtnih sredstava i kratkoročnih obveza prema izvorima.\n' +
      'Trebao bi biti 2 ili više, to znači da poduzeće ima dvostruko više gotovine, potraživanja i zaliha nego što\n' +
      'su kratkoročne obveze koje dolaze na naplatu.',
    quickRatio:
      '<b>Koeficijent ubrzane likvidnosti = (Kratkotrajna imovina - Zalihe) / Ukupne kratkoročne obveze</b> \n'+
      'Pokazuje ima li poduzeće dovoljno kratkoročnih sredstava da podmiri dospjele obveze a bez prodaje zaliha. Poželjna vrijednost iznosi 1, a preporučena minimalna vrijednost je 0.9.',
    cashLiquidity:
      '<b>Koeficijent trenutne likvidnosti = Novac / Kratkoročne obveze </b>\n'+
      'Koeficijent trenutne likvidnosti ukazuje na sposobnost poduzeća za trenutačno podmirenje obveza. Njegova vrijednost ne bi trebala biti ispod 0.1',
    financialStabilityRatio:
      '<b>Koeficijent financijske stabilnosti = Dugotrajna imovina / (Kapital i Rezerve + Rezerviranja + Dugoročne obveze) </b>\n' +
      'Što je vrijednost ovog pokazatelja manja, to je likvidnost i financijska stabilnost veća, tj. povećava se učešće radnog kapitala. Trebao bi biti manji od 1.',
    debtIndicators: 'Pokazatelji zaduženosti pokazuju odnos zaduženosti i udjela vlastitog kapitala u poslovanju društva. Oni mjere koliko se društvo financira iz tuđih sredstava, odnosno koliko je imovine financirano iz vlastitog kapitala, a koliko iz tuđeg kapitala. Uz pokazatelje linvidnosti spadaju u grupu pokazatelja koji nas informiraju o sigurnosti poslovanja društva.',
    debtRatio:
      '<b>Koeficijent zaduženosti = Ukupne obveze/Ukupna imovina</b>\n'+
      'Prikazuje do koje mjere društvo koristi zaduženje kao izvor financiranja odnosno koji je postotak imovine nabavljen zaduživanjem. Ova vrijednost bi u pravilu trebala biti manja od 0.5',
    equityRatio:
      '<b>Koeficijent zaduženosti = Vlasnička glavnica/Ukupna imovina</b>\n'+
      'Koeficijent vlastitog financiranja prikazuje udio vlastitih sredstava u ukupnoj imovini. Ova vrijednost bi trebali biti što veća.',
    financialCoefficient:
      '<b>Koeficijent financiranja = Ukupne obveze/Vlasnička glavnica</b>\n'+
      'Koeficijent financiranja pokazuje odnos ukupnih obveza i glavnice. Visoka vrijednost ovog pokazatelja ukazuje na moguće poteškoće pri vraćanju posuđenih sredstava i plaćanju kamata. ',
    debtFactor:
      '<b>Faktor zaduženosti = Ukupne obveze/(Zadržana dobit + Troškovi amortizacije)</b>\n'+
      'Faktor zaduženosti je indikator koji daje informaciju o tome koliko je godina potrebno da bi se iz zadržane dobiti i amortizacije pokrile ukupne obveze. Što je ovaj pokazatelj veći veća je i zaduženost.',
    financialAssetsOwnSourcesCover:
      '<b>Stupanj pokrića imovine vlastitim izvorima = ((Kapital i Rezerve + Rezerviranja) / Dugotrajna imovina) * 100</b>\n'+
      'Pokazuje u kojoj mjeri je imovina pokrivena vlastitim izvorima. Referentna vrijednost je 1. U slučaju da je veća od 1, značilo bi da se uz vlastite, koriste i tuđi izvori financiranja.',
    financialAssetsLongTermSourcesCover:
      '<b>Stupanj pokrića imovine dugoročnim izvorima = ((Kapital i rezerve + Rezerviranja + Dugoročne obveze) / Dugotrajna imovina) * 100</b>\n'+
      ' Referentna vrijednost ovog pokazatelja jest 1, odnosno vrijednosti manje od 1 odražavaju financijsku stabilnost, dok vrijednosti veće od 1 odražavaju financijski nestabilno poslovanje.',
    activityIndicators: 'Pokazatelji aktivnosti mjere koliko je društvo aktivno, odnosno koliko učinkovito upotrebljava svoje resurse te kojom brzinom imovina cirkulira u poslovnom procesu. Ukazuju na sigurnost i uspješnost poslovanja.',
    salesIncomeAssetTurnover:
      '<b>Koeficijent obrtaja aktive na temelju prihoda od prodaje = Poslovni prihod / Aktiva</b>\n'+
      'Koeficijent obrtaja aktive na temelju prihoda od prodaje pokazuje odnos između ostvarenih poslovnih prihoda i aktive.',
    sumIncomeAssetTurnover:
      '<b>Koeficijent obrtaja ukupne imovine = Ukupni prihod / Ukupna Imovina</b>\n'+
      'Koeficijent obrta ukupne imovine pokazuje odnos između ukupnih prihoda i ukupne imovine.',

    fleetingAssetsTurnover:
      '<b>Koeficijent obrtaja kratkotrajne imovine = Prihodi od prodaje/Kratkotrajna imovina(tekuća aktiva)</b>\n'+
      ' Koeficijent obrtaja kratkotrajne imovine pokazuje koliko je kratkotrajne imovine društva potrebno vezati da bi se ostvarila određena visina poslovnih prihoda.',
    shortTermClaimsPaymentTime:
      '<b>Trajanje naplate potraživanja u danima = Broj dana u godini(365) * (kratkotrajna potraživanja / poslovni prihod)</b>\n'+
      'Prikazuje prosječno vrijeme naplate potraživanja u danima. \n',
    suppliersPaymentDays:
      '<b>Dani plaćanja obveza prema dobavljačima = Broj dana u godini/Koeficijent obrta obveza*</b>\n'+
      'prosječno razdoblje plaćanja obveza koji nam pokazuje koliko je dana potrebno zapodmirivanje obveza. \n' +
      '*Koeficijent obrta obveza = Rashodi od prodaje / Obveze prema dobavljačima',
    inventoryBindingDays:
      '<b>Dani vezivanja zaliha = Broj dana u godini / Koeficijent obrtaja zaliha</b>\n'+
      '365 / Koeficijent obrtaja zaliha (Prihodi od prodaje / Stanje zaliha) ili\n' +
      'Zalihe x 365 / Troškovi prodanih proizvoda i roba + troškovi usluga',
    sumBusinessCostEffectiveness:
      '<b>Ekonomičnost ukupnog poslovanja = Ukupni prihodi / Ukupno rashodi</b>\n'+
      'Pokazuje odnos ukupnih prihoda i ukupnih rashoda poslovanja. U slučaju kada je vrijednost ovog pokazatelja veća od 1, poduzeće je ostvarilo neto dobit. U suprotnom, tj. ako je vrijednost ovog indikatora manja od 1, društvo je zabilježilo neto gubitak. \n'+
      'Ovaj pokazatelj smisleno je sagledavati u vremenskom kontekstu koji može otkriti poboljšanje ukupnog poslovanja (u slučaju rasta ekonomičnosti ukupnog poslovanja), kao i u odnosu na prosjek industrije i konkurentska poduzeća jer marže među različitim djelatnostima znatno variraju.',
    regularBusinessCostEffectiveness:
      '<b>Ekonomičnost redovnog poslovanja = Ukupni redovni prihodi / Ukupni redovni rashodi</b>\n'+
      'Pokazuje odnos redovnih prihoda i redovnih rashoda poslovanja.',
    grossCirculationRentability:
      '<b>Bruto profitna marža = Dobit ili gubitak prije oporezivanja / Ukupni prihod * 100</b>\n'+
      'Što je veći postotak, to će više novca društvo zadržati na svakoj novčanoj jedinici koji ulazi u prodaju, kako bi platilo svoje ostale troškove i obveze. Bruto profitne marže uvelike ovise kojoj gospodarskoj grani društvo pripada.',
    netCirculationRentability:
      '<b>Neto profitna marža = Dobit ili gubitak razdoblja / Ukupni prihod * 100</b>\n'+
      'Pokazuje postotak preostalog prihoda nakon oduzimanja operativnih troškova, kamata, poreza i povlaštene dividende.',
    grossSumAssets:
      '<b>Ukupna imovina = (Dobit ili gubitak prije oporezivanja / Ukupna aktiva) * 100</b>\n'+
      'Pokazuje udio bruto dobiti i rashoda po jedinici vrijednosti ukupne imovine.',
    sumAssetsRentability:
      '<b>Neto rentabilnost ukupe imovine(ROA) = Dobit nakon oporezivanja / Ukupna imovina * 100</b>\n'+
      'Ovaj pokazatelj je indikator uspješnosti korištenja imovine u stvaranju dobitka tj. prikazuje intenzivnost imovine. Prikazuje u kojoj će mjeri ulaganje u imovinu rezultirati dobitkom.',
    ownCapitalRentability:
      '<b>Rentabilnost vlastitog kapitala(ROE) = (Dobit ili gubitak razdoblja / kapital i rezerve) * 100</b>\n'+
      'Jedan od nabitnijih pokazatelja koji pokazuje koliko novčanih jedinica dobitka društvo ostvaruje na jednu jedinicu vlastitog kapitala.',
    ebitMargin:
      '<b>EBIT marža = ((Poslovni prihodi - Poslovni rashodi) / Poslovni prihodi) * 100</b>\n'+
      'Prikazuje udio dobiti prije kamata i poreza u prihodima društva.',
    ebitOperativeResult:
      '<b>EBIT - Operativni rezultat = Poslovni prihodi - poslovni rashodi</b>\n',
    ebitaMargin:
      '<b>EBITDA marža = (( Poslovni prihodi - Poslovni rashodi) + Amortizacija) / Ukupni prihodi * 100</b>\n'+
      'EBITDA pokazateljem se utvrđuje koliki postotak dobitka prije kamata, poreza i amortizacije ostaje gospodarskom subjektu po svakoj jedinici (kuni) poslovnih prihoda (EBITDA marža). Veća je vrijednost EBITDA marže povoljnija, jer takvim gospodarskim subjektima ostaje veći dio prihoda nakon pokrića poslovnih troškova u odnosu na one koji ostvaruju niže vrijednosti ovog pokazatelja.',

  },

  'hrv': {
    mainTitle: 'U naslovu radi potpune jasnoće svakako rabite i generičko i komercijalno nazivlje za proizvode i usluge.',
    entrepreneurDescription: '• Ukratko tekstualno opisati poduzetnika ili nositelja\n' +
                             ' (npr. kada je osnovan, tko je osnivač i u kojoj je funkciji,\n ' +
                             ' postoje li ostali suosnivači i vlasnici, koja je osnovna djelatnost i sl.)',
    entrepreneurInfo: '• Opišite postojeću pravnu osobu\n' +
                      '• Napravite projekciju za buduću pravnu osobu\n' +
                      '• Navedite podatke o ključnim osobama\n',
    entrepreneurBusinessSubject: '• Opišite osnovne karakteristike poduzetničkog pothvata',
    recentBusinessFinancialAnalysis: '• Opisno unijeti financijsku analizu  i potkrijepiti je pokazateljima',
    potentialDevelopmentRating: '• Navesti dokazane poslovne sposobnosti poduzetnika\n' +
                                '• Opisati i izračunati sve elemente poslovnog pothvata koji se uključuju\n' +
                                '  u konačni cilj povećanja produktivnosti, konkurentnosti i kompetentnosti na tržištu\n' +
                                '• ocijeniti razvojne mogućnosti poslovanja',
    entrepreneurCurrentAssets: '• Opisati postojeću imovinu s kojom se ulazi u projekt',
    marketAnalysis: '• Navesti sekundarne podatke o proizvodu, kupcima, konkurenciji\n' +
                    '• Napraviti segmentaciju i analizu tržišta',
    procurementMarket: '• Opisati stanje i kretanje na tržištu nabave, kao i o tržišnim čimbenicima\n' +
                       '  koji izravno, ili neizravno utječu na uvjete nabavljanja (dobavljači, predmeti nabave,\n' +
                       '  cijene, putovi nabave, ekološki zahtjevi, transport, carina, porezi i sl.) ',
    salesMarket: '• Opisati tržište prodaje koje se odnosi na projekt\n' +
                 '• Navesti sekundarne podatke o ciljanom tržištu, veličini ciljanog  tržišta, geografskoj rasprostranjenosti isl.\n' +
                 '• Navesti informacije o proizvodu, grupi potrošača, komentar cijena i konkurencije',
    marketAnalysisSummary: '• Opisati i analizirati vanjsko okruženje (tržište)\n ' +
                           '• Postaviti ciljeve strategije plasiranja proizvoda (projekta)\n' +
                           '• Opisati procjenu ostvarenja prihoda',
    employeesDynamicAndStructure: '• Opisati koliko osoba je zaposleno na projektu, koje su njihove kvalifikacije, stručna sprema\n' +
                                  '• Opisati dodatne potrebe za zapošljavanjem te dinamiku zapošljavanja u budućim godinama',

    wantedStaffAnalysis: '• Opisati koliko osoba je zaposleno na projektu, koje su njihove kvalifikacije,\n' +
                         '  stručna sprema, dodatno zapošljavanje, dinamika kroz godine koliko će ljudi biti zaposleno u sljedećim godinama',
    yearSalaryBudget: '• Opisati trend kretanja radnika i bruto plaće po godinama',
    techInvestmentElements: '• Ispunjava se ukoliko u projektu ima navedenih značajki potrebnih za provedbu\n' +
                            '  (ukoliko nije potrebno tada nije nužno ispunjavati, te prilikom ispisa dokumenta\n' +
                            '  neće biti vidljivi tehnički elementi ulaganja)\n' +
                            '• Opisati planiranu investiciju, svrhu ulaganja, lokaciju',

    techProcess: '• Napravite pregled stanja tehnike\n' +
                 '• Odaberite tehnologiju i opišite tehnološki proces\n',
    techMaterialsAndEnergy: '• Navesti okvir utroška sirovina, materijala i energenata\n' +
                            '  koje će se koristiti za određeni proizvod koji se želi realizirati\n' +
                            '• Opisati sirovine, materijale i energente koji su raspoloživi na zalihama\n' +
                            '• Opisati troškove koji će nastati tijekom realizacije proizvoda, koliko će materijala i energenata biti potrošeno',
    techInvestmentStructure: '• Opisati strategiju razvoja tehničko-tehnološkog procesa \n' +
                             '  (na koji način će se koristiti sirovine, materijal, energenti,\n' +
                             '  organizacija zadataka, raspodjela radnog vremena te potrebni troškovi za učinkovitiju realizaciju)',

    buildingsCharacteristics: '• Navesti i opisati lokaciju nekretnine te prateću infrastrukturu u okolini\n' +
                              '• Da li postoji  građevinska dozvola i sva potrebna dokumentacija za izgradnju',
    techCapacity: '• Opisati planirani kapacitet i strukturu proizvodnje\n' +
                  '• Opisati planirane prihode (od čega će se ostvarivati)',
    location: '• Kratak opis lokacije prostora projekta',
    locationAnalysis: '• Opišite odabranu lokaciju.\n' +
                      '• Navedite koja je pravna osnova korištenja te je opišite (vlasništvo, zakup ili nešto treće).\n' +
                      '• Provjerite jeste li obvezni imati studiju utjecaja na okoliš. Ako jeste, provjerite tko je ovlašten za izradu iste.\n' +
                      '• Provjerite jeste li obvezni ishoditi dopuštenja za obavljanje djelatnosti na odabranoj lokaciji.\n'+
                      '  Ako jeste, provjerite koja je institucija nadležna za njegovo izdavanje.\n' +
                      '• Provjerite jeste li obvezni provoditi određene aktivnosti vezane uz energetsku učinkovitost.\n',
    environmentProtection: '• Ovaj dio se popunjava ako investicijska studija ili projekt ima utjecaja na okoliš\n' +
                           '• Navesti o kakvoj se proizvodnji radi i u kojoj mjeri projekt ima utjecaja na okoliš\n' +
                           '• Prikazati sve podatke utjecaja na okoliš',
    environmentEffects: '• Opisati da li projekt ima pozitivnih utjecaja na okoliš\n' +
                        '• Navesti mjere koje će investitor poduzimati za očuvanje okoliša\n' +
                        '• Navesti primjere provođenja mjere zaštite okoliša zbog lakše realizacije investicijske studije ili projekta\n' +
                        '• Navesti zakonski okvir kojem projekt podliježe sukladno Zakonu',
    investmentRealization: '• Opisati razdoblje trajanja  realizacije projekta/investicije (može biti jedna, dvije, tri godine…)\n' +
                           '  odnosno predviđeno vrijeme kada će cijeli projekt biti dovršen i pušten u rad',
    workDynamics: '• Opisati izvodljivost projekta i izvodljivosti rada, odnosno obuhvatiti\n' +
                  '  sve ekonomske, pravne, tehnološke, lokacijske, organizacijske, vremenske i ostale komponente\n' +
                  '  koje je potrebno upisati kao razlog zašto projekt/investicijsku studiju treba realizirati',
    marketingPlan: '• Opisati marketinške aktivnosti koje se planiraju\n' +
                   '  koristiti u realizaciji projekta/investicijskoj studiji',
    marketingActivitiesPlan: '• Definirati smjer marketinških djelovanja i fokus zaposlenih na aktivnosti\n' +
                             '  neophodne za uspjeh marke proizvoda i/ili samog poslovnog subjekta.\n' +
                             '• Definirati strategije kojom bi se privukli novi kupci, kao i zadržali postojeći\n' +
                             '• Definirati tržište, potrošače, konkurente, previđati dolazeće promjene\n' +
                             '• Definirati unutarnje i vanjske čimbenike i elemente okruženja u kojem poslovni subjekt djeluje',
    economicFinancialAnalysis: '• Općenito napisati koliki iznos sredstava se planira uložiti u projekt da bi projekt počeo\n' +
                               '• Navesti da li se radi o postojećem poduzeću ili o novom\n' +
                               '• Navesti udio vlastitih i tuđih sredstava\n' +
                               '• Navesti pozitivne efekte ulaganja u projekt, za koliko godina se očekuje povrat ulaganja',
    fixedAssetsEstimateInvestment: '• Navesti koliko sredstava se planira uložiti u nabavku dugotrajne imovine\n' +
                                   '• Navesti što se planira nabaviti od dugotrajne imovine i opisati namjenu imovine koja se planira nabaviti',
    currentAssetsEstimateInvestment: '• Navesti iznos koji se planira uložiti u kratkotrajnu imovinu\n' +
                                     '• Navesti kategorije kratkotrajne imovine koje se planiraju financirati\n' +
                                     '  (npr. troškovi energenata, kancelarijski materijali, konzultantske usluge i sl.)',
    sumEstimateInvestment: '• Opisati osnovna sredstva koja se nabavljaju\n' +
                           '• Definirati iznos potrebnih trajnih obrtnih sredstava (TOS)\n' +
                           '• Navesti koje resurse već posjedujemo ili ih ulažemo eventualno kao poslovnu imovinu',
    financeSourcesAndCredit: '• Navesti iznos i dinamiku ulaganja investicijskih sredstava\n' +
                             '• Navesti izvore financiranja (koliki iznos sredstava se planira financirati\n' +
                             '  iz bespovratnih sredstava, a koliki će se financirati sa vlastitim ili tuđim sredstvima)',
    creditCalculation: '• Navesti ime kreditora, iznos kredita, kamatnu stopu, rok povrata kredita,\n' +
                       '  vrsta otplate (mjesečno, kvartalno, godišnje)',
    amortization: '• Opisati izabranu metodu obračuna amortizacije\n' +
                  '• Navesti vijek trajanja sredstava i amortizacijske stope',
    expensesBudgetAndPrices: '• Unijeti dodatne informacije o troškovima (koja vrste troškova su\n' +
                             '  najzastupljenije u ukupnim troškovima, pojašnjenje i sl.)\n' +
                             '• Pojasniti trend kretanja troškova kroz promatrane godine',
    totalIncome: '• Opisati kojom djelatnošću poduzeće generira najveći udio u prihodima\n' +
                 '• Navesti prilike na tržištu i odrediti potražnju za proizvodom\n' +
                 '• Obrazložiti očekivani trend kretanja prodaje po godinama',
    totalExpense: '• Navesti koje su najznačajnije kategorije rashoda (navesti udio u ukupnim rashodima)\n' +
                  '• Pojasniti trend kretanja troškova kroz promatrane godine',
    profitAndLoss: '• Obrazložiti dobivene rezultate o ostvarenom dobitku ili gubitku projekta\n' +
                   '• Analizirati eventualne gubitke u početnim godinama projekta i opravdati ih',
    currentAssetsProjection: '• Navesti koja vrsta obrtnih sredstava je najzastupljenija u financiranju i pojasniti\n' +
                             '• Navesti iz kojih izvora se financira većina potreba za obrtnim sredstvima\n' +
                             '• Analizirati dane vezivanja\n' +
                             '• Analizirati raspoloživost obrtnih sredstava kroz promatrane godine',
    financialFlow: '• Ocijeniti efikasnost projekta s obzirom na rezultate financijskog tijeka\n' +
                   '• Ocijeniti likvidnost projekta (analizirati primitke i izvore i izdatke projekta)\n' +
                   '• Poželjno je da u svim prikazanim razdobljima neto primici budu pozitivni',
    economicFlow: '• Analizirati održivost projekta (da li su primici u svim razdobljima veći od izdataka)\n' +
                  '• Analizirati pojedine stavke iz tablice koje na značajan način utječu na iznos neto primitaka',
    balanceProjection: '• Analizirati strukturu imovine i strukturu izvora financiranja\n' +
                       '• Navesti najznačajnije pozicije imovine i kapitala i obveza, obrazložiti trend kretanja navedenih pozicija\n' +
                       '• Analizirati promjene najznačajnijih pozicija bilance kroz promatrane godine',
    marketRating: '• Analizirati utjecaj projekta na ekonomski razvoj poduzeća\n' +
                  '• Analizirati utjecaj projekta na dobit poduzeća',
    staticEfficiencyRating: '• Analizirati efikasnost projekta s obzirom na pozicije bilance i računa dobiti i gubitka\n' +
                            '• Analizirati dobivene rezultate financijskih pokazatelja',
    dynamicRating: '• Opisati dobivene rezultate svih četiri metoda u dinamičkoj ocjeni projekta\n' +
                   '• Temeljem dobivenih rezultata prikazanih metoda dati dinamičku ocjenu projekta',
    roiMethod: '• Temeljem dobivenog rezultata metode napisati koliko je vremena\n' +
               '  potrebno da se vrate ukupno uložena sredstva u projekt',
    netValueMethod: '• Temeljem dobivenog rezultata dati ocjenu prihvatljivosti za realizaciju',
    relativeValueMethod: '• Temeljem dobivenog rezultata ocijeniti prihvatljivost projekta',
    internalProfitabilityMethod: '• Temeljem dobivenog rezultata ocijeniti da li je projekt profitabilan\n' +
                                 '• Komentirati prag prihvatljivost projekta',
    sensitivityAnalysis: '• Detektirati rizike koji utječu na poslovni uspjeh i strategije kako bi se navedeni rizik umanjio/izbjegao\n' +
                         '• Detektirati dijelove poslovanja koji su posebno osjetljivi na promjene',
    projectSensitivityAnalysis: '• Navesti konkretne pretpostavke na kojima se temelji analiza osjetljivosti za navedeni projekt\n' +
                                '• Komentirati izračun osjetljivosti projekta pri promjeni neke od varijabli\n' +
                                '• Dati zaključak vezano uz osjetljivost i rizičnost projekta',
    conclusion: '• Navesti najvažnije informacije vezane za projekt na kojemu radimo\n' +
                '  (informacije se ne smiju pojavljivati po prvi puta već moraju biti navedene u prethodnim tablicama)',
    finalRating: '• Istaknuti najvažnije informacije koje su navedene u prethodnim sekcijama\n' +
                 '• Opisati 4 sekcije o izvodljivosti projekta, zaključak o tehnološkoj izvodljivosti projekta,\n' +
                 '  zaključak o marketinškoj izvodljivosti projekta, zaključak o financijskoj izvodljivosti projekta,\n' +
                 '  te ukupnu ocjenu projekta i preporuke za realizaciju projekta',
    //todo: add english
    liquidityIndicators:
      'Likvidnost poslovanja predstavlja sposobnost trgovačkog društva za pravodobno namirenje obveza. U skladu s time pokazatelji likvidnosti pokazuju koliko je društvo sposobno podmirivati svoje dospjele kratkoročne obveze. Uz pokazatelje zaduženosti spadaju u grupu pokazatelja koji nas informiraju o sigurnosti poslovanja društva.',
    currentRatio:
      '<b>Koeficijent tekuće likvidnosti = Kratkotrajna imovina / Kratkoročne obveze</b>\n' +
      'Koeficijent tekuće likvidnosti je odnos između obrtnih sredstava i kratkoročnih obveza prema izvorima.\n' +
      'Trebao bi biti 2 ili više, to znači da poduzeće ima dvostruko više gotovine, potraživanja i zaliha nego što\n' +
      'su kratkoročne obveze koje dolaze na naplatu.',
    quickRatio:
      '<b>Koeficijent ubrzane likvidnosti = (Kratkotrajna imovina - Zalihe) / Ukupne kratkoročne obveze</b> \n'+
      'Pokazuje ima li poduzeće dovoljno kratkoročnih sredstava da podmiri dospjele obveze a bez prodaje zaliha. Poželjna vrijednost iznosi 1, a preporučena minimalna vrijednost je 0.9.',
    cashLiquidity:
      '<b>Koeficijent trenutne likvidnosti = Novac / Kratkoročne obveze </b>\n'+
      'Koeficijent trenutne likvidnosti ukazuje na sposobnost poduzeća za trenutačno podmirenje obveza. Njegova vrijednost ne bi trebala biti ispod 0.1',
    financialStabilityRatio:
      '<b>Koeficijent financijske stabilnosti = Dugotrajna imovina / (Kapital i Rezerve + Rezerviranja + Dugoročne obveze) </b>\n' +
      'Što je vrijednost ovog pokazatelja manja, to je likvidnost i financijska stabilnost veća, tj. povećava se učešće radnog kapitala. Trebao bi biti manji od 1.',
    debtIndicators: 'Pokazatelji zaduženosti pokazuju odnos zaduženosti i udjela vlastitog kapitala u poslovanju društva. Oni mjere koliko se društvo financira iz tuđih sredstava, odnosno koliko je imovine financirano iz vlastitog kapitala, a koliko iz tuđeg kapitala. Uz pokazatelje linvidnosti spadaju u grupu pokazatelja koji nas informiraju o sigurnosti poslovanja društva.',
    debtRatio:
      '<b>Koeficijent zaduženosti = Ukupne obveze/Ukupna imovina</b>\n'+
      'Prikazuje do koje mjere društvo koristi zaduženje kao izvor financiranja odnosno koji je postotak imovine nabavljen zaduživanjem. Ova vrijednost bi u pravilu trebala biti manja od 0.5',
    equityRatio:
      '<b>Koeficijent zaduženosti = Vlasnička glavnica/Ukupna imovina</b>\n'+
      'Koeficijent vlastitog financiranja prikazuje udio vlastitih sredstava u ukupnoj imovini. Ova vrijednost bi trebali biti što veća.',
    financialCoefficient:
      '<b>Koeficijent financiranja = Ukupne obveze/Vlasnička glavnica</b>\n'+
      'Koeficijent financiranja pokazuje odnos ukupnih obveza i glavnice. Visoka vrijednost ovog pokazatelja ukazuje na moguće poteškoće pri vraćanju posuđenih sredstava i plaćanju kamata. ',
    debtFactor:
      '<b>Faktor zaduženosti = Ukupne obveze/(Zadržana dobit + Troškovi amortizacije)</b>\n'+
      'Faktor zaduženosti je indikator koji daje informaciju o tome koliko je godina potrebno da bi se iz zadržane dobiti i amortizacije pokrile ukupne obveze. Što je ovaj pokazatelj veći veća je i zaduženost.',
    financialAssetsOwnSourcesCover:
      '<b>Stupanj pokrića imovine vlastitim izvorima = ((Kapital i Rezerve + Rezerviranja) / Dugotrajna imovina) * 100</b>\n'+
      'Pokazuje u kojoj mjeri je imovina pokrivena vlastitim izvorima. Referentna vrijednost je 1. U slučaju da je veća od 1, značilo bi da se uz vlastite, koriste i tuđi izvori financiranja.',
    financialAssetsLongTermSourcesCover:
      '<b>Stupanj pokrića imovine dugoročnim izvorima = ((Kapital i Rezerve + Rezerviranja + Dugoročne obveze) / Dugotrajna imovina) * 100</b>\n'+
      ' Referentna vrijednost ovog pokazatelja jest 1, odnosno vrijednosti manje od 1 odražavaju financijsku stabilnost, dok vrijednosti veće od 1 odražavaju financijski nestabilno poslovanje.',

  //  ----------------------------------------
    activityIndicators: 'Pokazatelji aktivnosti mjere koliko je društvo aktivno, odnosno koliko učinkovito upotrebljava svoje resurse te kojom brzinom imovina cirkulira u poslovnom procesu. Ukazuju na sigurnost i uspješnost poslovanja.',
    salesIncomeAssetTurnover:
      '<b>Koeficijent obrtaja aktive na temelju prihoda od prodaje = Poslovni prihod / Aktiva</b>\n'+
      'Koeficijent obrtaja aktive na temelju prihoda od prodaje pokazuje odnos između ostvarenih poslovnih prihoda i aktive.',
    sumIncomeAssetTurnover:
      '<b>Koeficijent obrtaja ukupne imovine = Ukupni prihod / Ukupna Imovina</b>\n'+
      'Koeficijent obrta ukupne imovine pokazuje odnos između ukupnih prihoda i ukupne imovine.',

    fleetingAssetsTurnover:
      '<b>Koeficijent obrtaja kratkotrajne imovine = Prihodi od prodaje/Kratkotrajna imovina(tekuća aktiva)</b>\n'+
      ' Koeficijent obrtaja kratkotrajne imovine pokazuje koliko je kratkotrajne imovine društva potrebno vezati da bi se ostvarila određena visina poslovnih prihoda.',
    shortTermClaimsPaymentTime:
      '<b>Trajanje naplate potraživanja u danima = Broj dana u godini(365) * (kratkotrajna potraživanja / poslovni prihod)</b>\n'+
      'Prikazuje prosječno vrijeme naplate potraživanja u danima. \n',
    suppliersPaymentDays:
      '<b>Dani plaćanja obveza prema dobavljačima = Broj dana u godini/Koeficijent obrta obveza*</b>\n'+
      'prosječno razdoblje plaćanja obveza koji nam pokazuje koliko je dana potrebno zapodmirivanje obveza. \n' +
      '*Koeficijent obrta obveza = Rashodi od prodaje / Obveze prema dobavljačima',
    inventoryBindingDays:
      '<b>Dani vezivanja zaliha = Broj dana u godini / Koeficijent obrtaja zaliha</b>\n'+
      '365/koeficijent obrtaja zaliha (prihodi od prodaje / stanje zaliha) ili\n' +
      'Zalihe x 365 / Troškovi prodanih proizvoda i roba + troškovi usluga',
    successIndicators: 'Pokazatelji profitabilnosti mjere uspjeh ostvarivanja zarade kod društva. Koriste se kao indikatori upravljačke odgovornosti, kao metoda za predviđanje financijskog rezultata te kao mjeru sposobnosti društva da ostvari određenu stopu povrata ulaganja. Spadaju u grupu koja pokazuje uspješnost poslovanja.',
    sumBusinessCostEffectiveness:
      '<b>Ekonomičnost ukupnog poslovanja = Ukupni prihodi / Ukupno rashodi</b>\n'+
      'Pokazuje odnos ukupnih prihoda i ukupnih rashoda poslovanja. U slučaju kada je vrijednost ovog pokazatelja veća od 1, poduzeće je ostvarilo neto dobit. U suprotnom, tj. ako je vrijednost ovog indikatora manja od 1, društvo je zabilježilo neto gubitak. \n'+
    'Ovaj pokazatelj smisleno je sagledavati u vremenskom kontekstu koji može otkriti poboljšanje ukupnog poslovanja (u slučaju rasta ekonomičnosti ukupnog poslovanja), kao i u odnosu na prosjek industrije i konkurentska poduzeća jer marže među različitim djelatnostima znatno variraju.',
    regularBusinessCostEffectiveness:
      '<b>Ekonomičnost redovnog poslovanja = Ukupni redovni prihodi / Ukupni redovni rashodi</b>\n'+
      'Pokazuje odnos redovnih prihoda i redovnih rashoda poslovanja.',
    grossCirculationRentability:
      '<b>Bruto profitna marža = Dobit ili gubitak prije oporezivanja / Ukupni prihod * 100</b>\n'+
      'Što je veći postotak, to će više novca društvo zadržati na svakoj novčanoj jedinici koji ulazi u prodaju, kako bi platilo svoje ostale troškove i obveze. Bruto profitne marže uvelike ovise kojoj gospodarskoj grani društvo pripada.',
    netCirculationRentability:
      '<b>Neto profitna marža = Dobit ili gubitak razdoblja / Ukupni prihod * 100</b>\n'+
      'Pokazuje postotak preostalog prihoda nakon oduzimanja operativnih troškova, kamata, poreza i povlaštene dividende.',
    grossSumAssets:
      '<b>Ukupna imovina = (Dobit ili gubitak prije oporezivanja / Ukupna aktiva) * 100</b>\n'+
      'Pokazuje udio bruto dobiti i rashoda po jedinici vrijednosti ukupne imovine.',
    sumAssetsRentability:
      '<b>Neto rentabilnost ukupe imovine(ROA) = Dobit nakon oporezivanja / Ukupna imovina * 100</b>\n'+
      'Ovaj pokazatelj je indikator uspješnosti korištenja imovine u stvaranju dobitka tj. prikazuje intenzivnost imovine. Prikazuje u kojoj će mjeri ulaganje u imovinu rezultirati dobitkom.',
    ownCapitalRentability:
      '<b>Rentabilnost vlastitog kapitala(ROE) = (Dobit ili gubitak razdoblja / kapital i rezerve) * 100</b>\n'+
      'Jedan od nabitnijih pokazatelja koji pokazuje koliko novčanih jedinica dobitka društvo ostvaruje na jednu jedinicu vlastitog kapitala.',
    ebitMargin:
      '<b>EBIT marža = ((Poslovni prihodi - Poslovni rashodi) / Poslovni prihodi) * 100</b>\n'+
      'Prikazuje udio dobiti prije kamata i poreza u prihodima društva.',
    ebitOperativeResult:
      '<b>EBIT - Operativni rezultat = Poslovni prihodi - poslovni rashodi</b>\n',
    ebitaMargin:
      '<b>EBITDA marža = (( Poslovni prihodi - Poslovni rashodi) + Amortizacija) / Ukupni prihodi * 100</b>\n'+
      'EBITDA pokazateljem se utvrđuje koliki postotak dobitka prije kamata, poreza i amortizacije ostaje gospodarskom subjektu po svakoj jedinici (kuni) poslovnih prihoda (EBITDA marža). Veća je vrijednost EBITDA marže povoljnija, jer takvim gospodarskim subjektima ostaje veći dio prihoda nakon pokrića poslovnih troškova u odnosu na one koji ostvaruju niže vrijednosti ovog pokazatelja.',
    // applicantGeneralInformation: 'Opći podaci i Kontakt podaci koji su već zabilježeni u aplikaciji automatski su\n' +
    //   'učitani u odgovarajuća polja. Opći podaci o prijavitelju ne mogu se mijenjati \n' +
    //   'dok se ostali učitani podaci mogu mijenjati.\n'
  }
}
