import {environment} from '../../environments/environment';

export const FileUploadRoutes =  {
  upload: `${environment.baseUrl}/${environment.version}/media/upload`,
  get: {
    method: 'Get',
    url: `${environment.baseUrl}/${environment.version}/media/get` // /:id
  },
  delete: {
    method: 'Delete',
    url: `${environment.baseUrl}/${environment.version}/media/delete` // /:id
  }
};
