import {Balance} from './documents/balance-2018';
import {ProfitAndLoss} from './documents/profit-and-loss-2018';
import {Params} from '@angular/router';
import {RawImport, ImportDocumentName, RawImportYear} from './raw-import';
import {ImportValue} from './import-value';
import {ImportYear} from './import-year';
import {CashFlow} from './documents/cash-flow-2018';
import {ProfitAndLoss2021} from "./documents/profit-and-loss-2021";
import {Balance2021} from "./documents/balance-2021";
export interface SavedRawImportDocument {
  name: ImportDocumentName;
  values: ImportValue[];
  endDate: string;
}

export interface SavedRawImportYear {
  businessYear: number;
  importedDocuments: SavedRawImportDocument[];
}

export class ImportData {
   years: ImportYear[] = [];

  constructor(rawYears: SavedRawImportYear[]) {
    this.years = rawYears.map(rawYear => new ImportYear(rawYear));
  }

  getYear(businessYear: number): ImportYear | undefined {
    return this.years.find(year => year.businessYear === businessYear);
  }

  hasDocument(businessYear: number, documentName: ImportDocumentName): boolean {
    let year: ImportYear = this.getYear(businessYear);
    return !!(year && year[documentName]);
  }

  mergeDocument(importDocument: RawImport): void {
    let yearTwo = importDocument.endDate.split('.')[2]
    let yearThree: number = parseInt(yearTwo);
    importDocument.years.forEach(yearToImport => {
      let year: ImportYear = this.years.find(year => year.businessYear === yearToImport.businessYear);
      if (year) {
        if(importDocument.name === 'profitAndLoss') {
          if (yearThree <= 2020) {
            year.profitAndLoss = new ProfitAndLoss(yearToImport.importedValues);
          }
          else if (yearThree > 2020) {
            year.profitAndLoss = new ProfitAndLoss2021(yearToImport.importedValues);
          }
        } else if (importDocument.name === 'balance') {
          if (yearThree <= 2020) {
            year.balance = new Balance(yearToImport.importedValues);
          }
          else if (yearThree > 2020) {
            year.balance = new Balance2021(yearToImport.importedValues);
          }
        } else if (importDocument.name === 'cashFlow') {
          year.cashFlow = new CashFlow(yearToImport.importedValues);
        }
        year.endDate = importDocument.endDate
      } else {
        this.years.push(this.generateYearFromRawImport(yearToImport, importDocument.name, importDocument.endDate));
      }
    });
  }

  private generateYearFromRawImport(yearToImport: RawImportYear, docName: ImportDocumentName, endDate: string): ImportYear {
    return new ImportYear({
        businessYear: yearToImport.businessYear,
        importedDocuments: [
          {
            name: docName,
            values: yearToImport.importedValues,
            endDate: endDate,
          }
        ]
      });
  }

  getSaveData(): Params {
    return {
      years: this.years.map(year => year.getSaveData())
    };
  }

  removeDocument(documentName: ImportDocumentName): void {
    this.years.forEach(year => {
      year[documentName] = null;
    });
  }

}
