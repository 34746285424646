<div id="modal-primary" class="manage-assignees">
  <div class="modal-overlay"
       [class.electron]="_electronService.isElectronApp"
       [class.active]="_toggle.occupationModal.getValue()"
       (visible)="_toggle.occupationModal.getValue()">
    <div class="modal-container">
      <div class="modal-content" (appClickOutside)="onClickOutsideGlobalPrimaryModal($event)" [class.electron]="_electronService.isElectronApp">
        <div class="global-close-btn right-side">
          <i class="icon-remove" (click)="_toggle.occupationModal.next(false); clickOutsideCounterModal = 1;"></i>
        </div>
        <h4 class="form-name colored">{{_locale.occupationModal.manage}}</h4>
        <div class="modal-content-box">
          <form [formGroup]="form" class="" (submit)="occupation ? update(form.value) : create(form.value)">
            <div class="form-colors">

            </div>
            <div class="form-grid full">
              <div class="form-grid-full">
                <div class="form-flex-input">
                  <input
                    type="text"
                    [placeholder]="_locale.occupationModal.occupationNamePlaceholder"
                    formControlName="name"
                  >
                  <button class="form-button btn-blue" type="submit">
                    {{occupation ? _locale.occupationModal.save : _locale.occupationModal.add}}
                  </button>
                </div>
              </div>
            </div>
            <div class="form-grid full" *ngFor="let occupation of occupations; let i = index">
              <div class="form-grid-full">
                <div class="form-flex">
                  <div>
                    {{occupation.name}}
                  </div>
                  <div class="form-flex no-border">
                    <div class="remove" (click)="edit(occupation)">
                      <div class="icon-rounded clickable_link">
                        <i class="icon-edit"></i>
                      </div>
                    </div>
                    <div class="remove" (click)="delete(occupation)">
                      <div class="icon-rounded clickable_link">
                        <i class="icon-remove"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="form-grid full margin_top">
              <div class="form-grid-full">
                <button
                  class="form-button btn-blue"
                  type="button"
                  (click)="clear(true)"
                >
                  {{_locale.occupationModal.clear}}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
