export const ContactTypeTranslations = {
  'eng': {
    email: 'E-mail',
    'e-mail': 'Email',
    phone: 'Phone',
    mobile: 'Mobile',
    fax: 'Fax',
    skype: 'Skype'
  },
  'hrv': {
    email: 'Email',
    'e-mail': 'Email',
    phone: 'Telefon',
    mobile: 'Mobitel',
    fax: 'Fax',
    skype: 'Skype'
  }
};
