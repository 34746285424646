import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'toHumanReadable'
})
export class ToHumanReadablePipe implements PipeTransform {

  //Split word by split param then grab the last one and capitalize first letter of each word
  //Turn camel case to words divided by space

  transform(text:string, splitBy:string): string {
    let val = text.split(splitBy);
    return val[val.length - 1].match(/[A-Za-z][a-z]*/g).map((word) => word.charAt(0).toUpperCase() + word.substring(1)).join(' ');
  }

}
