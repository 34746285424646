export const ConfirmationDialogTranslations = {
  'eng': {
    header: 'Confirmation',
    message: 'Are you sure that you want to perform this action?',
    acceptlabel: 'Yes',
    rejectlabel: 'No'
  },
  'hrv': {
    header: 'Potvrda',
    message: 'Jeste li sigurni da želite izvršiti ovu radnju?',
    acceptlabel: 'Da',
    rejectlabel: 'Ne'
  }
};
