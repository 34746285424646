import { environment } from '../../environments/environment';
import {AdminRoutes} from '../modules/layout/admin/admin.routes';
import {CompanyRoutes} from '../modules/layout/company/company.routes';
import {UserRoutes} from '../modules/layout/user/user.routes';
import {AuthRoutes} from '../routes/auth.routes';
import {SettingsRoutes} from '../routes/settings.routes';
import {CommonDataRoutes} from '../routes/common-data.routes';
import {FileUploadRoutes} from '../routes/file-upload.routes';
import {StudyRoutes} from '../modules/layout/study/study.routes';
import {ProjectRoutes} from '../modules/layout/study/project.routes';

export const urlValues = {

  permissionCheck: `${environment.baseUrl}/api`, //used for checking permissions in global service
  base: environment.baseUrl, //Base url

  placeholderPath: (extension: string) => {
    return `assets/images/file-formats/${extension}.svg`;
  },

  auth: AuthRoutes,
  common: CommonDataRoutes,
  settings: SettingsRoutes,
  file: FileUploadRoutes,

  user: UserRoutes,
  company: CompanyRoutes,
  admin: AdminRoutes,
  study: StudyRoutes,
  project: ProjectRoutes,

  activity: {
    filter: {
      method: 'Post',
      url: `${environment.baseUrl}/api/${environment.version}/activity`
    }
  },

  //<editor-fold desc="Charts">
  charts: {
    method: 'Get',
    url: `${environment.baseUrl}/${environment.version}/widgets`
  },
  //</editor-fold>

  //<editor-fold desc="Custom">
  assign: {
    url: `${environment.baseUrl}/api/${environment.version}/user/assign`,
    method: 'Post'
  },
  //</editor-fold>
};
