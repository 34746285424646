export const HomeTranslations = {
  'eng': {
    mainTitle: 'Investment study: E-tool to support entrepreneurs',
    revenueMonthly: 'Revenue monthly',
    inboundVsOutgoingInv: 'Inbound vs outgoing invoices',
    invoicesByDueDate: 'Invoices by due date',
    totalDocuments: 'Total documents',
    topSellingProdServ: 'Top selling products & services',
    top6customers: 'Top 6 customers',
    revenue: 'Revenue',
    revenueWeekly: 'REVENUE WEEKLY',
    revenueDaily: 'REVENUE DAILY'
  },
  'hrv': {
    mainTitle: 'Investicijska studija: E-alat za potporu poduzetnicima',
    revenueMonthly: 'Mjesečni prihodi',
    inboundVsOutgoingInv: 'Ulazni i izlazni računi',
    invoicesByDueDate: 'Računi po datumu dospijeća',
    totalDocuments: 'Ukupno dokumenti',
    topSellingProdServ: 'Najprodavaniji proizvodi i usluge',
    top6customers: 'Top 6 kupaca',
    revenue: 'Prihod',
    revenueWeekly: 'TJEDNI PRIHOD',
    revenueDaily: 'DNEVNI PRIHOD'
  }
};
