import {environment} from '../../../../environments/environment';

export const UserRoutes = {
  get: {
    url: `${environment.baseUrl}/api/${environment.version}/user/get`,
    method: 'Get'
  },
  filter: {
    url: `${environment.baseUrl}/api/${environment.version}/user/filter`,
    method: 'Post'
  },
  create: {
    url: `${environment.baseUrl}/api/${environment.version}/user/create`,
    method: 'Post'
  },
  update: {
    url: `${environment.baseUrl}/api/${environment.version}/user/update`,
    method: 'Patch'
  },
  archive: {
    url: `${environment.baseUrl}/api/${environment.version}/user/delete`,
    method: 'Delete'
  },
  anonimyse:{
    url:`${environment.baseUrl}/api/${environment.version}/user/anonymise`, // :id
    method:'Post',
  },
  identify:{
    url:`${environment.baseUrl}/api/${environment.version}/user/identify`, // :id
    method:'Post',
  },
  restore: {
    url: `${environment.baseUrl}/api/${environment.version}/user/update`,
    method: 'Post'
  },
  permissions: {
    method: 'Get',
    url: `${environment.baseUrl}/api/${environment.version}/permissions`
  },
  documents: {
    method: 'Post',
    url: `${environment.baseUrl}/api/${environment.version}/document/singleUserDocument`
  },
  priceList: {
    filter: {
      method: 'Post',
      url: `${environment.baseUrl}/api/${environment.version}/user/priceList/filter`
    },
    create: {
      method: 'Post',
      url: `${environment.baseUrl}/api/${environment.version}/user/priceList/create`
    },
    update: {
      method: 'Patch',
      url: `${environment.baseUrl}/api/${environment.version}/user/priceList/update` // /:id
    },
    delete: {
      method: 'Delete',
      url: `${environment.baseUrl}/api/${environment.version}/user/priceList/delete` // /:id
    }
  },
  mentor: {
    index: {
      method: 'Get',
      url: `${environment.baseUrl}/api/${environment.version}/user/mentor/index`
    },
    user: {
      url: function(mentorId: number){return `${environment.baseUrl}/api/${environment.version}/user/mentor/${mentorId}/user`},
      method: 'Get',
    },
    removeUser: {
      url: function(userId: number){return `${environment.baseUrl}/api/${environment.version}/user/mentor/remove_user/${userId}`},
      method: 'Get',
    }
  },
  promotionRequest: {
    request: {
      method: 'Post',
      url: `${environment.baseUrl}/api/${environment.version}/user/promotion/request`
    },
    filter: {
      method: 'Post',
      url: `${environment.baseUrl}/api/${environment.version}/user/promotion/filter`
    },
    delete: {
      method: 'Delete',
      url: `${environment.baseUrl}/api/${environment.version}/user/promotion/delete/`
    },
    accept: {
      method: 'Post',
      url: `${environment.baseUrl}/api/${environment.version}/user/promotion/accept/`
    }
  },
  mentorRequest: {
    request: {
      method: 'Post',
      url: `${environment.baseUrl}/api/${environment.version}/user/tutoring/request`
    },
    filter: {
      method: 'Post',
      url: `${environment.baseUrl}/api/${environment.version}/user/tutoring/filter`
    },
    delete: {
      method: 'Delete',
      url: `${environment.baseUrl}/api/${environment.version}/user/tutoring/delete/`
    },
    accept: {
      method: 'Post',
      url: `${environment.baseUrl}/api/${environment.version}/user/tutoring/accept/`
    }
  },
  commons: {
    label: {
      filter: {
        method: 'Post',
        url: `${environment.baseUrl}/api/${environment.version}/user/label/filter`
      },
      create: {
        method: 'Post',
        url: `${environment.baseUrl}/api/${environment.version}/user/label/create`
      },
      update: {
        method: 'Patch',
        url: `${environment.baseUrl}/api/${environment.version}/user/label/update` // /:id
      },
      delete: {
        method: 'Delete',
        url: `${environment.baseUrl}/api/${environment.version}/user/label/delete` // /:id
      },
    },
    category: {
      filter: {
        method: 'Post',
        url: `${environment.baseUrl}/api/${environment.version}/user/category/filter`
      },
      create: {
        method: 'Post',
        url: `${environment.baseUrl}/api/${environment.version}/user/category/create`
      },
      update: {
        method: 'Patch',
        url: `${environment.baseUrl}/api/${environment.version}/user/category/update` // /:id
      },
      delete: {
        method: 'Delete',
        url: `${environment.baseUrl}/api/${environment.version}/user/category/delete` // /:id
      },
    },
    occupation: {
      filter: {
        method: 'Post',
        url: `${environment.baseUrl}/api/${environment.version}/user/occupation/filter`
      },
      create: {
        method: 'Post',
        url: `${environment.baseUrl}/api/${environment.version}/user/occupation/create`
      },
      update: {
        method: 'Patch',
        url: `${environment.baseUrl}/api/${environment.version}/user/occupation/update` // /:id
      },
      delete: {
        method: 'Delete',
        url: `${environment.baseUrl}/api/${environment.version}/user/occupation/delete` // /:id
      },
    },
    workgroup: {
      filter: {
        method: 'Post',
        url: `${environment.baseUrl}/api/${environment.version}/user/workgroup/filter`
      },
      create: {
        method: 'Post',
        url: `${environment.baseUrl}/api/${environment.version}/user/workgroup/create`
      },
      update: {
        method: 'Patch',
        url: `${environment.baseUrl}/api/${environment.version}/user/workgroup/update` // /:id
      },
      delete: {
        method: 'Delete',
        url: `${environment.baseUrl}/api/${environment.version}/user/workgroup/delete` // /:id
      },
    },
  }
};
