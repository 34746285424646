<div style="position: relative">
  <div class="multiselect" (appClickOutside)="closeSelect(); onHideMultiselectDropdown()"  (mouseenter)="onShowMultiselectDropdown()" (mouseleave)="onHideMultiselectDropdown()">
<!--    <div class="multiselect-hovered-dropdown" *ngIf="showMultiselectDropdown && multiple && allSelected.length >= 3">-->
<!--      <div class="multiselect-hovered-dropdown-content">-->
<!--        <span *ngFor="let data of allSelected; let l = last">{{data}}<span *ngIf="!l">, </span></span>-->
<!--      </div>-->
<!--    </div>-->
    <div (click)="openSelect(isDisabled)">
      <input
        class="form-control letters-color clickable"
        style="width: calc(100% - 2rem);"
        [value]="getDisplayValue()"
        [readonly]="readonly"
        [class.multiselectDisabled]="isDisabled"
        [class.multiselectAsButton]="selectAsButton"
      >
    </div>
    <div class="dropdown" [hidden]="!isOpen" [class.multiselectAsButtonDropdown]="selectAsButton" style="top: unset; background-color: white">
      <input *ngIf="enableSearch"
             class="form-control"
             type="search"
             style="width: calc(100% - 2rem);"
             [placeholder]="searchPlaceholder"
             [(ngModel)]="searchValue"
             (keyup)="onSearchStart($event)"
      >
      <div class="options">
        <div *ngIf="newEntity" style="padding: 1rem; position: relative">
          <div>
            <label class="custom-checkbox-container" style="height: unset"> <span class="font_regular" id="opt_addNew" style="font-size: 1.4rem">{{_locale.global.addNew}}</span>
              <input type="checkbox"
                     (click)="createNewEntity.emit()">
              <span class="icon-class-add" style="position: absolute; left: 2px"></span>
            </label>
          </div>
        </div>
        <div *ngFor="let option of options | multiselectSearch:name:searchValue" style="padding: 1rem">
          <div>
            <label class="custom-checkbox-container" style="height: unset"> <span class="font_regular" id="opt_{{option[name]}}" style="font-size: 1.4rem">{{option[name]}}</span>
              <input type="checkbox"
                     (change)="preventCheck($event)"
                     [checked]="isSelectedOption(option)"
                     (click)="selectOption(option)">
              <span class="checkmark"></span>
            </label>
          </div>
        </div>

        <div *ngIf="showLoadMore" style="padding-top: 1rem">
          <div class="show-more" (click)="loadMore()">
            {{_locale.global.showMore}}
          </div>
        </div>

      </div>
    </div>
  </div>
  <div class="class-click-outside" *ngIf="isOpen" (click)="closeSelect(); onHideMultiselectDropdown()"></div>
</div>
