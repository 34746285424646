import {Component, OnDestroy, OnInit} from '@angular/core';
import {LocalizationService} from '../../services/localization.service';
import {GlobalService} from '../../services/global.service';

@Component({
  selector: 'app-no-records',
  templateUrl: './no-records.component.html',
  styleUrls: ['./no-records.component.scss']
})
export class NoRecordsComponent implements OnInit, OnDestroy {

  public show: boolean = false;

  constructor(public _locale: LocalizationService) {
  }

  ngOnInit() {
    setTimeout(() => {
      this.show = true
    }, 500); //Delay showing this component
  }

  ngOnDestroy() {
    this.show = false;
  }
}
