export const CalendarMonthNumbersTranslations = {
  'eng': {
    1: 'January',
    2: 'February',
    3: 'March',
    4: 'April',
    5: 'May',
    6: 'June',
    7: 'July',
    8: 'August',
    9: 'September',
    10: 'October',
    11: 'November',
    12: 'December'
  },
  'hr:': {
    1: 'Siječanj',
    2: 'Veljača',
    3: 'Ožujak',
    4: 'Travanj',
    5: 'Svibanj',
    6: 'Lipanj',
    7: 'Srpanj',
    8: 'Kolovoz',
    9: 'Rujan',
    10: 'Listopad',
    11: 'Studeni',
    12: 'Prosinac'
  }
};
