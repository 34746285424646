export const AddressTypeTranslations = {
  'eng': {
    main: 'Main',
    billing: 'Billing',
    shipping: 'Shipping',
    other: 'Other'
  },
  'hrv': {
    main: 'Primarna',
    billing: 'Adresa za dostavu računa',
    shipping: 'Adresa isporuke',
    other: 'Ostalo'
  }
};
