import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {FlatpickrComponent} from './flatpickr.component';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    FlatpickrComponent
  ],
  exports: [
    FlatpickrComponent
  ]
})
export class FlatpickrModule { }
