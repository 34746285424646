export const WorkgroupTranslations = {
  'eng': {
    manage: 'Manage workgroups',
    add: 'Add',
    save: 'Save',
    workGroupNamePlaceholder: 'Work group name',
    clear: 'Clear'
  },
  'hrv': {
    manage: 'Upravljaj radnim grupama',
    add: 'Dodaj',
    save: 'Spremi',
    workGroupNamePlaceholder: 'Naziv radne grupe',
    clear: 'Očisti'
  }
};
