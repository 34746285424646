import {Directive, ElementRef, EventEmitter, Input, OnDestroy, Output} from '@angular/core';
import {ClickedElementService} from '../../services/clicked-element.service';
import {Subscription} from 'rxjs';

@Directive({
  selector: '[appOutsideClick]'
})
export class OutsideClickDirective implements OnDestroy {

  @Input('outsideClickExceptionKey') exceptionKey?;
  @Output('appOutsideClick') appOutsideClick: EventEmitter<void> = new EventEmitter<void>();
  private clickedElementSubscription: Subscription;

  constructor(
    private element: ElementRef,
    private clickedElement: ClickedElementService
  ) {
    this.setClickedElementSubscription();
  }

  setClickedElementSubscription(): void {
    this.clickedElementSubscription = this.clickedElement.element.subscribe(clickedElement => {
      if(this.clickedElementIsOutside(clickedElement) && !this.clickedElementIsException(clickedElement)) {
        this.appOutsideClick.next();
      }
    });
  }

  private clickedElementIsOutside(clickedElement: HTMLElement): boolean {
    return !this.element.nativeElement.contains(clickedElement);
  }

  private clickedElementIsException(clickedElem: HTMLElement): boolean {
    return !!(this.exceptionKey && this.exceptionKey === clickedElem.dataset.outsideClickExceptionKey);
  }

  ngOnDestroy(): void {
    this.clickedElementSubscription.unsubscribe();
  }

}
