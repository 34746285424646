import {Injectable} from '@angular/core';
import {ApiService} from '../../../services/api.service';
import {GlobalService} from '../../../services/global.service';
import {Company} from './classes/company';
import {BehaviorSubject, Observable} from 'rxjs';
import * as _ from 'lodash';
import {urlValues} from '../../../configs/url.values';
import {LocalizationService} from '../../../services/localization.service';
import {Location} from '@angular/common';
import {FilterData} from '../../../classes/filter-data';
import {FilterField} from '../../../components/filter/filter.component';
import {AuthService} from '../../../services/auth.service';
import {Router} from '@angular/router';
import {ImportedYearCompanyService} from './imported-year-company.service';

@Injectable()
export class CompanyService {

  public sidebarActived: boolean = false;
  public activeCompany: Company = null; //Active company on company tab

  public companies: Company[] = [];
  public company: Company = null; //Company that is being manipulated (edited...)

  //<editor-fold desc="Filter data">
  public filterData: BehaviorSubject<any> = new BehaviorSubject(null);
  public totalPages: number;
  public totalRecords: number;

  //</editor-fold>

  public listFilters: FilterField[] = []; //Filters that will be shown above the list

  constructor(private _api: ApiService,
              private _locale: LocalizationService,
              private _location: Location,
              private _auth: AuthService,
              private _router: Router,
              public _importCompany: ImportedYearCompanyService,
              public _global: GlobalService) {

    //Get all companies and subscribe to filter data
    //Track every change on filter data (search, filters, page ...) and send new request
    this.filterData.subscribe((data: FilterData) => {
      if (!data) return;
      this.getCompanies(data, true).subscribe();
    });
  }

  //<editor-fold desc="CRUD">
  getCompany(id: number, active?: boolean): Observable<Company> {
    return new Observable<Company>(obs => {
      this._api.send(urlValues.company.get.method, `${urlValues.company.get.url}/${id}`).subscribe(res => {
        res['data'].iconColor = this._global.getRandomColor(res['data'].id); //Set a color for company icon
        if (active) this.activeCompany = new Company(res['data']); //When loading company module get the active company that is selected
        obs.next(new Company(res['data']));
        obs.complete();
      }, err => {
        obs.error(err);
        obs.complete();
      });
    });
  }

  getCompanies(filters?: FilterData, updateCount?: boolean): Observable<Company[]> {
    return new Observable<Company[]>(obs => {
      this._api.send(urlValues.company.filter.method, urlValues.company.filter.url, filters).subscribe(res => {

        if (updateCount) {
          this.totalPages = res['data'].totalPages; //Set how many total pages are in db
          this.totalRecords = res['data'].totalRecords; //Set how many records are in db
        }

        this.companies = _.map(res['data'].records, company => {
          if (!company.iconColor) _.extend(company, {iconColor: this._global.getRandomColor(company.id)});
          return new Company(company);
        });

        obs.next(this.companies);
        obs.complete();
      }, err => {
        obs.error(err);
        obs.complete();
      });
    });
  }

  create(data: object, gfiData?): void {
    this._api.send(urlValues.company.create.method, urlValues.company.create.url, data).subscribe(res => {
      //Update user with new company settings if user does not have company
      this._location.back(); //Return user back to the page he came from
      if (gfiData.years.length > 0) {
        gfiData.companyId = res['data'].id
        this._importCompany.update(res['data'].id, gfiData).subscribe()
        this._global.pushAppMessage('success', this._locale.company.success, this._locale.company.companycreated);
      } else {
        this._global.pushAppMessage('success', this._locale.company.success, this._locale.company.companycreated);
      }
    });
  }

  update(id: number, data: object, redirect?: boolean): Observable<boolean> {
    return new Observable<boolean>(obs => {
      if (this._global.isEmptyObject(data)) {
        obs.next(true);
        obs.complete();
        return this._location.back();
      }

      this._api.send(urlValues.company.update.method, `${urlValues.company.update.url}/${id}`, data).subscribe(res => {
        if (redirect) this._location.back(); //Return user back to the page he came from
        this._global.pushAppMessage('success', this._locale.company.success, this._locale.company.companyupdated);
        obs.next(true);
        obs.complete();
      });

    });
  }

  createMain(data: object): void {
    this._api.send(urlValues.company.createMain.method, urlValues.company.createMain.url, data).subscribe(res => {
      //Update user with new company settings if user does not have company
      this._global.pushAppMessage('success', this._locale.company.success, this._locale.company.companycreated);
      if (!this._auth.loggedUser.company) this._auth.updateLoggedUserCompany(res['data'].id, this._auth.loggedUser.company);
      this._router.navigate(['/home']);
    });
  }

  updateMain(id: number, data: object): Observable<boolean> {
    return new Observable<boolean>(obs => {
      if (this._global.isEmptyObject(data)) {
        this._global.pushAppMessage('success', this._locale.company.success, this._locale.company.companyupdated);
        obs.next(true);
        obs.complete();
      }

      this._api.send(urlValues.company.update.method, `${urlValues.company.update.url}/${id}`, data).subscribe(res => {
        this._global.pushAppMessage('success', this._locale.company.success, this._locale.company.companyupdated);
        obs.next(true);
        obs.complete();
      });
    });
  }

  archive(id: number): void {
    this._api.send(urlValues.company.archive.method, `${urlValues.company.archive.url}/${id}`).subscribe(res => {
      _.remove(this.companies, ['id', id]);
      this._global.pushAppMessage('success', this._locale.company.success, this._locale.company.companyarchived);
    });
  }

  restore(company: Company): void {
    company.archived = false;
    this._api.send(urlValues.company.update.method, `${urlValues.company.update.url}/${company.id}`, {archived: false}).subscribe(res => {
      _.remove(this.companies, ['id', company.id]);
      this._global.pushAppMessage('success', this._locale.company.success, this._locale.company.companyrestored);
    });
  }

  //</editor-fold>

  setListFilters(): void {
    this.listFilters = [
      {
        name: 'Labels', // will be translated inside the filter component
        values: _.mapFilterFields(this._global.commonData.company.label),
        selected: null,
        type: 'select',
        key: 'label'
      },
      {
        name: 'Category',
        values: _.mapFilterFields(this._global.commonData.company.category),
        selected: null,
        type: 'select',
        key: 'category' //Optional
      },
      {
        name: 'Industry',
        values: _.map(this._global.commonData.industryTypes, iType => {
          return {name: this._locale.industryTypes[iType.slug], sendValue: iType.id};
        }),
        selected: null,
        type: 'select',
        key: 'industryType' //Optional
      },
    ];
  }
}
