export const FileUploadTranslations = {
  'eng': {
    maxFileSizeWarn: 'UploadedFile to big',
    maxFileSizeError: 'Max file size is',
  },
  'hrv': {
    maxFileSizeWarn: 'Prevelika datoteka',
    maxFileSizeError: 'Premašena maksimalna veličina datoteke',
  }
};
