import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ToggleService} from '../../../services/toggle.service';
import {ElectronService} from 'ngx-electron';
import {LocalizationService} from '../../../services/localization.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ApiService} from '../../../services/api.service';
import {GlobalService} from '../../../services/global.service';
import {urlValues} from '../../../configs/url.values';
import {Category} from '../../../interfaces/common/categoty';
import * as _ from 'lodash';
import {AuthService} from '../../../services/auth.service';

@Component({
  selector: 'app-category-modal',
  templateUrl: './category-modal.component.html',
  styleUrls: ['./category-modal.component.scss']
})
export class CategoryModalComponent implements OnInit {

  public clickOutsideCounterModal: number = 1;
  public form: FormGroup;
  public category: Category = null;

  private returnObj: any = {
    type: 'category',
    data: null
  };

  @Input() categories: Category[] = [];
  @Input() url: any; //Url object
  @Input() module: string; //Module for which is going to be create (company, user, project)
  @Output() onChange: any = new EventEmitter(); //Emit onChange event with the changed object

  constructor(public _electronService: ElectronService,
              public _locale: LocalizationService,
              public _toggle: ToggleService,
              private _global: GlobalService,
              private _auth: AuthService,
              private _fb: FormBuilder,
              private _api: ApiService) {
  }

  ngOnInit() {
    if (!this.categories) this.categories = []; //Set empty array
    this.buildForm();
  }

  onClickOutsideGlobalPrimaryModal(event) {
    if (this._toggle.showGlobalPrimaryModal.getValue()) {
      this.clickOutsideCounterModal = this.clickOutsideCounterModal + 1;
      if (this._toggle.showGlobalPrimaryModal.getValue() && this.clickOutsideCounterModal === 3) {
        this.clickOutsideCounterModal = 1;
        this._toggle.showGlobalPrimaryModal.next(false);
      }
    }
  }


  //<editor-fold desc="FORM">
  buildForm(category?: Category): void {

    this.form = this._fb.group({
      id: [{value: category ? category.id : null, disabled: !category}],
      name: [category ? category.name : '', Validators.compose([Validators.required, Validators.maxLength(20)])],
      type: 'category',
      company: this._auth.loggedUser.company, //Company for which is created
    });
  }

  edit(category: Category): void {
    this.category = category;
    this.form.reset();
    this.buildForm(this.category);
  }

  clear(clearCategory?: boolean): void {
    this.form.reset();
    if (clearCategory) this.category = null;
    this.buildForm();
  }

  //</editor-fold>

  //<editor-fold desc="CRUD">
  create(formData: Category): void {
    if (!this.form.valid) return this._global.checkFormErrors(this.form);
    this._api.send(this.url.create.method, this.url.create.url, formData).subscribe(res => {
      this._global.updateCommonData(this.module, 'category', res['data'], 'create'); //Update common data
      this.onChange.emit(_.assign(this.returnObj, {data: formData})); //Send event to parent component
      this.clear();
    });
  }

  update(formData: Category): void {
    if (!this.form.valid) return this._global.checkFormErrors(this.form);

    this._api.send(this.url.update.method, `${this.url.update.url}/${formData.id}`, formData).subscribe(res => {
      this._global.updateCommonData(this.module, 'category', formData, 'update'); //Update common data
      this.onChange.emit(_.assign(this.returnObj, {data: formData})); //Send event to parent component
      this.clear(true);
    });
  }

  delete(category: Category): void {
    this._api.send(this.url.delete.method, `${this.url.delete.url}/${category.id}`, category).subscribe(res => {
      this._global.updateCommonData(this.module, 'category', category, 'delete'); //Update common data
      this.onChange.emit(_.assign(this.returnObj, {data: category})); //Send event to parent component
    });
  }

  //</editor-fold>

}
