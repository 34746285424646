import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {NgxElectronModule} from 'ngx-electron';
import {ApiInterceptor} from './interceptors/api.interceptor';
import {ApiService} from './services/api.service';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {GlobalModule} from './modules/global.module';
import {LoadingBarHttpClientModule} from '@ngx-loading-bar/http-client';
import {ToastModule} from 'primeng/toast';
import {MessageService} from 'primeng/api';
import {GuidelinesModule} from './components/guidelines/guidelines.module';
import {CanLeaveGuard} from './guards/can-leave.guard';
import {LocalizationService} from './services/localization.service';
import { registerLocaleData } from '@angular/common';
import localeHr from '@angular/common/locales/hr';
import { AutofocusDirective } from './modules/layout/study/directives/autofocus.directive';
import {RouteReuseStrategy} from '@angular/router';
import {CustomRouteReuseStrategy} from './custom-route-reuse-strategy';
import {ImportService} from './modules/layout/study/services/import.service';
registerLocaleData(localeHr);

@NgModule({
  declarations: [
    AppComponent],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    ToastModule,
    ReactiveFormsModule,
    AppRoutingModule,
    NgxElectronModule,
    LoadingBarHttpClientModule,
    // LoadingBarRouterModule,
    GuidelinesModule,
    GlobalModule.forRoot(),
  ],
  providers: [
    {provide: HTTP_INTERCEPTORS, useClass: ApiInterceptor, multi: true},
    {provide: RouteReuseStrategy, useClass: CustomRouteReuseStrategy},
    ApiService,
    LocalizationService,
    ImportService,
    CanLeaveGuard,
    MessageService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
