export const SalutationTranslations = {
  'eng': {
    Mr: 'Mr',
    Ms: 'Ms',
    Sir: 'Sir',
    Dr: 'Dr'
  },
  'hrv': {
    Mr: 'g',
    Ms: 'gđa',
    Sir: 'g',
    Dr: 'dr'
  }
};
