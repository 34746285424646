import {environment} from '../../environments/environment';

export const SettingsRoutes = {
  user: {
    method: 'Get',
    url: `${environment.baseUrl}/api/${environment.version}/settings/user`
  },
  save: {
    method: 'Post',
    url: `${environment.baseUrl}/api/${environment.version}/settings/save`
  }
};
