export const CompanyTranslations = {
  'eng': {
    phone: 'Phone',
    addnew: 'Add new',
    edit: 'Edit',
    user: 'User',
    company: 'Company',
    branch: 'Branch',
    branches: 'Branches',
    newcompany: 'New company',
    editcompany: 'Edit company',
    create: 'Create',
    savechanges: 'Save changes',
    name: 'Name',
    shortname: 'Short name',
    shortName: 'Short name',
    clientcompany: 'Client company',
    industrytype: 'Industry type',
    select: '- Select -',
    companyidnumber: 'Company ID Number',
    vatnumber: 'Vat number',
    addresses: 'Addresses',
    address: 'Address',
    add: 'Add',
    remove: 'Remove',
    city: 'City',
    region: 'Region',
    housenumber: 'House number',
    postalcode: 'Postal code',
    streetaddress: 'Street address',
    stateprovince: 'State/Province',
    country: 'Country',
    contacts: 'Contacts',
    type: 'Type',
    value: 'Value',
    cancel: 'Cancel',
    search: 'Search...',
    main: 'Main',
    selectimage: 'Select image',
    hasaccesstooltip: 'Client company is a fictive company',
    contactcheckboxtooltip: 'Your main contact',
    success: 'Success',
    companycreated: 'New company has been created',
    companyupdated: 'Company has been updated',
    companyarchived: 'Company archived',
    companyrestored: 'Company restored',
    selectcompany: 'Select company',
    selectcompanytext: 'Please select a company',
    companyType: 'Legal entity type',
    physicalPerson: 'Physical person',
    selfGovernment: 'Units of regional (regional) and local self-government',
    publicLawBodies: 'Other bodies of public law',
    nonProfitSector: 'Private non-profit sector',
    profitSector: 'Private for-profit sector',
    administrationBodies: 'State administration bodies',
    category: 'Category',
    manage: 'Manage',
    labels: 'Labels',
    website: 'Website',
    email: 'Email',
    parent: 'Parent',
    supervisor: 'Supervisor',
    fax: 'Fax',
    mobile: 'Mobile',

    departments: 'Departments',
    newdepartment: 'New department',
    editdepartment: 'Edit department',

    departmentcreated: 'Department created',
    departmentupdated: 'Department updated',
    departmentarchived: 'Department archived',
    sendmail: 'Send email',
    companyLogo: 'Company logo',
    priceList: 'Price list',
    documents: 'Documents',
    anonymise: 'Anonymise',
    identify: 'Identify',

  },
  'hrv': {
    phone: 'Telefon',
    addnew: 'Dodaj',
    edit: 'Uredi',
    user: 'Korisnik',
    company: 'Tvrtka',
    branch: 'Poslovnica',
    branches: 'Poslovnice',
    newcompany: 'Nova tvrtka',
    editcompany: 'Uredi tvrtku',
    shortname: 'Kratki naziv',
    shortName: 'Kratki naziv',
    create: 'Kreiraj',
    savechanges: 'Spremi promjene',
    name: 'Naziv',
    clientcompany: 'Klijent tvrtka',
    industrytype: 'Tip djelatnosti',
    select: '- Odaberi -',
    companyidnumber: 'OIB',
    vatnumber: 'Porezni broj',
    addresses: 'Adrese',
    address: 'Adresa',
    add: 'Dodaj',
    remove: 'Ukloni',
    city: 'Grad',
    region: 'Regija',
    housenumber: 'Kućni broj',
    postalcode: 'Poštanski broj',
    streetaddress: 'Ulica',
    stateprovince: 'Općina/Naselje',
    country: 'Država',
    contacts: 'Kontakti',
    type: 'Tip',
    value: 'Vrijednost',
    cancel: 'Poništi',
    search: 'Pretraži...',
    main: 'Primarni',
    selectimage: 'Odaberi sliku',
    hasaccesstooltip: 'Klijent tvrtka',
    contactcheckboxtooltip: 'Vaš glavni kontakt',
    success: 'Uspješno',
    companycreated: 'Nova tvrtka je kreirana',
    companyupdated: 'Tvrtka je uređena',
    companyarchived: 'Tvrtka je arhivirana',
    companyrestored: 'Tvrtka je vraćena',
    selectcompany: 'Odaberi tvrtka',
    selectcompanytext: 'Molimo odaberite tvrtku',
    companyType: 'Vrsta pravnog subjekta',
    physicalPerson: 'Fizička osoba',
    selfGovernment: 'Jedinice područne (regionalne) i lokalne samouprave',
    publicLawBodies: 'Ostala tijela javnog prava',
    nonProfitSector: 'Privatni neprofitni sektor',
    profitSector: 'Privatni profitni sektor',
    administrationBodies: 'Tijela državne uprave',
    category: 'Kategorija',
    manage: 'Upravljaj',
    labels: 'Oznake',
    website: 'Internetska stranica',
    email: 'Email',
    parent: 'Nadređeni odjel',
    supervisor: 'Nadređena osoba',

    departments: 'Odjeli',
    newdepartment: 'Novi odjel',
    editdepartment: 'Uredi odjel',
    departmentcreated: 'Odjel kreiran',
    departmentupdated: 'Odjel uređen',
    departmentarchived: 'Odjel arhiviran',
    sendmail: 'Pošalji email',
    fax: 'Fax',
    mobile: 'Mobitel',
    companyLogo: 'Logo tvrtke',
    priceList: 'Cjenik',
    documents: 'Dokumenti',
    anonymise: 'Anonimiziraj',
    identify: 'Identificiraj'
  }
};
