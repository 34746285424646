export const InternalServerErrorTranslations = {
  'eng': {
    title: 'Internal Server Error',
    back: 'Go back'
  },
  'hrv': {
    title: 'Interna greška na serveru',
    back: 'Vrati se natrag'
  }
};
