export const PaginationTranslations = {
  'eng': {
    previous: 'Previous',
    next: 'Next',
    page: 'page',
    plural: 's',
    show: 'Show',
    entries: 'entries',
    of: 'of',
    to: 'to'

  },
  'hrv': {
    previous: 'Prethodna',
    next: 'Sljedeća',
    page: 'stranica',
    show: 'Prikaži',
    entries: 'rezultata',
    of: 'od',
    to: 'do'
  }
};
