import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ToggleService} from '../../../services/toggle.service';
import {ElectronService} from 'ngx-electron';
import {LocalizationService} from '../../../services/localization.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Occupation} from '../../../interfaces/common/occupation';
import {ApiService} from '../../../services/api.service';
import {GlobalService} from '../../../services/global.service';
import {urlValues} from '../../../configs/url.values';
import * as _ from 'lodash';
import {AuthService} from '../../../services/auth.service';

@Component({
  selector: 'app-occupation-modal',
  templateUrl: './occupation-modal.component.html',
  styleUrls: ['./occupation-modal.component.scss']
})
export class OccupationModalComponent implements OnInit {

  public clickOutsideCounterModal: number = 1;
  public form: FormGroup;
  public occupation: Occupation = null;

  private returnObj: any = {
    type: 'occupation',
    data: null
  };

  @Input() occupations: Occupation[] = [];
  @Input() url: any; //Url object
  @Input() module: string; //model type (user, company, project ...)
  @Output() onChange: any = new EventEmitter(); //Emit onChange event with the changed object

  constructor(public _electronService: ElectronService,
              public _locale: LocalizationService,
              public _toggle: ToggleService,
              private _global: GlobalService,
              private _fb: FormBuilder,
              private _auth: AuthService,
              private _api: ApiService) {
  }

  onClickOutsideGlobalPrimaryModal(event) {
    if (this._toggle.showGlobalPrimaryModal.getValue()) {
      this.clickOutsideCounterModal = this.clickOutsideCounterModal + 1;
      if (this._toggle.showGlobalPrimaryModal.getValue() && this.clickOutsideCounterModal === 3) {
        this.clickOutsideCounterModal = 1;
        this._toggle.showGlobalPrimaryModal.next(false);
      }
    }
  }

  ngOnInit() {
    if (!this.occupations) this.occupations = []; //Set empty array
    this.buildForm();
  }

  //<editor-fold desc="FORM">
  buildForm(occupation?: Occupation): void {

    this.form = this._fb.group({
      id: [{value: occupation ? occupation.id : null, disabled: !occupation}],
      name: [occupation ? occupation.name : '', Validators.compose([Validators.required, Validators.maxLength(100)])],
      type: 'occupation',
      company: this._auth.loggedUser.company, //Company for which is created
    });
  }

  edit(occupation: Occupation): void {
    this.occupation = occupation;
    this.form.reset();
    this.buildForm(this.occupation);
  }

  clear(clearOccupation?): void {
    this.form.reset();
    if (clearOccupation) this.occupation = null;
    this.buildForm();
  }

  //</editor-fold>

  //<editor-fold desc="CRUD">
  create(formData: Occupation): void {
    if (!this.form.valid) return this._global.checkFormErrors(this.form);
    this._api.send(this.url.create.method, this.url.create.url, formData).subscribe(res => {
      this._global.updateCommonData(this.module,'occupation', res['data'], 'create'); //Update common data
      this.clear();
      this.onChange.emit(_.assign(this.returnObj, {data: formData})); //Send event to parent component
    });
  }

  update(formData: Occupation): void {
    if (!this.form.valid) return this._global.checkFormErrors(this.form);

    this._api.send(this.url.update.method, `${this.url.update.url}/${formData.id}`, formData).subscribe(res => {
      this._global.updateCommonData(this.module,'occupation', formData, 'update'); //Update common data
      this.clear(true);
      this.onChange.emit(_.assign(this.returnObj, {data: formData})); //Send event to parent component
    });
  }

  delete(occupation: Occupation): void {
    this._api.send(this.url.delete.method, `${this.url.delete.url}/${occupation.id}`, occupation).subscribe(res => {
      this._global.updateCommonData(this.module,'occupation', occupation, 'delete'); //Update common data
      this.onChange.emit(_.assign(this.returnObj, {data: occupation})); //Send event to parent component
    });
  }

  //</editor-fold>
}
