<div class="section-content-header"
     [class.defaultTheme]="_theme.colorScheme === 'defaultTheme'"
     [class.customTheme]="_theme.colorScheme === 'customTheme'"
     [class.businessTheme]="_theme.colorScheme === 'businessTheme'">
  <div class="section-content-header-grid"
       style="grid-template-areas: 'title filter';
              grid-template-columns: 1fr auto;">

    <div class="section-content-header-title">
      {{_locale.activity.recentActivity}}
    </div>

    <div class="section-content-header-filter" style="justify-self: right">
     <app-filter *ngIf="hasFilter"></app-filter>
    </div>

  </div>
</div>

<p-scrollPanel id="table" *ngIf="_activity.activities.length"
               [class.defaultTheme]="_theme.colorScheme === 'defaultTheme'"
               [class.customTheme]="_theme.colorScheme === 'customTheme'"
               [class.businessTheme]="_theme.colorScheme === 'businessTheme'"
               class="margin_top">

  <div class="activity-list">
    <div class="activity-list-grid" style="grid-template-columns: 145px auto 1fr auto auto auto" *ngFor="let activity of _activity.activities">
      <div>
        <span>
          {{_global.timestampToDate(activity.createdAt)}}
          {{_global.getTimeFromTimestamp(activity.createdAt)}}
        </span>
      </div>
      <div>
          <span>
            <i class="icon-pie-chart"></i>
          </span>
      </div>
      <div class="txtOver">
        <span>{{displayActivity(activity)}}</span>
      </div>
      <div>{{_locale.activity.createdBy}}</div>
      <div class="txtOver">
        <span class="font_bold">{{activity.createdBy.getFullName()}}</span>
      </div>
      <div class="last">
        <div class="initials clickable" [ngStyle]="{'background-color': activity.createdBy.iconColor}"
             *ngIf="!activity.createdBy['profileImage']; else showAvatar">
          <span [pTooltip]="activity.createdBy.getFullName()" tooltipPosition="top">{{activity.createdBy.getInitials()}}</span>
        </div>
        <ng-template #showAvatar>
          <div class="avatar clickable">
            <img src="{{_fileUpload.getMediaPath(activity.createdBy['profileImage']['thumbnail'])}}"
                 alt=""
                 [pTooltip]="activity.createdBy.getFullName()"
                 tooltipPosition="top">
          </div>
        </ng-template>
      </div>
    </div>
  </div>

</p-scrollPanel>

<app-pagination *ngIf="_activity.totalPages && _activity.totalPages > 1"
                [filterData]="_activity.filterData"
                [totalPages]="_activity.totalPages"
                [totalRecords]="_activity.totalRecords"
></app-pagination>
<app-no-records *ngIf="!_activity.activities.length"></app-no-records>


