export const IndustryTypeTranslations = {
  'eng': {
    banking: 'Banking Services',
    construction: 'Construction',
    consulting: 'Consulting',
    delivery: 'Delivery',
    education: 'Education',
    entertainment: 'Entertainment',
    finance: 'Finance',
    government: 'Government',
    it: 'Information Technology',
    manufacturing: 'Manufacturing',
    nonprofit: 'Non-profit',
    sales: 'Sales',
    telecommunication: 'Telecommunication',
    other: 'Other'
  },
  'hrv': {
    banking: 'Bankarstvo',
    construction: 'Građevina',
    consulting: 'Konzalting',
    delivery: 'Dostava',
    education: 'Edukacija',
    entertainment: 'Zabava',
    finance: 'Financijski sektor',
    government: 'Državna služba',
    it: 'Informacijske tehnologije',
    manufacturing: 'Proizvodnja',
    nonprofit: 'Neprofitni sektor',
    sales: 'Prodajne djelatnosti',
    telecommunication: 'Telekomunikacije',
    other: 'Ostalo'
  }
};
