export const ActivityTranslations = {
  'eng': {
    recentActivity: 'Recent activity',
    recentActivities: 'Recent activities',
    createdBy: 'Created by',
    //<editor-fold desc="Actions">
    created: 'Created',
    updated: 'Updated',
    deleted: 'Deleted',
    modified: 'Modified',
    logged_in: 'Logged in',
    moved_to_archive: 'Moved to archive',
    restored_from_archive: 'Restored from archive',
    completed: 'Completed',
    reopened: 'Responded',
    uploaded: 'Uploaded',
    //</editor-fold>

    //<editor-fold desc="Subject types">
    user: 'user',
    label: 'label',
    workgroup: 'workgroup',
    occupation: 'occupation',
    category: 'category',
    company: 'company',
    branch: 'branch',
    cashRegister: 'cash register',
    department: 'department',
    product: 'product',
    simple_product: 'simple product',
    complex_product: 'complex product',
    eProduct_product: 'e-Product',
    service: 'service',
    simple_service: 'simple service',
    complex_service: 'complex service',
    eProduct_service: 'e-Service',
    material: 'material',
    externalService: 'external service',
    attribute: 'attribute',
    attributeSet: 'attribute set',
    course: 'course',
    courseExam: 'exam',
    class: 'class',
    discussion: 'discussion',
    comment: 'comment',
    project: 'project',
    warehouse: 'warehouse',
    bin: 'bin',
    asset: 'asset',
    projectDiscussion: 'discussion',
    task: 'task',
    removed_from_stock:'removed from stock',
    added_to_stock:'added to stock',
    //</editor-fold>
  },
  'hrv': {
    recentActivity: 'Nedavna aktivnost',
    recentActivities: 'Nedavne aktivnosti',
    createdBy: 'Kreirano od',
    //<editor-fold desc="Actions">
    created: 'Kreirano',
    updated: 'Uređeno',
    deleted: 'Obrisano',
    modified: 'Promijenjeno',
    logged_in: 'Logiran',
    moved_to_archive: 'Prebačeno u arhivu',
    restored_from_archive: 'Vraćeno iz arhive',
    completed: 'Završen',
    reopened: 'Ponovno otvoren',
    uploaded: 'Učitan',
    //</editor-fold>
    //<editor-fold desc="Subject types">
    user: 'korinik',
    label: 'oznaka',
    workgroup: 'radna grupa',
    occupation: 'zanimanje',
    category: 'kategorija',
    company: 'tvrtka',
    branch: 'poslovica',
    cashRegister: 'blagajna',
    department: 'odjel',
    product: 'produkt',
    simple_product: 'jednostavni proizvod',
    complex_product: 'složeni proizvod',
    eProduct_product: 'e-Proizvod',
    service: 'usluga',
    simple_service: 'jednostavna usluga',
    complex_service: 'složena usluga',
    eProduct_service: 'e-Usluga',
    material: 'sirovina',
    externalService: 'vanjska usluga',
    attribute: 'atribut',
    attributeSet: 'atribut set',
    course: 'tečaj',
    courseExam: 'ispit',
    class: 'lekcija',
    discussion: 'diskusija',
    comment: 'komentar',
    project: 'projekt',
    warehouse: 'skladište',
    bin: 'policu',
    asset: 'imovina',
    projectDiscussion: 'diskusija',
    task: 'zadatak',
    removed_from_stock:'uklonjeno sa stanja',
    added_to_stock:'dodano na stanje'
    //</editor-fold>
  }
};
