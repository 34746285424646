import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActivityComponent } from './activity.component';
import {ScrollPanelModule} from 'primeng/scrollpanel';
import {TooltipModule} from 'primeng/tooltip';
import {PaginationModule} from '../pagination/pagination.module';
import {FilterModule} from '../filter/filter.module';
import {NoRecordsModule} from '../no-records/no-records.module';
import {ApiService} from '../../services/api.service';

@NgModule({
  imports: [
    CommonModule,
    ScrollPanelModule,
    PaginationModule,
    FilterModule,
    TooltipModule,
    NoRecordsModule
  ],
  declarations: [ActivityComponent],
  exports: [ActivityComponent]
})
export class ActivityModule { }
