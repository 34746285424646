export class Category {
  id?: number = null;
  name: string = '-';

  constructor(init?: any) {
    if (init && init.id) this.id = init.id;
    if (init && init.name) this.name = init.name;
  }

}
