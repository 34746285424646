import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ToggleService} from '../../../services/toggle.service';
import {ElectronService} from 'ngx-electron';
import {LocalizationService} from '../../../services/localization.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Label} from '../../../interfaces/common/label';
import * as _ from 'lodash';
import {GlobalService} from '../../../services/global.service';
import {BehaviorSubject} from 'rxjs';
import {ApiService} from '../../../services/api.service';
import {AuthService} from '../../../services/auth.service';

@Component({
  selector: 'app-label-modal',
  templateUrl: './label-modal.component.html',
  styleUrls: ['./label-modal.component.scss']
})
export class LabelModalComponent implements OnInit {

  public clickOutsideCounterModal: number = 1;
  public colors: any[] = ['#20ADE2', '#1DBFBF', '#A0D176', '#EFA02A', '#ED6C76', '#6F80C7', '#B272B7', '#E64B8D', '#BF5783', '#3E7FAF'];
  public selectedColor: BehaviorSubject<string> = new BehaviorSubject(null);
  public label: Label = null; //Selected label inside this modal
  public form: FormGroup;

  private returnObj: any = {
    type: 'label',
    data: null
  };

  @Input() labels: Label[] = [];
  @Input() url: any; //Url object
  @Input() module: string; //Module for which is going to be create (company, user, project)
  @Input() type: string = null; //Module for which is going to be create (company, user, project)
  @Output() onChange: any = new EventEmitter(); //Emit onChange event with the changed object

  constructor(public _electronService: ElectronService,
              public _locale: LocalizationService,
              public _toggle: ToggleService,
              private _global: GlobalService,
              private _fb: FormBuilder,
              private _auth: AuthService,
              private _api: ApiService) {
  }

  ngOnInit() {
    if (!this.labels) this.labels = []; //Set empty array
    this.buildForm();
  }

  onClickOutsideGlobalPrimaryModal(event) {
    if (this._toggle.labelModal.getValue()) {
      this.clickOutsideCounterModal = this.clickOutsideCounterModal + 1;
      if (this._toggle.labelModal.getValue() && this.clickOutsideCounterModal === 3) {
        this.clickOutsideCounterModal = 1;
        this._toggle.labelModal.next(false);
      }
    }
  }

  selectLabelColor(param: string): void {
    this.selectedColor.next(param);
    let form = this.form.get('color');
    form.patchValue(this.selectedColor.getValue());
    form.updateValueAndValidity();
  }

  //<editor-fold desc="FORM">
  buildForm(label?: Label): void {
    if (label) this.selectedColor.next(label.color);

    this.form = this._fb.group({
      id: [{value: label ? label.id : null, disabled: !label}],
      name: [label ? label.name : '', Validators.compose([Validators.required, Validators.maxLength(20)])],
      color: [this.selectedColor.getValue(), Validators.required],
      type: this.type ? this.type : 'label',
      company: this._auth.loggedUser.company, //Company for which is created
    });
  }

  edit(label: Label): void {
    this.label = label;
    this.form.reset();
    this.buildForm(this.label);
    this.selectedColor.next(label.color);
  }

  clear(clearLabel?): void {
    this.selectedColor.next(null);
    this.form.reset();
    if (clearLabel) this.label = null;
    this.buildForm();
  }

  //</editor-fold>

  //<editor-fold desc="CRUD">
  create(formData: Label): void {
    if (!this.form.valid) return this._global.checkFormErrors(this.form);
    this._api.send(this.url.create.method, this.url.create.url, formData).subscribe(res => {
      this._global.updateCommonData(this.module, 'label', res['data'], 'create'); //Update common data
      this.onChange.emit(_.assign(this.returnObj, {data: formData})); //Send event to parent component
      this.clear();
    });
  }

  update(formData: Label): void {
    if (!this.form.valid) return this._global.checkFormErrors(this.form);

    this._api.send(this.url.update.method, `${this.url.update.url}/${formData.id}`, formData).subscribe(res => {
      this._global.updateCommonData(this.module, 'label', formData, 'update'); //Update common data
      this.onChange.emit(_.assign(this.returnObj, {data: formData})); //Send event to parent component
      this.clear(true);
    });
  }

  delete(label: Label): void {
    this._api.send(this.url.delete.method, `${this.url.delete.url}/${label.id}`, label).subscribe(res => {
      this._global.updateCommonData(this.module, 'label', label, 'delete'); //Update common data
      this.onChange.emit(_.assign(this.returnObj, {data: label})); //Send event to parent component
    });
  }

  //</editor-fold>

}
