import { Injectable } from '@angular/core';
import {ImportData} from '../modules/import-xls/classes/import-data';

@Injectable()
export class ImportService {

  private _import?: ImportData;

  get import(): ImportData {
    if (!this._import) {
      this._import = new ImportData([]);
    }
    return this._import;
  }

  set import(importData: ImportData) {
    this._import = importData;
  }

  constructor() { }

}
