export class Label {
  id?: number = null;
  name: string = 'NOT SET';
  color: string = '#4294E6';

  constructor(init?: any) {
    if (init && init.id) this.id = init.id;
    if (init && init.name) this.name = init.name;
    if (init && init.color) this.color = init.color;
  }
}
