import {ModuleWithProviders, NgModule} from '@angular/core';
import {GlobalService} from '../services/global.service';
import {StorageService} from '../services/storage.service';
import {ToggleService} from '../services/toggle.service';
import {ApiService} from '../services/api.service';
import {LoaderModule} from '../components/loader/loader.module';
import {ErrorMsgModule} from '../components/error-msg/error-msg.module';
import {ThemeService} from '../services/theme.service';

import {CalendarModule} from 'primeng/calendar';
import {UserService} from './layout/user/user.service';
import {AccordionModule} from 'primeng/accordion';
import {DragDropModule} from 'primeng/dragdrop';
import {FileUploadModule} from 'primeng/fileupload';
import {InputMaskModule} from 'primeng/inputmask';
import {KeyFilterModule} from 'primeng/keyfilter';
import {OrganizationChartModule} from 'primeng/organizationchart';
import {RadioButtonModule} from 'primeng/radiobutton';
import {ScrollPanelModule} from 'primeng/scrollpanel';
import {TooltipModule} from 'primeng/tooltip';
import {FileUploadService} from '../services/file-upload.service';
import {MessageService} from 'primeng/api';
import {NoRecordsModule} from '../components/no-records/no-records.module';
import {LabelModalModule} from '../components/modals/label-modal/label-modal.module';
import {CategoryModalModule} from '../components/modals/category-modal/category-modal.module';
import {OccupationModalModule} from '../components/modals/occupation-modal/occupation-modal.module';
import {WorkgroupModalModule} from '../components/modals/workgroup-modal/workgroup-modal.module';
import {ChipsModule} from 'primeng/chips';
import {BreadcrumbService} from '../services/breadcrumb.service';
import {CompanyService} from './layout/company/company.service';
import {CompanyGetResolver} from './layout/company/resolvers/company-get.resolver';
import {MultiselectModule} from '../components/multiselect/multiselect.module';
import {CompanyAllResolver} from './layout/company/resolvers/company-all.resolver';
import {UserGetResolver} from './layout/user/resolvers/user-get.resolver';
import {UserSidebarModule} from './layout/user/modals/user-sidebar/user-sidebar.module';
import {FlatpickrModule} from '../components/flatpickr/flatpickr.module';
import {UserCommonsResolver} from './layout/user/resolvers/user-commons.resolver';
import {ToHumanReadablePipe} from '../pipes/to-human-readable.pipe';
import {UserAllResolver} from './layout/user/resolvers/user-all.resolver';
import {EmployeesGetResolver} from './layout/user/resolvers/employees-get.resolver';
import {HasPermissionResolver} from '../resolvers/has-permission.resolver';
import {AuthService} from '../services/auth.service';
import {TreeModule} from 'primeng/tree';
import {ActivityService} from '../services/activity.service';
import {ActivityModule} from '../components/activity/activity.module';
import {ClientsGetResolver} from './layout/user/resolvers/clients-get.resolver';
import { TruncatePipe } from '../pipes/truncate.pipe';
import {GroupModalModule} from '../components/modals/group-modal/group-modal.module';
import {SafeHtmlPipe} from '../pipes/safe-html.pipe';
import {LocalizedNumberPipe} from '../pipes/localized-number.pipe';
import {OutsideClickModule} from '../directives/outside-click/outside-click.module';
import {FourDigitDecimalPipe} from '../pipes/four-digit-decimal.pipe';

@NgModule({
  declarations: [
    ToHumanReadablePipe,
    TruncatePipe,
    SafeHtmlPipe,
    LocalizedNumberPipe,
    FourDigitDecimalPipe
  ],
  exports: [
    LoaderModule,
    ErrorMsgModule,
    NoRecordsModule,
    LabelModalModule,
    CategoryModalModule,
    OccupationModalModule,
    GroupModalModule,
    WorkgroupModalModule,
    MultiselectModule,
    FlatpickrModule,
    UserSidebarModule,
    TreeModule,
    ActivityModule,
    //<editor-fold desc="NGPrime Modules">
    CalendarModule,
    FileUploadModule,
    InputMaskModule,
    TooltipModule,
    ChipsModule,
    RadioButtonModule,
    DragDropModule,
    AccordionModule,
    OrganizationChartModule,
    ScrollPanelModule,
    KeyFilterModule,
    //</editor-fold>
    //<editor-fold desc="Pipes">
    ToHumanReadablePipe,
    TruncatePipe,
    SafeHtmlPipe,
    LocalizedNumberPipe,
    FourDigitDecimalPipe,
    //</editor-fold>
    OutsideClickModule
  ]
})
export class GlobalModule {

  static forRoot(): ModuleWithProviders<GlobalModule> {
    return {
        ngModule: GlobalModule,
        providers: [
            //<editor-fold desc="Services">
            ApiService,
            GlobalService,
            BreadcrumbService,
            UserService,
            CompanyService,
            AuthService,
            MessageService,
            FileUploadService,
            StorageService,
            ToggleService,
            ThemeService,
            ActivityService,
            //</editor-fold>
            //<editor-fold desc="Resolvers">
            HasPermissionResolver,
            CompanyGetResolver,
            CompanyAllResolver,
            UserGetResolver,
            UserAllResolver,
            EmployeesGetResolver,
            ClientsGetResolver,
            UserCommonsResolver,
            //</editor-fold>
            ToHumanReadablePipe,
            TruncatePipe
        ]
    };
}

}
