export const LabelTranslations = {
  'eng': {
    manage: 'Manage labels',
    add: 'Add',
    save: 'Save',
    labelNamePlaceholder: 'Label name',
    clear: 'Clear',
  },
  'hrv': {
    manage: 'Upravljaj oznakama',
    add: 'Dodaj',
    save: 'Spremi',
    labelNamePlaceholder: 'Ime oznake',
    clear: 'Očisti',
  }
};
