import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {LabelModalComponent} from './label-modal.component';
import {ClickOutsideModule} from '../../../directives/clickOutside/clickOutside.module';
import {ErrorMsgModule} from '../../error-msg/error-msg.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ClickOutsideModule,
    ErrorMsgModule
  ],
  providers: [
  ],
  declarations: [
    LabelModalComponent
  ],
  exports: [
    LabelModalComponent
  ]
})
export class LabelModalModule {
}
