export const OccupationTranslations = {
  'eng': {
    manage: 'Manage Occupations',
    occupationNamePlaceholder: 'Occupation name',
    add: 'Add',
    save: 'Save',
    clear: 'Clear'
  },
  'hrv': {
    manage: 'Upravljaj zanimanjima',
    occupationNamePlaceholder: 'Naziv zanimanja',
    add: 'Dodaj',
    save: 'Spremi',
    clear: 'Očisti'
  }
};
