import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ToggleService} from '../../services/toggle.service';
import * as _ from 'lodash';
import {LocalizationService} from '../../services/localization.service';
import {BehaviorSubject, Observable} from 'rxjs';
import {FilterData} from '../../classes/filter-data';
import {GlobalService} from '../../services/global.service';

export interface FilterField {
  name: string;
  values?: Array<{
    name: string,
    sendValue: number | string | boolean | { key: string, value: number | string } | {
      [logicalOperator: string]: boolean | number | string | number[] | string[] //Operator, != ....
    }
  }>,
  selected?: any; //selected value
  type: string | 'select' | 'multiselect' | 'date' | 'date-range' | 'switch';
  key?: string; // key that will be send to backend, if not key then it will get the name from selected object,
  default?: boolean //If filter is default filter but his value can be changed
}

@Component({
  selector: 'app-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss']
})
export class FilterComponent implements OnInit {

  @Input() filterData: BehaviorSubject<FilterData> = null;
  @Input() fields: FilterField[] = [];
  @Input() returnTimestamp: boolean = false;

  @Output() onSearch: EventEmitter<any> = new EventEmitter();

  public activeFilters: string[] = null;

  constructor(public _toggle: ToggleService,
              public _global: GlobalService,
              public _locale: LocalizationService) {
  }

  ngOnInit() {
    //Order fields by type
    if (this.fields.length) this.fields = _.orderBy(this.fields, 'type');
  }

  clear() {

    let currentData = this.filterData.getValue();
    if (!_.find(this.fields, field => field.selected != null)) return; //If not filters then don't do clear request

    this.removeFilters(currentData);
    _.each(this.fields, field => field.selected = null); //Set all selected fields to null
    this.activeFilters = null; //Reset active filters
    this.filterData.next(currentData);
  }

  getData() {
    this.convertDatesToTimestamps().subscribe(done => {
      if (done) {
        let currentData = this.filterData.getValue();
        this.updateDefaultFilters(currentData);
        this.removeFilters(currentData);
        currentData.filters = _.unionBy(currentData.filters, _.map(_.reject(this.fields, field => {
          if (field.default) return true;
          if (field.selected == null || field.selected.length < 1) return true;
          if (field.selected && field.selected.length > 1 && field.selected.find(f => f == null)) return true;
          if (field.type == 'switch' && field.selected == false) return true; //Case switch filter, selected is never null, so reject fields with false selected
          if (field.type == 'select' && field.selected == null) return true;
          if (field.type == 'multiselect' && field.selected && field.selected.length < 1) return true;
          if (field.type == 'date-range' && field.selected && field.selected > 1) return true; //Date range pickers must have 2 values, so accept only ones that have 2 values
        }), f => {
          return {
            key: f.key ? f.key : f.selected.name.toLowerCase(),
            value: this.getValue(f),
          };
        }), 'key');
        currentData.page = 1; //Always set page to 1 when using filter
        this.filterData.next(currentData);
      }
    });
  }

  updateDefaultFilters(currentData: FilterData): void {
    let defaultFilters = _.filter(this.fields, {default: true});
    _.each(defaultFilters, f => {
      if (f.selected) {
        let index = _.findIndex(currentData.filters, {key: f.key});
        currentData.filters.splice(index, 1, {key: f.key, value: f.selected.sendValue, default: true});
      }
    });
  }

  getValue(field: FilterField): any {
    if (field.selected == null) return;
    if (field.selected && field.selected.sendValue !== undefined) return field.selected.sendValue;
    else if (field.type == 'multiselect') return _.map(_.reject(field.selected, sel => sel == null), 'sendValue');
    else if (field.type == 'date-range' || field.type == 'date' || field.type == 'switch') return field.selected;
    else return field.selected;
  }

  isActive(): boolean {
    return !!_.find(this.fields, field => {
      return field.selected !== null || (field.selected && field.selected.length > 0);
    });
  }

  removeFilters(currentData: FilterData): void {
    _.remove(currentData.filters, filter => {
      if (filter.default == undefined || !filter.default || filter.default == false) return true;
    });  //Remove all filters except defaults that are always active
  }

  private clearSearch: any;

  onSearchStart(keywords: string) {
    clearTimeout(this.clearSearch);
    if (keywords.length > 3 || keywords == '') {
      this.clearSearch = setTimeout(() => this.onSearch.emit(keywords), 500);
    }
  }

  setSwitchValue(event, field: FilterField): void {
    if (event.target.checked) {
      if (!field.values) field.selected = true; //If no value was passed
      field.selected = field.values[0].sendValue; //If array with object in values is defined send that
    } else field.selected = false;
  }

  getActiveFilters(): void { //Get all active filters and make array of strings from it for displaying ["filterName": "filterValues"]

    let active = _.filter(this.fields, field => {
      if (_.isArray(field.selected)) return field.selected.length;
      else return field.selected;
    });

    this.activeFilters = _.map(active, field => {
      if (!_.isArray(field.selected)) {
        return `${field.name}: ${_.find(field.values, val => val.sendValue == field.selected).name}`;
      } else {
        let names = _.map(field.selected, sel => {
          return (!field.values) ? sel : _.find(field.values, val => val.sendValue == sel).name;
        });
        return `${field.name}: ${names.toString()}`;
      }
    });

  }

  convertDatesToTimestamps(): Observable<boolean> {
    return new Observable(obs => {

      if (!this.returnTimestamp) { //If not timestamp just set obs to complete without doing anything
        obs.next(true);
        obs.complete();
      }

      let dateFields = _.filter(this.fields, field => {
        return field.selectd !== null && (field.type == 'date-range' || field.type == 'date');
      });
      _.each(dateFields, field => {
        if (field.selected !== null) {
          if (!_.isArray(field.selected)) {
            field.selected = new Date(field.selected).getTime();
          }
          else field.selected = _.map(field.selected, val => new Date(val).getTime());
        }
      });

      obs.next(true);
      obs.complete();
    });
  }
}
