import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AuthService} from '../../../services/auth.service';
import {GlobalService} from '../../../services/global.service';
import {ElectronService} from 'ngx-electron';
import {LocalizationService} from '../../../services/localization.service';
import {ApiService} from '../../../services/api.service';
import {BehaviorSubject} from 'rxjs/index';
import {ToggleService} from '../../../services/toggle.service';
import {Group} from '../../../interfaces/common/group';
import * as _ from 'lodash';

@Component({
  selector: 'app-group-modal',
  templateUrl: './group-modal.component.html',
  styleUrls: ['./group-modal.component.scss']
})
export class GroupModalComponent implements OnInit {

  public clickOutsideCounterModal: number = 1;
  public colors: any[] = ['#20ADE2', '#1DBFBF', '#A0D176', '#EFA02A', '#ED6C76', '#6F80C7', '#B272B7', '#E64B8D', '#BF5783', '#3E7FAF'];
  public selectedColor: BehaviorSubject<string> = new BehaviorSubject(null);
  public group: Group = null; //Selected label inside this modal
  public form: FormGroup;

  private returnObj: any = {
    type: 'group',
    data: null
  };

  @Input() groups: Group[] = [];
  @Input() url: any; //Url object
  @Input() module: string; //Module for which is going to be create (company, user, project)
  @Input() type: string = null; //Module for which is going to be create (company, user, project)
  @Output() onChange: any = new EventEmitter(); //Emit onChange event with the changed object

  constructor(public _electronService: ElectronService,
              public _locale: LocalizationService,
              public _toggle: ToggleService,
              private _global: GlobalService,
              private _fb: FormBuilder,
              private _auth: AuthService,
              private _api: ApiService) {
  }

  ngOnInit() {
    if (!this.groups) this.groups = []; //Set empty array
    this.buildForm();
  }

  onClickOutsideGlobalPrimaryModal(event) {
    if (this._toggle.groupModal.getValue()) {
      this.clickOutsideCounterModal = this.clickOutsideCounterModal + 1;
      if (this._toggle.groupModal.getValue() && this.clickOutsideCounterModal === 3) {
        this.clickOutsideCounterModal = 1;
        this._toggle.groupModal.next(false);
      }
    }
  }

  selectLabelColor(param: string): void {
    this.selectedColor.next(param);
    let form = this.form.get('color');
    form.patchValue(this.selectedColor.getValue());
    form.updateValueAndValidity();
  }

  //<editor-fold desc="FORM">
  buildForm(group?: Group): void {
    if (group) this.selectedColor.next(group.color);

    this.form = this._fb.group({
      id: [{value: group ? group.id : null, disabled: !group}],
      name: [group ? group.name : '', Validators.compose([Validators.required, Validators.maxLength(20)])],
      color: [this.selectedColor.getValue(), Validators.required],
      type: this.type ? this.type : 'group',
      company: this._auth.loggedUser.company, //Company for which is created
      description: [group ? group.description : '']
    });
  }

  edit(group: Group): void {
    this.group = group;
    this.form.reset();
    this.buildForm(this.group);
    this.selectedColor.next(group.color);
  }

  clear(clearGroup?): void {
    this.selectedColor.next(null);
    this.form.reset();
    if (clearGroup) this.group = null;
    this.buildForm();
  }

  //</editor-fold>

  //<editor-fold desc="CRUD">
  create(formData: Group): void {
    if (!this.form.valid) return this._global.checkFormErrors(this.form);
    this._api.send(this.url.create.method, this.url.create.url, formData).subscribe(res => {
      this._global.updateCommonData(this.module, 'group', res['data'], 'create'); //Update common data
      this.onChange.emit(_.assign(this.returnObj, {data: formData})); //Send event to parent component
      this.clear();
    });
  }

  update(formData: Group): void {
    if (!this.form.valid) return this._global.checkFormErrors(this.form);

    this._api.send(this.url.update.method, `${this.url.update.url}/${formData.id}`, formData).subscribe(res => {
      this._global.updateCommonData(this.module, 'group', formData, 'update'); //Update common data
      this.onChange.emit(_.assign(this.returnObj, {data: formData})); //Send event to parent component
      this.clear(true);
    });
  }

  delete(group: Group): void {
    this._api.send(this.url.delete.method, `${this.url.delete.url}/${group.id}`, group).subscribe(res => {
      this._global.updateCommonData(this.module, 'group', group, 'delete'); //Update common data
      this.onChange.emit(_.assign(this.returnObj, {data: group})); //Send event to parent component
    });
  }

  //</editor-fold>

}
