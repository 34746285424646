import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';

import {Params} from '@angular/router';
import {ApiService} from '../../../services/api.service';
import {ImportData, SavedRawImportYear} from '../study/modules/import-xls/classes/import-data';
import {urlValues} from '../../../configs/url.values';

@Injectable({
  providedIn: 'root'
})
export class ImportedYearCompanyService {

  constructor(
    private _api: ApiService
  ) { }

  public get(studyId: number): Observable<ImportData> {
    return new Observable<ImportData>(obs => {
      const {url, method} = urlValues.company.import.get;
      this._api.send(method, url(studyId)).subscribe(res => {
        let rawData: SavedRawImportYear[] = res['data'];
        obs.next(new ImportData(rawData));
        obs.complete();
      }, err => {
        obs.error(err);
        obs.complete();
      });
    });
  }

  public update(studyId: number, data: Params): Observable<void> {
    return new Observable<void>(obs => {
      const {url, method} = urlValues.company.import.update;
      this._api.send(method, url(studyId), data).subscribe(() => {
        obs.next();
        obs.complete();
      }, err => {
        obs.error(err);
        obs.complete();
      });
    });
  }

  public clear(companyId: number): Observable<void> {
    return new Observable<void>(obs => {
      const {url, method} = urlValues.company.import.clear;
      this._api.send(method, url(companyId)).subscribe(() => {
        obs.next();
        obs.complete();
      }, err => {
        obs.error(err);
        obs.complete();
      });
    });
  }

}
