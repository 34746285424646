export const ContactTranslations = {
  'eng': {
    view: 'View',
    edit: 'Edit',
    archive: 'Archive',
    restore: 'Restore',
    companies: 'Companies',
    contacts: 'Contacts',
    addnew: 'Add new'
  },
  'hrv': {
    view: 'Prikaži',
    edit: 'Uredi',
    archive: 'Arhiviraj',
    restore: 'Vrati',
    companies: 'Tvrtke',
    contacts: 'Kontakti',
    addnew: 'Dodaj'
  }
};
