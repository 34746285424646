import {Injectable} from '@angular/core';
import {ApiService} from '../../../services/api.service';
import {GlobalService} from '../../../services/global.service';
import {User} from './classes/user';
import {urlValues} from '../../../configs/url.values';
import {LocalizationService} from '../../../services/localization.service';
import {Location} from '@angular/common';
import {BehaviorSubject, Observable} from 'rxjs';
import * as _ from 'lodash';
import {ListData} from '../../../interfaces/list-data';
import {FilterData} from '../../../classes/filter-data';
import {FilterField} from '../../../components/filter/filter.component';
import {CompanyService} from '../company/company.service';
import {FileUploadService} from '../../../services/file-upload.service';
import {AuthService} from '../../../services/auth.service';
import {UploadedFile} from '../../../interfaces/uploaded-file';
import {StorageService} from '../../../services/storage.service';


@Injectable()
export class UserService {

  public users: User[] = [];
  public user: User = null;

  public sidebarUser: User = null; //Only user for showing user in sidebar and nothing more
  public userListData: ListData = null; //WHEN LIST WILL BE FULL DYNAMIC

  public listFilters: FilterField[] = []; //Filters that will be shown above the list

  //<editor-fold desc="Filter data">
  public filterData: BehaviorSubject<any> = new BehaviorSubject(null);
  public totalPages: number = 0;
  public totalRecords: number = 0;

  //</editor-fold>

  constructor(private _api: ApiService,
              private _locale: LocalizationService,
              private _fileUpload: FileUploadService,
              private _location: Location,
              private _company: CompanyService,
              private _auth: AuthService,
              private _storage: StorageService,
              public _global: GlobalService) {

    //Get all users and subscribe to filter data
    //Track every change on filter data (search, filters, page ...) and send new request
    this.filterData.subscribe((data: FilterData) => {
      if (!data) return;
      this.getUsers(data, true).subscribe();
    });
  }

  //<editor-fold desc="Functions">
  uploadProfileImage(event, user: User): void {
    let response = JSON.parse(event.xhr.response);
    if (!response.data.length) return;

    //Check file size if its bigger then defined trow warning and return
    if (event.files[0].size > this._fileUpload.profileImageSize) {
      this._global.pushAppMessage('warn',
        this._locale.fileUpload.maxFileSizeWarn,
        `${this._locale.fileUpload.maxFileSizeError} ${this._fileUpload.bytesToSize(this._fileUpload.profileImageSize)}`);
      return;
    }

    this.update(user.id, {details: {profileImage: response.data[0].media}}).subscribe((updated: boolean) => {
      //Check if user was updated and then get the user with the updated profile picture
      if (!updated) return;
      this.getUser(user.id).subscribe(user => {
        //If logged user then update his profile image in app
        if (user.id == this._auth.loggedUser.id) this.setUserProfileImage(user.details.profileImage);
      });
    });

    this._fileUpload.uploaded = true;
    this._fileUpload.progress = null;
  }

  setUserProfileImage(image: UploadedFile): void {
    this._auth.loggedUser.details.profileImage = image; //Set image to logged user in service
    let user = this._storage.get('user'); //Set image to local storage
    user.details.profileImage = image;
    this._storage.set('user', user);
  }

  checkIsAvailableUsername(username: string): Observable<any> {
    return new Observable<any>(obs => {
      this._api.send(urlValues.auth.checkUserParamAvailable.method, urlValues.auth.checkUserParamAvailable.url, {username: username}).subscribe(res => {
        obs.next(res['data']);
        obs.complete();
      }, err => {
        obs.error(err);
        obs.complete();
      });
    });
  }

  checkIsAvailableEmail(email: string): Observable<any> {
    return new Observable<any>(obs => {
      this._api.send(urlValues.auth.checkUserParamAvailable.method, urlValues.auth.checkUserParamAvailable.url, {email: email}).subscribe(res => {
        obs.next(res['data']);
        obs.complete();
      }, err => {
        obs.error(err);
        obs.complete();
      });
    });
  }

  //</editor-fold>

  //<editor-fold desc="CRUD">
  getUsers(filters: FilterData, updateCount?: boolean, manage?: boolean): Observable<User[]> {
    return new Observable<User[]>(obs => {
      this._api.send(urlValues.user.filter.method, urlValues.user.filter.url, filters).subscribe(res => {
        if (updateCount) {
          this.totalPages = res['data'].totalPages; //Set how many total pages are in db
          this.totalRecords = res['data'].totalRecords; //Set how many records are in db
        }
        let users = _.map(res['data'].records, user => { //Check if user has profile image and get it
          if (!user.iconColor) _.extend(user, {iconColor: this._global.getRandomColor(user.id)});
          return new User(user);
        });
        if (!manage) this.users = users; //If not manage bool then store users in service else just return users
        obs.next(manage ? users : this.users);
        obs.complete();
      }, err => {
        obs.error(err);
        obs.complete();
      });
    });
  }

  getUser(id: number, sidebarUser?: boolean): Observable<User> {
    return new Observable<User>(obs => {
      this._api.send(urlValues.user.get.method, `${urlValues.user.get.url}/${id}`).subscribe(res => {
        if (!res['data'].iconColor) res['data'].iconColor = this._global.getRandomColor(res['data'].id); //Add random color to user
        sidebarUser ? this.sidebarUser = new User(res['data']) : this.user = new User(res['data']);
        obs.next(this.user);
        obs.complete();
      }, err => {
        obs.error(err);
        obs.complete();
      });
    });
  }

  create(data: object): void {
    this._api.send(urlValues.user.create.method, urlValues.user.create.url, data).subscribe(res => {
      this._location.back();
      this._global.pushAppMessage('success', this._locale.user.success, this._locale.user.usercreated);
    });
  }

  update(id: number, data: object, redirect?: boolean): Observable<boolean> {
    if (this._global.isEmptyObject(data)) return;

    return new Observable<boolean>(obs => {
      this._api.send(urlValues.user.update.method, `${urlValues.user.update.url}/${id}`, data).subscribe(res => {
        this._global.pushAppMessage('success', this._locale.user.success, this._locale.user.userupdated);

        if (id == this._auth.loggedUser.id) this._auth.updateLoggedUser(data);
        if (redirect) this._location.back();
        obs.next(true);
        obs.complete();
      }, err => {
        obs.error(err);
        obs.complete();
      });
    });
  }

  archive(id: number): void {
    this._api.send(urlValues.user.archive.method, `${urlValues.user.archive.url}/${id}`).subscribe(res => {
      _.remove(this.users, ['id', id]);
      this._global.pushAppMessage('success', this._locale.user.success, this._locale.user.userarchived);
    });
  }

  restore(id: number): void {
    this._api.send(urlValues.user.update.method, `${urlValues.user.update.url}/${id}`, {archived: false}).subscribe(res => {
      _.remove(this.users, ['id', id]);
      this._global.pushAppMessage('success', this._locale.user.success, this._locale.user.userrestored);
    });
  }

  anonymise(id: number): void {
    this._api.send(urlValues.user.anonimyse.method, `${urlValues.user.anonimyse.url}/${id}`).subscribe(res => {
      _.remove(this.users, ['id', id]);
      this._global.pushAppMessage('success', this._locale.user.success, this._locale.user.useranonymised);
    });
  }

  identify(id: number): void {
    this._api.send(urlValues.user.identify.method, `${urlValues.user.identify.url}/${id}`).subscribe(res => {
      _.remove(this.users, ['id', id]);
      this._global.pushAppMessage('success', this._locale.user.success, this._locale.user.useridentified);
    });
  }


  //</editor-fold>

  //<editor-fold desc="Filters">
  setListFilters(): void {
    //Check user permissions and based on them create array of filters that will be displayed on the list
    this.listFilters = [
      {
        name: 'Labels', // will be translated inside the filter component
        values: _.mapFilterFields(this._global.commonData.user.label),
        selected: null,
        type: 'select',
        key: 'label'
      },
      {
        name: 'Category',
        values: _.mapFilterFields(this._global.commonData.user.category),
        selected: null,
        type: 'select',
        key: 'category' //Optional
      },
      {
        name: 'Workgroup',
        values: _.mapFilterFields(this._global.commonData.user.workgroup),
        selected: null,
        type: 'multiselect',
        key: 'workgroups' //Optional
      },
      {
        name: 'Occupation',
        values: _.mapFilterFields(this._global.commonData.user.occupation),
        selected: null,
        type: 'select',
        key: 'occupation' //Optional
      },
      {
        name: 'Tags',
        values: null,
        selected: null,
        type: 'tags',
        key: 'tags'
      }
    ];

    //Check if user has permission to see companies, and enable the filter
    if (this._auth.checkPermission('company_filter')) {
      let initFilters = new FilterData();
      initFilters.filters = [
        {key: 'isClient', value: true, default: true},
        {key: 'archived', value: false, default: true}
      ];
      this._company.getCompanies(initFilters).subscribe(companies => {
        this.listFilters.push({
          name: 'Company', // will be translated inside the filter component
          values: _.mapFilterFields(companies),
          selected: null,
          type: 'select',
          key: 'company'
        });
      });
    }
    //Return appropriate user types in filter based on
    let params = this.getFilterValue(this.filterData.getValue());
    this.listFilters.push(this.filterUserTypes(params));
  }

  filterUserTypes(params): FilterField {
    let hasLogicOperator: boolean = params['logicOperator'].indexOf('!=') != -1;
    let filteredTypes = _.filter(this._global.commonData.userTypes, uType => (params['values'].indexOf(uType.id) == -1) == hasLogicOperator);
    return {
      name: 'Type', // will be translated inside the filter component
      values: _.map(filteredTypes, uType => {
        return {name: this._locale.userTypes[uType.slug], sendValue: uType.id};
      }),
      selected: null,
      type: 'select',
      key: 'type'
    };
  }

  getFilterValue(filterData: FilterData): object {
    let types = _.find(filterData.filters, ['key', 'type']);
    if (!_.isObject(types.value)) {
      return types.value;
    } else return {logicOperator: _.keys(types.value), values: _.flatten(_.values(types.value))};
  }

  //</editor-fold>



}
